import React, { useRef, useState, useEffect } from 'react';

function createDot(x: any, y: any) {
    var elem = document.createElement("div");
    elem.setAttribute("class", "dot");
    elem.setAttribute("style", "left:" + x + "px;top:" + y + "px;");
    if(document.getElementsByClassName("root") && document.getElementsByClassName("root")[0]){
      document.getElementsByClassName("root")[0].appendChild(elem);
      return elem;
    }
  }
  
  function anotherDot() {
    createDot(Math.floor(Math.random() * 200), Math.floor(Math.random() * 200));
  }
  
  function createDots(){
    for (var i = 0; i < 100; i++) {
      anotherDot();
    }
  }


  interface RandomDotsBackgroundProps {
    dots_num: number, /*change:boolean, equip_id:number,*/ 
    duplicate: boolean
    }

  const RandomDotsBackground: React.FC<RandomDotsBackgroundProps> = ({dots_num, duplicate}) => {
    let q = 60
    if(dots_num){
    q = dots_num
    }
    return <>
        {

          [...Array(q)].map((e, i) => {
            let x = Math.floor(Math.random() * 100)
            let y = Math.floor(Math.random() * 100)
            return <><div 
                    className= {Math.floor(Math.random()*2) == 1 ? 'dot-star' : 'dot-star-big'}
                    style={{
                    left: x + '%', 
                    top: y  + '%'}}
                    />
                    {duplicate?
                      <div 
                          className= {Math.floor(Math.random()*2) == 1 ? 'dot-star' : 'dot-star-big'}
                          style={{
                              left: (x)  + '%', 
                              top: (y - 100)  + '%'}}
                          />
                    :<></>}
            </>
        })
        }
    </>
  }

  export default RandomDotsBackground