import React, { useRef,useEffect, useState, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';

import ky from 'ky';
import EquipCard from '../components/inventory/EquipCard';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { Pos } from '../models/types'; // Import the PosArray type definition
import { Html } from '@react-three/drei';

import {setUser} from '../slices/userSlice';
import {setScreenTab} from '../slices/screenTabSlice';

import { RESET_STORE } from '../utils/consts';
import {setUserLocations} from '../slices/userLocationsSlice';
import {setUserReputations} from '../slices/userReputationsSlice';

import {Canvas, useFrame, useThree} from '@react-three/fiber';
import { MeshReflectorMaterial, OrbitControls, PerspectiveCamera, Points} from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import * as THREE from "three";
import { Line, Billboard } from "@react-three/drei";
import './App.css'
import {setNavMap} from '../slices/navMapSlice';
import { DoubleSide } from 'three'
import { User } from '../models/types';
import { Navigate } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from '../utils/icons/arrow-left.svg';  // Import as a React component

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const star_color = "#82705E"
const light_color= "#FEDBB7"
const main_color= "#CBAF92"
const darker_color ="#A28C75"
const rocket_color = '#FF00FF'


const animation_speed_coef = 5/100000


const sizes={
  width: window.innerWidth,
  height: window.innerHeight
}
const random_array_length = 1000

const RandomArray = new Array(random_array_length)
for(let i = 0; i<random_array_length; i++){
  RandomArray[i] = Math.random()
}

function decimalToHexString(n: number)
{
  if (n < 0)
  {
    n = 0xFFFFFFFF + n + 1;
  }

  return n.toString(16).toUpperCase();
}

function PseudoRand(hash:number){
  const salt1 = 124
  const salt2 = 23
  if(hash > 0 )
  {
    return RandomArray[ (hash*salt2 + salt1)%random_array_length]
  }
  return RandomArray[ (hash*salt2*(-1) + salt1)%random_array_length]
}

const loader = new TextureLoader();
const texture_enemy = loader.load("../markers/triangle2.svg");
const texture_enemy_target = loader.load("../markers/triangle2_target.svg");
const texture_enemy_dead = loader.load("../markers/redcross.svg");
const texture_ally = loader.load("../markers/triangle1.svg");
const texture_ally_target = loader.load("../markers/triangle_fill.svg")
const texture_ally_dead = loader.load("../markers/bluecross.svg");
const texture_rocket_block  = loader.load("../markers/whitecross.svg");


export default function StationsGraph() {

  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(0)
  const [selectedCommand, setSelectedCommand] = useState('')
  const [stationInfoModal, setStationInfoModal] = useState(0)
  const [politicalPositionChangeModal, setPoliticalPositionChangeModal] = useState(0)
  const [errorCommand, setErrorCommand] = useState(false)
  const [politicalPositionSelected, setPoliticalPositionSelected] = useState('')
  const [selectedStationReps, setSelectedStationReps] = useState({'reputations' : []})
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');
  const navMap = useSelector((state:RootState)=> state.navMap);
  const userLocations = useSelector((state:RootState)=> state.userLocations);
  const userReputations = useSelector((state:RootState)=> state.userReputations);
  const team = useSelector((state:RootState)=> state.team);

  const user = useSelector((state:RootState)=> state.user);

  let all_stations = navMap.stations
  //all_stations = [...navMap.stations, ...navMap.stations, ...navMap.stations, ...navMap.stations, ...navMap.stations, ...navMap.stations]
  // Memoize the CommandAnimations component using useMemo
  //}

   useEffect(() => {
    fetchUserLocations()
    fetchUserReputations()
    fetchStations()
  }, [dispatch]);
  
  let selected_station = all_stations.filter((e : any)=>{return (selectedId == e.id)})[0]

  let selected_station_reps_length = selectedStationReps?.reputations?.length
  let selected_station_neutral_reps = selectedStationReps?.reputations?.filter((e : any)=>{return (e?.political_status == '')})
  let selected_station_pro_reps = selectedStationReps?.reputations?.filter((e : any)=>{return (e?.political_status == 'owner' || e?.political_status == 'pro-owner' )})
  let selected_station_negative_reps = selectedStationReps?.reputations?.filter((e : any)=>{return (e?.political_status == 'opposition' || e?.political_status == 'new_order' )})
  
  let total_station_gdp_neutral = selected_station_neutral_reps?.reduce((partialSum, e : any) => partialSum + e?.gdp, 0);
  let total_station_gdp_pro  = selected_station_pro_reps?.reduce((partialSum, e : any) => partialSum + e?.gdp, 0);
  let total_station_gdp_negative = selected_station_negative_reps?.reduce((partialSum, e : any) => partialSum + e?.gdp, 0);
  let total_station_gdp = total_station_gdp_neutral + total_station_gdp_pro + total_station_gdp_negative

  console.log('selected_station_neutral_reps', selected_station_neutral_reps)

  let selected_station_mutiny_check = false

  if(selected_station?.political_status == "mutiny" || total_station_gdp_negative > total_station_gdp_pro){
    selected_station_mutiny_check = true
  }

  let selected_rep = userReputations.reputations.filter((e : any)=>{return (selectedId == e.station_id)})[0]
  console.log('selected_rep',selected_rep)
  async function fetchUserLocations() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/players/get_user_locations?hash_id=${hash_id}`;
      const result = await ky.get(requestUrl, { credentials: 'include' }).json();
      console.log('fetchUserLocations', result)
      dispatch(setUserLocations(result));
    } catch (error) {
      console.error('Could not fetch user locations:', error);
    }
  };


  async function fetchUserReputations() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/players/get_user_reputations?hash_id=${hash_id}`;
      const result = await ky.get(requestUrl, { credentials: 'include' }).json();
      console.log('fetchUserReputations', result)
      dispatch(setUserReputations(result));
    } catch (error) {
      console.error('Could not fetch user locations:', error);
    }
  };

  async function fetchStationReputations(station_id : number) {
    try {
      // Add hash_id to the fetch request URL
      if (selected_station?.id){
        const requestUrl = `${API_BASE_URL}/gameplay/stations/get_all_reputations/?hash_id=${hash_id}`;
        
        const result :any = await ky.put(requestUrl, {json: {station_id: station_id}, credentials: 'include' }).json();
        console.log('fetchStationReputations', result)
        setSelectedStationReps(result);

      }

    } catch (error) {
      console.error('Could not fetch user locations:', error);
    }
  };


  async function changeDestination(){
    try {
      
      const requestUrl = `${API_BASE_URL}/gameplay/ships/set_destination/?hash_id=${hash_id}`;
      console.log('purchase Cargo', {destination_id: selectedId})
      const result = await ky.put(requestUrl, { json: {destination_id: selectedId},  credentials: 'include' }).json();

      if(result){
        fetchUserLocations()

      }
      else{
        console.log('error selectedOption', result)
      }
    } catch (error) {
      console.error('Could not send command:', error);
    }
  };

  async function changeHomeDestination(){
    try {
      
      const requestUrl = `${API_BASE_URL}/gameplay/ships/set_home_location/?hash_id=${hash_id}`;
      console.log('purchase Cargo', {station_id: selectedId})
      const result = await ky.put(requestUrl, { json: {station_id: selectedId},  credentials: 'include' }).json();

      if(result){
        fetchUserLocations()
      }
      else{
        console.log('error selectedOption', result)
      }
    } catch (error) {
      console.error('Could not send command:', error);
    }
  };



  async function fetchStations() {
    try {
      // Add hash_id to the fetch request URL

      const requestUrl = `${API_BASE_URL}/gameplay/stations/get_all_stations?hash_id=${hash_id}`;

      const new_stations :any = await ky.get(requestUrl, { credentials: 'include' }).json();
      if(new_stations){
        dispatch(setNavMap(new_stations));
        console.log('all_stations', new_stations) // ??????????????????
        }
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };


  const handleReset = () => {
    dispatch({ type: RESET_STORE });
  };

  
  const handleLogObjects = (object:any) => {
      if (object.name) {
        console.log(`Object Name: ${object.name}`);
      } else {
        console.log('Unnamed Object:', object);
      }
  };


  function fnv1a(str: string) {
    let hash = 0x811c9dc5; // 32-bit FNV offset basis

    for (let i = 0; i < str.length; i++) {
      hash ^= str.charCodeAt(i); // XOR with the byte of the string
      hash = (hash * 0x01000193) >>> 0; // Multiply by FNV prime and ensure 32-bit unsigned
    }
    return hash;
  }
  
  function pseudoRandomNumber(preset_X:any) {
    let salted_preset = preset_X * 5381 + ((preset_X >> 3) ^ (5381 << 2));

    const hash = fnv1a(String(salted_preset));

    // Normalize to the 0-1 range
    const normalized = hash / 4294967295; // Max value for 32-bit unsigned int (2^32 - 1)

    return normalized;

  }

  
function disposeGroup(group :any) {
  group.traverse((object :any) => {
    if (object instanceof THREE.Mesh || object instanceof THREE.Points) {
      // Dispose of the object's geometry and material
      if (object.geometry) {
        object.geometry.dispose();
      }
      if (object.material) {
        if (object.material.map) {
          object.material.map.dispose();
        }
        object.material.dispose();
      }
    }
  });

  // Clear the group's children
  group.children.length = 0;
}

  const radiusAlly = 0.7

  function tanRand(x:any) { 
    let new_rand = (Math.tan((x * Math.PI / 2) - Math.PI/4) + 1)/2
    return new_rand
  }

  const radiusUniverse = 2
  const universeHeightCoef = 3
  const fluffyRand = 1.5

  const RandomSphere1 = useMemo(() => {
    const particlesCnt = 550
    const pos_array = new Float32Array(particlesCnt*3)
    const radius_main = radiusUniverse
    let alpha = 0
    let beta = 0
    let radius = 0
    let rand = 0
    let radius_delta_coef = 0
    for (let i = 0; i < particlesCnt*3; i+=3){
      alpha = PseudoRand(i+13)  * 2 * Math.PI 
      beta = (tanRand(PseudoRand(i+179)))* Math.PI *0.95 + Math.PI /2 + (0.05 * Math.PI /2) //0.95 и последнее прибавление для того чтобы не скапливалось на полюсах
      rand =  0.5 -  tanRand(Math.random())
      radius_delta_coef = 1 + (rand)* fluffyRand
      radius = radius_main * radius_delta_coef
      pos_array[i] = Math.sin(alpha)* Math.cos(beta) * radius
      pos_array[i+1] = Math.cos(alpha) * Math.cos(beta) * radius
      pos_array[i+2] = Math.sin(beta) * radius /universeHeightCoef
    }

    return(
      <points
      rotation={[Math.PI/2,0,0]}
      >
        <bufferGeometry>
        <bufferAttribute
                // ref={positionsRef}
                  attach='attributes-position'
                  array={pos_array}
                  count={pos_array.length / 3}
                  itemSize={3}
              />
        </bufferGeometry>
        <pointsMaterial size={0.008} color={'#888'}/>
      </points>
    );
  }, []);

  
  const RandomSphere2 = useMemo(() => {
    console.log('Sphere rerender')
    const particlesCnt = 150
    const pos_array = new Float32Array(particlesCnt*3)
    const radius_main = radiusUniverse
    let alpha = 0
    let beta = 0
    let radius = 0
    let rand = 0
    let radius_delta_coef = 0
    for (let i = 0; i < particlesCnt*3; i+=3){
      alpha = PseudoRand(i+13)  * 2 * Math.PI 
      beta = (tanRand(PseudoRand(i+179)))* Math.PI *0.95 + Math.PI /2 + (0.05 * Math.PI /2) //0.95 и последнее прибавление для того чтобы не скапливалось на полюсах
      rand =  0.5 - tanRand(Math.random())
      radius_delta_coef = 1 + (rand) * fluffyRand
      radius = radius_main * radius_delta_coef
      pos_array[i] = Math.sin(alpha)* Math.cos(beta) * radius
      pos_array[i+1] = Math.cos(alpha) * Math.cos(beta) * radius
      pos_array[i+2] = Math.sin(beta) * radius / universeHeightCoef
    }

    return(
      <points
      rotation={[Math.PI/2,0,0]}
      >
        <bufferGeometry>
        <bufferAttribute
                // ref={positionsRef}
                  attach='attributes-position'
                  array={pos_array}
                  count={pos_array.length / 3}
                  itemSize={3}
              />
        </bufferGeometry>
        <pointsMaterial size={0.008} color={'#fff'}/>
      </points>
    );
  }, []);


  const StationPoliticalBar = useMemo(() => {
    return(
      <></>
    )
  }, [navMap, userLocations, userReputations, selectedId]);

  const Stations = useMemo(() => {

    const particlesCnt = all_stations.length
    const pos_array = new Array(particlesCnt)
    const radius_main = radiusUniverse
    const max_gdp_coef = 2
    let alpha = 0
    let beta = 0
    let radius = 0
    let rand = 0
    let radius_delta_coef = 0
    let gdp_avg = 0


    for (let i = 0; i <  100; i++){
      pseudoRandomNumber(i)
    }

    let TURN_ON_PSEUDO_STATIONS = 0

    for (let i = 0; i < particlesCnt; i++){
      gdp_avg += all_stations[i].gdp / particlesCnt

      alpha = pseudoRandomNumber(all_stations[i].id + 11 + i*13 * TURN_ON_PSEUDO_STATIONS)  * 2 * Math.PI 
      beta = (tanRand(pseudoRandomNumber(all_stations[i].id + 171 + i*13 * TURN_ON_PSEUDO_STATIONS)))* Math.PI *0.95 + Math.PI /2 + (0.05 * Math.PI /2) //0.95 и последнее прибавление для того чтобы не скапливалось на полюсах
      rand = 0.5 - tanRand(pseudoRandomNumber(all_stations[i].id + 35 + i*13 * TURN_ON_PSEUDO_STATIONS))
      radius_delta_coef = 1 + (rand) * fluffyRand
      radius = radius_main * radius_delta_coef
      pos_array[i] = {
        x: Math.sin(alpha)* Math.cos(beta) * radius, 
        y: Math.cos(alpha) * Math.cos(beta) * radius / universeHeightCoef, 
        z: Math.sin(beta) * radius,
        id: i*3,
      }
    }

    if(gdp_avg <= 0){
      gdp_avg = 1
    }

    let rep = null
    return (
        <>
        {pos_array.map((pos, index)=>{
          rep = userReputations.reputations.filter((e : any)=>{return (all_stations[index].id == e.station_id)})[0]
          return(
            <group
              key={"mark-ally"+pos.x +"-"+pos.y}            
              position={[pos.x, pos.y, pos.z]}
            >

                    {/*<sprite 
                      scale={0.10}
                      onClick = {()=>{ 
                        selectTarget(pos?.id, 'station');
                      }}
                      >
                      
                      <spriteMaterial 
                        depthWrite={true} 
                        depthTest={true} 
                        map={texture_ally_target} 
                        rotation={Math.PI}
                      />
                    </sprite>*/}

            <Html
              key = {'html-memorized-indicator'+ pos?.id}
              renderOrder={1}
              zIndexRange={[10, 0]} 
              >
                <div
                  className=
                  {
                    "station-radar-indicator noselect pulsate-once " + 
                    (index % 3 == 0 ? "" : index % 3 == 1 ? "type2 ":"type3 ") +
                    (selectedId == all_stations[index].id ? "selected" : "")
                  }
                >
                  <div className="line"/>
                  <div className="center" onClick = {()=>{ selectTarget(all_stations[index].id, 'station');}}/>
                  
                  <div className={'gdp-circle ' + (rep?.political_status == 'owner'? 'owned' : '')}
                  
                    onClick = {()=>{ selectTarget(all_stations[index].id, 'station');}} style={{transform: 'scale('+ Math.max((max_gdp_coef * Math.sqrt(all_stations[index].gdp / gdp_avg)), 0.4 )+')'}} />
                  {/*<div className="center-transparent-clickable" />*/}
                  <div className="row" onClick = {()=>{ selectTarget(all_stations[index].id, 'station');}}>
                  
                    <small>{userLocations?.destination?.id == all_stations[index].id ? 'ЦЕЛЬ ' : ''}</small>
                    <small>{userLocations?.location?.id == all_stations[index].id ? 'ЭКИПАЖ' : ''}</small>
                
                    
                    <small className='name'>
                      <span>{userLocations?.home_station?.id == all_stations[index].id ? '❤ ' : ''}</span> 
                      {
                        all_stations[index] ?
                        all_stations[index].name
                        :
                        'Неизв.'
                      }
                    </small>

                    <small >{rep?.hyperpoints > 0 ? rep?.hyperpoints + ' ВС' : ''}</small>

                  </div>
                  <div className='indicators-column'>

                  </div>
                </div>
            </Html>
  
            </group>
          )
        }
        )}
      </>
    )

  }, [navMap, userLocations, userReputations, selectedId]);
  /*
  const NavAim = () =>{
    if(userLocations?.location?.id && userLocations?.destination?.id){
      const radius_main = radiusUniverse

      const from_id = userLocations?.location?.id
      const to_id = userLocations?.destination?.id
      let points = new Array(3)

      let alpha = pseudoRandomNumber(from_id + 11)  * 2 * Math.PI 
      let beta = (tanRand(pseudoRandomNumber(from_id + 171)))* Math.PI *0.95 + Math.PI /2 + (0.05 * Math.PI /2) //0.95 и последнее прибавление для того чтобы не скапливалось на полюсах
      let rand = 0.5 - tanRand(pseudoRandomNumber(from_id + 35))
      let radius_delta_coef = 1 + (rand) * fluffyRand
      let radius = radius_main * radius_delta_coef

      points[0] = new THREE.Vector3(Math.sin(alpha)* Math.cos(beta) * radius, Math.cos(alpha) * Math.cos(beta) * radius / universeHeightCoef, Math.sin(beta) * radius)
      
      alpha = pseudoRandomNumber(to_id + 11 )  * 2 * Math.PI 
      beta = (tanRand(pseudoRandomNumber(to_id + 171 )))* Math.PI *0.95 + Math.PI /2 + (0.05 * Math.PI /2) //0.95 и последнее прибавление для того чтобы не скапливалось на полюсах
      rand = 0.5 - tanRand(pseudoRandomNumber(to_id + 35 ))
      radius_delta_coef = 1 + (rand) * fluffyRand
      radius = radius_main * radius_delta_coef

      points[2] = new THREE.Vector3(Math.sin(alpha)* Math.cos(beta) * radius, Math.cos(alpha) * Math.cos(beta) * radius / universeHeightCoef, Math.sin(beta) * radius)
      points[1] = new THREE.Vector3(points[0].x*0.6 + points[2].x*0.4 , points[0].y*0.6 + points[2].y*0.4 +0.1, points[0].z*0.6 + points[2].z*0.4  )

      let curve1 = new THREE.CatmullRomCurve3(points)
      return(
        <points 
        key={"trace-ally"+curve1?.points[0].x.toString()+curve1?.points[0].y.toString()}>
          <tubeGeometry args={[curve1, 5, 0.03, 10, false]} />
          <pointsMaterial size={0.001} color={"#FEDBB7"}/>
          </points>

      )
    } 
    return <></>

  };*/

  

  function selectTarget(station_id : number, type: string){
    
    setSelectedId(station_id)
    if(stationInfoModal){
      fetchStationReputations(station_id)
    }

    let s_rep = userReputations.reputations.filter((e : any)=>{return (station_id == e.station_id)})[0]
    if (!s_rep){
      setPoliticalPositionChangeModal(0);
    }
    setPoliticalPositionSelected(s_rep?.political_status)
  

  }

  async function sendPoliticalStatusChange(){
    try {
      
      const requestUrl = `${API_BASE_URL}/gameplay/ships/change_political_status/?hash_id=${hash_id}`;
      const result = await ky.put(requestUrl, { json: {station_id: selectedId, political_status: politicalPositionSelected},  credentials: 'include' }).json();
      console.log('sendPoliticalStatusChange', result )

      if(result){

        await fetchUserReputations()
        await fetchStations()
        setPoliticalPositionChangeModal(0); 
        setStationInfoModal(1)      

      }else{
        console.log('error sendPoliticalStatusChange', result)
      }


    } catch (error) {
      console.error('Could not sendPoliticalStatusChange:', error);
    }

  }




  return (
    <>


    <Canvas>
    <PerspectiveCamera
        makeDefault// Set this camera as the default camera
        fov={100}
        aspect={sizes.width / sizes.height}
        position={[3.6, 3.6, -3.6]} // Camera positioned at (0, 2, 6)
        near={0.4}
        far={10}
      />
      {RandomSphere1}
      {RandomSphere2}
      {Stations}
      <OrbitControls minZoom={1} maxZoom={1} minDistance={2} maxDistance={5} enablePan={false}/>


    </Canvas>


    <div className='hud noselect no-opacity'>
    {/* 
    <div className='info-panel-left no-borders' onClick={()=>navigate('/voyage' + `/?hash_id=${hash_id}`)}>
      <p className="cyan-color"  ><ArrowLeft className='inline-svg' /> НАЗАД</p>
      <small>к управлению</small>

    </div>
    */}
    <div className='info-panel-top'>
      {/*
    <div className='tabs-row'>

      <p className='tab' onClick={() => dispatch(setScreenTab('voyage'))}>ПУТЬ</p>
        <p className='selected tab'>КАРТА</p>
        
          
    </div> */}
    <div className='info-block'>
    

    <div className='info-panel-right not-fixed no-border' >                  
                  
  
    </div>


      <div className='info-panel-left appear-with-shift-left no-borders not-fixed'> 
        <div className='voyage-stats-list '>    
      
          <div className='highlight' onClick={() => dispatch(setScreenTab('voyage'))}><ArrowLeft className='inline-svg-h2'/>  ПОЛЕТ</div>
          <br/>

        { selectedId ?
          <>
              <h2  className='main-color'>
                {selected_station?.name + '\n'}
              </h2> 

              <div className='stat inactive'>
                Влияние: {selected_rep?.gdp ? selected_rep?.gdp : 0}
              </div> 
              <div  className='stat inactive'>
                {(selected_rep?.political_status == 'owner'? <span className='cyan-color'>УПРАВЛЕНИЕ </span> : 
                  selected_rep?.political_status == 'opposition'? 'Дестабилизация' :
                  selected_rep?.political_status == 'new_order'? 'Противостояния' :
                  selected_rep?.political_status == 'pro-owner'? 'Поддержка' :
                  'Нейтралитет'
                )} 
              </div>

          </>
          :
          <>
              <h2  className='main-color'>
                ВЫБЕРИТЕ<br/>СТАНЦИЮ
              </h2> 
         </>
      }
 


        </div>                       
      </div>

    </div>
    </div>


    <div className="commands-panel">
        <div className='commands-list'>
          <div className='commands-descriptions full-width'>
            { selectedId ?
              <>
                  <div className='commands-description appear-with-shift-left'>

                    <small>
                      НДС (владельцу): 10 %
                    </small>
                    <small className='main-color'>
                      Доступно на станции: {selected_rep ? selected_rep?.hyperpoints : 0} ВС
                    </small>
                    
                  </div>      
              </>
              :
              <></>
    
            }

          </div>
                 

        </div>
        <div className="command-order-btns-row">
          { 
            team?.dive_level < 5 ?
            <>
                <div className="order-btn inactive" >ДОСТУПНО НА СТАНЦИИ</div>
            </>
            :
            selectedId ?
            <>
              <div className="next-target-btn" onClick={() => {setStationInfoModal(1); fetchStationReputations(selectedId)}}>УПРАВЛЕНИЕ СТАНЦИЕЙ</div> 


              {userLocations?.destination?.id == selectedId ? 
                <div className="order-btn inactive">ВЫБРАН</div>
                :
                <div className="order-btn"  onClick={() => changeDestination()}>МАРШРУТ</div>
              }

              {/*userLocations?.home_station?.id != selectedId ? 
              <div className="next-target-btn" onClick={() => changeHomeDestination()}>❤ БАЗА</div> 
              : 
              <div className="next-target-btn inactive">❤ БАЗА</div>
              */}
            </>
            :
            <>
              <div className="order-btn inactive" >ВЫБЕРИТЕ СТАНЦИЮ</div>
            </>
          }

        </div>
      </div>


      
      <div className={stationInfoModal? 'bottom-drawer': 'bottom-drawer hidden'}>
        <div className='drawer-header'>Информация о колонии</div>
          <div className='station-section-block'>
          <h2 >Политическая структура {selected_station?.name} </h2>
          {selected_station?.political_status == "mutiny" ?
          <>
            <p className='red-highlight'>// ПОЛИТИЧЕСКИЙ ПЕРЕВОРОТ //</p> 
            <p className='red-color'>До окончания: <span>{Math.floor(((new Date(selected_station?.phase_end_date)).getTime() -(new Date()).getTime() )/1000/(60*60))}ч {Math.floor(((((new Date(selected_station?.phase_end_date)).getTime() -(new Date()).getTime() )/1000) % 3600)/ 60) }м</span></p>
          </>
          :
          selected_station_mutiny_check ? 
          <p className='red-color'>// ДЕСТАБИЛИЗИРОВАНА // </p>
          
          :<></>
          }

            <div className='station-influence-bar-section' key={'station-influence-bar-section'} >
            { selectedStationReps && selected_station_reps_length > 0 && total_station_gdp > 0?
              <>
                    <div className='legend'>
                      <div className='legend-item left cyan-color'>
                        <h2>{Math.round(total_station_gdp_pro * 100 / total_station_gdp) +'%'}</h2>
                        <p>Поддержка</p>
                      </div>
                      <div className='legend-item inactive'>
                        <h2>{Math.round(total_station_gdp_neutral * 100 / total_station_gdp) + '%'}</h2>
                        <p>Нейтралитет</p> 
                        </div>
                      <div className='legend-item right red-color'>
                        <h2>{Math.round(total_station_gdp_negative * 100 / total_station_gdp) + '%'}</h2>
                        <p>Оппозиция</p>
                      </div>
                    </div>
                    <div className='station-influence-bar'>

                          <div key={'segment-positive'} className='segment cyan' style={{width: (total_station_gdp_pro * 100 / total_station_gdp) +'%'}}></div>
                          <div key={'segment-neutral'} className='segment' style={{width: (total_station_gdp_neutral * 100 / total_station_gdp) + '%'}}></div>
                          <div key={'segment-negative'} className='segment red' style={{width: (total_station_gdp_negative * 100 / total_station_gdp) + '%'}}></div>

                    </div>
                    <div className='legend'>
                      <div className='legend-item left cyan-color'>
                        <p>{total_station_gdp_pro} ВЛ</p>
                      </div>
                      <div className='legend-item inactive'>
                        <p>{total_station_gdp_neutral} ВЛ</p>
                        </div>
                      <div className='legend-item right red-color'>
                        <p>{total_station_gdp_negative} ВЛ</p>
                      </div>
                    </div>
                    </>:            
            <>
              Недостаточно данных
            </>
            }
            
            </div>

          </div>
          <div className='station-section-block yesselect'>
          {
          selected_rep ?
          <>
                <h2>Фактория {decimalToHexString(Math.round(pseudoRandomNumber(selected_rep?.station_id) *1000000))}</h2>
                <div  className='line-with-btns'>
                  <div  className='label'>

                  {(selected_rep?.political_status == 'owner'? <span className='cyan-color'>УПРАВЛЕНИЕ </span> : 
                    selected_rep?.political_status == 'opposition'? <span className='red-color'>Дестабилизация</span> :
                    selected_rep?.political_status == 'new_order'? <span className='red-color'>Мятеж</span>:
                    selected_rep?.political_status == 'pro-owner'? <span className='cyan-color'>Поддержка</span>:
                    'Нейтралитет'
                  )} 
                  </div>
                  <div  className='inline-btn' onClick={()=>{setStationInfoModal(0); setPoliticalPositionChangeModal(1)}}>
                    ИЗМЕНИТЬ
                  </div>

                </div>
                <div  className='line-with-btns'>
                  <div  className='label'>

                    <p className='main-color '>
                      {selected_rep ? selected_rep?.gdp : 0} ВЛ (Влияние)
                    </p>   
                    </div>
                  <div  className='inline-btn display-none'>
                    поддержать
                  </div>

                </div>
                <div  className='line-with-btns'>
                  <div  className='label'>

                    <p className='main-color'>
                      {selected_rep ? selected_rep?.hyperpoints : 0} ВС
                    </p>
                  </div>
                  <div  className='inline-btn display-none'>
                    трансфер
                  </div>

                </div>
            </>:
            <>
              <h2 className='inactive'>Не доступно</h2>
                <div  className='line-with-btns inactive'>
                  <div  className='label'>
                  Вы еще не посетили эту станцию
                  </div>

                </div>
                <div  className='line-with-btns inactive'>
                  <div  className='label'>
                      0 ВЛИЯНИЕ 
                  </div>


                </div>
                <div  className='line-with-btns inactive'>
                  <div  className='label'>
                    0 ВС
                  </div>
                </div>

            </>
            }
          </div> 


          <div className="command-order-btns-row">
          <div className="next-target-btn "  onClick={() => {setStationInfoModal(0)}}>ЗАКРЫТЬ</div>  

            {userLocations?.home_station?.id != selectedId ? 
              <div className="order-btn" onClick={() => changeHomeDestination()}>СДЕЛАТЬ БАЗОЙ</div> 
              : 
              <div className="order-btn inactive">❤ БАЗА</div>
            }
          </div>       
      </div>
      

      <div className={politicalPositionChangeModal && selected_rep? 'bottom-drawer': 'bottom-drawer hidden'}>
        <div className='drawer-header'>Изменить политическое влияние</div>
        <div className='station-section-block'>
          <h2>Текущая позиция: {(selected_rep?.political_status == 'owner'? <span className='cyan-color'>Управление </span> : 
                    selected_rep?.political_status == 'opposition'? <span className='red-color'>Дестабилизация</span>:
                    selected_rep?.political_status == 'new_order'? <span className='red-color'>Противостояние</span>:
                    selected_rep?.political_status == 'pro-owner'? <span className='cyan-color'>Поддержка</span>:
                    <span className='inactive'>Нейтралитет</span>
                  )} 
          </h2>
          {selected_rep?.political_status == 'owner' ?
          <p className='inactive'>Вы управляете станцией. Выбор политической позиции недоступен</p>: <></>}
          <div className='political-coordinates-select'>

              <div className='bar'><div className='segment cyan'/><div className='segment'/><div className='segment red'/></div>
              <div className='bar right'><div className='segment cyan'/><div className='segment'/><div className='segment red'/></div>
              {selected_rep?.political_status == 'owner' ?
                <p className='option selected'><div className='box-select'/>Управление</p>
                :
                <p className='option disabled'><div className='box-select'/>Управление</p>
              }
              {selected_rep?.political_status == '' || selected_rep?.political_status == 'pro-owner' ?
                <p className={politicalPositionSelected == 'pro-owner' ? 'option selected' : 'option'} onClick={() => setPoliticalPositionSelected("pro-owner")} ><div className='box-select'/>Поддержка</p>
                :<p className='option disabled'><div className='box-select'/>Поддержка</p>
              }
              {selected_rep?.political_status == '' ?
                <p className={politicalPositionSelected == '' ? 'option selected' : 'option'} onClick={() => setPoliticalPositionSelected("")}><div className='box-select'/>Нейтралитет</p>
                :<p className='option disabled'><div className='box-select'/>Нейтралитет</p>
              }
              {selected_rep?.political_status == '' || selected_rep?.political_status == 'opposition' ?
              <p className={politicalPositionSelected == 'opposition' ? 'option selected' : 'option'} onClick={() => setPoliticalPositionSelected("opposition")}><div className='box-select'/>Дестабилизация</p>
              :<p className='option disabled'><div className='box-select'/>Дестабилизация</p>
              }
              { (selected_rep?.political_status == 'opposition' || selected_rep?.political_status == 'new_order' ) && selected_station_mutiny_check ?
              <p className={politicalPositionSelected == 'new_order' ? 'option selected' : 'option'} onClick={() => setPoliticalPositionSelected("new_order")}><div className='box-select'/>Мятеж</p>
              :<p className='option disabled'><div className='box-select'/>Мятеж</p>
              }
              </div>
          <p className='inactive'>При выходе из нейтралитета - нельзя будет поменять сторону до смены политической структуры колонии.</p>
          <p className='inactive'>Подпишитесь на <a href="https://t.me/hyperdive_news" target="_blank">канал</a> и получайте уведомлениями о любых событиях на станциях.</p>
          <p className='inactive'>Ознакомьтесь подробнее с <a href="https://telegra.ph/Politicheskie-igry-10-25" target="_blank">механиками прокси-управления</a> колониями и рисками.</p>

        </div>
        <div className="command-order-btns-row">
          <div className="next-target-btn "  onClick={() => {setPoliticalPositionChangeModal(0); setStationInfoModal(1)}}>ЗАКРЫТЬ</div>  
          {
            selected_rep?.political_status != politicalPositionSelected ?
            <div className="order-btn"   onClick={() => sendPoliticalStatusChange()}>ВЫБРАТЬ</div>  :
            <div className="order-btn inactive"  >ВЫБЕРИТЕ ПОЗИЦИЮ</div> 
          }
        </div>     
      </div>

    </div>
    </>
    //      <axesHelper />

  )
}
