import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { Team } from '../models/types';

const initialState: Team = {
  id: 0,
  name: '',
  description: '',
  status: '',
  steps_after_dive: 0,
  dive_level: 5,
  karma: 0,
  supply: 0,
  aggression: 0,
  diplomacy: 0,
  faith: 0,
  order: 0,
  morale: 0,

  morale_limit: 0,
  order_limit: 0,
  supply_limit: 0,


  freight: 0,
  cargo: 0,
  freight_limit: 0,
  frags: 0,
  voyage: null,

  loc_x: 0,
  loc_y:0,
  target_x: 0,
  target_y: 0,
  days_count: 0,
  steps_limit: 0,
  direction: 1,

  users: [],
  
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
      setTeam: (state, action) => {
        state.id = action.payload?.id;
        state.name = action.payload?.name;
        state.description = action.payload?.description;

        state.status = action.payload?.status;
        state.steps_after_dive = action.payload?.steps_after_dive;
        state.dive_level = action.payload?.dive_level;
        state.karma = action.payload?.karma;
        state.supply = action.payload?.supply;
        state.order = action.payload?.order;
        state.aggression = action.payload?.aggression;
        state.diplomacy = action.payload?.diplomacy;
        state.faith = action.payload?.faith;
        state.freight_limit = action.payload?.freight_limit;
        state.morale = action.payload?.morale;
        state.freight = action.payload?.freight;
        state.cargo = action.payload?.cargo;
        state.frags = action.payload?.frags;
        state.users = action.payload?.users;
        state.voyage = action.payload?.voyage;
        
        state.morale_limit = action.payload?.morale_limit;
        state.order_limit = action.payload?.order_limit;
        state.supply_limit = action.payload?.supply_limit;

        state.days_count = action.payload?.days_count;
        state.steps_limit = action.payload?.steps_limit;
        state.direction = action.payload?.direction;
        state.loc_x = action.payload?.loc_x;
        state.loc_y = action.payload?.loc_y;
        state.target_x = action.payload?.target_x;
        state.target_y = action.payload?.target_y;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setTeam } = teamSlice.actions;

export default teamSlice.reducer;
