import React, { useRef,useEffect, useState, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';

import ky from 'ky';
import EquipCard from '../components/inventory/EquipCard';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import items from '../utils/items';
import skills from '../utils/skills';
import { Pos } from '../models/types'; // Import the PosArray type definition

import {setUser} from '../slices/userSlice';
import {setBattle} from '../slices/battleSlice';
import {setPosArray} from '../slices/posArraySlice';
import { RESET_STORE } from '../utils/consts';

/*
import ky from 'ky';
import locales from '../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";*/
import {Canvas, useFrame, useThree} from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, Points} from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import * as THREE from "three";
import { Line, Billboard } from "@react-three/drei";
import './App.css'
// import styles from "./About.module.css"
import { DoubleSide } from 'three'
import Counter from '../components/Battle/Counter'
import CommandAnimations from '../components/Battle/CommandAnimations'
import Indicators from '../components/Battle/Indicators'
import { User } from '../models/types';
import { Navigate } from 'react-router-dom';
const API_BASE_URL = process.env.REACT_APP_BASE_URL;

const star_color = "#82705E"
const light_color= "#FEDBB7"
const main_color= "#CBAF92"
const darker_color ="#A28C75"
const rocket_color = '#FF00FF'

const meeting_skills : any= 
    [
        {
            "name": "fight",
            "ref_name" :"fight",
            "short_name":"БОЙ",
            "small_description": "НАПАСТЬ",
            "description": 'Победитель забирает все', //"block all attacks without counter-shield option",
        },
        {
          "name": "trade",
          "ref_name" :"trade",
          "short_name":"ТОРГ",
          "small_description": "ОБМЕН ТОВАРАМИ",
          "description": '+2 груза обеим командам. Штраф ЭНР при засаде', //"block all attacks without counter-shield option",
        },
        {
          "name": "evade",
          "ref_name" :"evade",
          "short_name":"УЙТИ",
          "small_description": "СКРЫТЬСЯ ОТ ПРОТИВНИКА",
          "description": '30% вероятность уйти от нападения', //"block all attacks without counter-shield option",
        },

]

const animation_speed_coef = 5/100000

const command_options ={
  pre_fight:[
    {ref_name: "trade", short_name:"ТОРГ"},
    {ref_name: "fight", short_name:"БОЙ"},
    {ref_name: "evade", short_name:"УЙТИ"},
  
    //{name: "special",ref_name: "def", small:"->"},
  ],
  vs_target:[
  {ref_name: "def", short_name:"ЩИТ"},
  {ref_name: "atk", short_name:"АТК"},
  {ref_name: "over", short_name:"ПУСК"},
  {ref_name: "squadron", short_name:"ИСТ"},
  {ref_name: "ew_pulse", short_name:"РЭБ"},
  {ref_name: "emi_over", short_name:"ЭМИ"},
  {ref_name: "traps", short_name:"ЛОВ"},

  //{name: "special",ref_name: "def", small:"->"},
],
  vs_enemy:[
    {name: "aim",ref_name: "def", small:"+1ep [def]", short_name:"ЦЕЛЬ"},
    {ref_name: "traps", short_name:"ЛОВ"},
    {ref_name: "ew_pulse", short_name:"РЭБ"},
  ],
  vs_ally:[]
}

function getSkillJson(ref_name:string){
  if (ref_name == 'trade' || ref_name == 'fight' || ref_name == 'evade'){
    return meeting_skills?.find((el:any) => el.ref_name === ref_name)
  }
  return skills?.find((el:any) => el.ref_name === ref_name)
}

function idToLetter(id : number){
  let arr = ["К","М","У","И","Н","Х","Л"]
  let num = id % arr.length
  return arr[num].toString()
}

const sizes={
  width: window.innerWidth,
  height: window.innerHeight
}
const random_array_length = 500

const RandomArray = new Array(random_array_length)
for(let i = 0; i<random_array_length; i++){
  RandomArray[i] = Math.random()
}

function PseudoRand(hash:number){
  const salt1 = 124
  const salt2 = 23
  if(hash > 0 )
  {
    return RandomArray[ (hash*salt2 + salt1)%random_array_length]
  }
  return RandomArray[ (hash*salt2*(-1) + salt1)%random_array_length]
}

const loader = new TextureLoader();
const texture_enemy = loader.load("../markers/triangle2.svg");
const texture_enemy_target = loader.load("../markers/triangle2_target.svg");
const texture_enemy_dead = loader.load("../markers/redcross.svg");
const texture_ally = loader.load("../markers/triangle1.svg");
const texture_ally_target = loader.load("../markers/triangle_fill.svg")
const texture_ally_dead = loader.load("../markers/bluecross.svg");
const texture_rocket_block  = loader.load("../markers/whitecross.svg");


export default function Battle() {

  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(0)
  const [commandsDescriptionVisible, setCommandsDescriptionVisible] = useState(1)
  const [selectedCommand, setSelectedCommand] = useState('')
  const [targetType, setTargetType] = useState('none')
  const [lastCommandStep, setLastCommandStep] = useState(-1)
  const [lastAnimationStepNum, setLastAnimationStepNum] = useState(-1)
  const [posArray, setPosArray] = useState<Pos[]>([]);
  const [errorCommand, setErrorCommand] = useState(false)
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');

  const user = useSelector((state:RootState)=> state.user);
  const battle = useSelector((state:RootState)=> state.battle);
  // Memoize the CommandAnimations component using useMemo

  useEffect(() => {
   fetchBattle(0);
  }, [dispatch]);

    
  async function fetchUser() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/players/get_user?hash_id=${hash_id}`;

      const result = await ky.get(requestUrl, { credentials: 'include' }).json();
      dispatch(setUser(result));
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };

  const handleReset = () => {
    dispatch({ type: RESET_STORE });
  };

  
  const handleLogObjects = (object:any) => {
      if (object.name) {
        console.log(`Object Name: ${object.name}`);
      } else {
        console.log('Unnamed Object:', object);
      }
  };


  
function disposeGroup(group :any) {
  group.traverse((object :any) => {
    if (object instanceof THREE.Mesh || object instanceof THREE.Points) {
      // Dispose of the object's geometry and material
      if (object.geometry) {
        object.geometry.dispose();
      }
      if (object.material) {
        if (object.material.map) {
          object.material.map.dispose();
        }
        object.material.dispose();
      }
    }
  });

  // Clear the group's children
  group.children.length = 0;
}



  let time_left_sec = Math.floor(((new Date(battle.step_end_date)).getTime() -(new Date()).getTime() )/1000)
  //const user_in_battle : any= battle.users.find(e => e.id == user.id)

  const ally_team = battle.teams?.filter(e=>{
    return (e?.id == user.team.id) 
  })[0]?.users


  const enemy_team = battle.teams?.filter(e=>{
    return (e?.id != user.team.id) 
  })[0]?.users

  const user_in_battle = ally_team?.find(e => e.id == user.id)

  //const ally_team = battle.users.filter(e=>{
  //  return (e.Users_Battles_join.team == user_in_battle?.Users_Battles_join?.team) 
  //})

  //const enemy_team = battle.users.filter(e=>{
  //  return (e.Users_Battles_join.team != user_in_battle?.Users_Battles_join?.team) 
  //})

  let user_is_waiting = false

  let target_array :any = []
  let user_target_id = 0
  battle?.battle_logs?.forEach((e)=>{
    const more = battle.battle_logs.find(
      (second_instance)=>(
        second_instance.user_id == e.user_id && second_instance.step_num > e.step_num && second_instance.step_num != 0
      )
    )
    if(!more){
      target_array.push(
        {from_id:e.user_id, to_id:e.target_id},
      )
      if(e.user_id == user.id){
        user_target_id = e.target_id
        if(e.step_num >= battle.step_num){
          user_is_waiting = true
        }
      }
    }
  })

   //цель игрока, может быть нулевой

  /*
  const target_array :any[] = [
    {from_id:1, to_id:3},
    {from_id:2, to_id:3},
    {from_id:3, to_id:2}
  ]*/


  //console.log("battle",battle)
  //const equip_equiped = user.equips?.filter((el:any) => el.item_type === "equip")
  //const crew_equiped = user.equips?.filter((el:any) => el.item_type === "crew")

  const target = enemy_team?.concat(ally_team).find(e => e.id == selectedId)
  console.log(target)


  const particlesCntAlly = ally_team ? ally_team?.length : 0
  const angArrayAlly = new Array(particlesCntAlly)
  const posArrayAlly = new Array(particlesCntAlly)
  const radiusAlly = 0.7





  for (let i = 0; i < particlesCntAlly; i++){
    if (ally_team[i].id == user.id)
    {
      posArrayAlly[i] = {
        x: 0, 
        y: 0, 
        z: 0,
        alpha: 0,
        beta: 0,
        alpha_delta: 0,
        beta_delta: 0,
        id: user.id,
        is_alive: (ally_team[i]?.hp > 0),
        hp: ally_team[i]?.hp,
        ep: ally_team[i]?.ep,
        radius: 0,
        enemy:false,
      }
      angArrayAlly[i] = {alpha: 0, beta: 0}
    }
    else{
      let alpha = PseudoRand(i+43) * 2 *Math.PI
      let beta = PseudoRand(i+55) * 2  * Math.PI
      angArrayAlly[i] = {alpha: alpha, beta: beta}
      posArrayAlly[i] = {
        x: Math.cos(alpha)* Math.cos(beta) * radiusAlly, 
        y: Math.sin(beta) * radiusAlly, 
        z: Math.sin(alpha) * Math.cos(beta) * radiusAlly,
        alpha: alpha,
        beta: beta,
        alpha_delta: Math.sign(PseudoRand(i + 5) - 0.5)*(PseudoRand(i+14) +0.3) * Math.PI / 2,
        beta_delta: Math.sign(PseudoRand(i+242) - 0.5)*(PseudoRand(i+143) +0.3) * Math.PI  / 2,
        id: ally_team[i]?.id,
        is_alive: (ally_team[i]?.hp > 0),
        hp: ally_team[i]?.hp,
        ep: ally_team[i]?.ep,
        radius: radiusAlly,
        enemy:false,
      }
    }
  }


  const particlesCntEnemy = enemy_team ? enemy_team?.length : 0
  const posArrayEnemy = new Array(particlesCntEnemy)
  const angArrayEnemy = new Array(particlesCntEnemy)
  const radiusEnemy = 2


  for (let i = 0; i < particlesCntEnemy; i++){
    //let alpha2 = Math.random() * 2 *Math.PI
    //let beta2 = Math.random() * 2  * Math.PI
    let alpha2 = 0
    let beta2 = 0
    if(i==0){
      alpha2 = (PseudoRand(i+130) -0.5) *Math.PI/4
      beta2 = (PseudoRand(i+32) -0.5) * Math.PI/4
    }
    else
    {
      alpha2 = angArrayEnemy[i-1].alpha + (PseudoRand(i+52) +0.2) *Math.PI / 3
      beta2 = angArrayEnemy[i-1].beta + (PseudoRand(i+64) -0.5)  * Math.PI /3
    }
    angArrayEnemy[i] = {alpha: alpha2, beta: beta2}
    posArrayEnemy[i] = {
      x: Math.cos(alpha2)* Math.cos(beta2) * radiusEnemy, 
      y: Math.sin(beta2) * radiusEnemy,
      z: Math.sin(alpha2) * Math.cos(beta2) * radiusEnemy,
      alpha: alpha2,
      beta: beta2,
      alpha_delta: Math.sign(PseudoRand(i + 279) - 0.5) * (PseudoRand(i + 202) + 0.5) * (Math.PI / 3),
      beta_delta: Math.sign(PseudoRand(i + 103) - 0.5) * (PseudoRand(i + 33) + 0.5) * (Math.PI / 3),
      id: enemy_team[i]?.id,
      is_alive: (enemy_team[i]?.hp > 0),
      hp: enemy_team[i]?.hp,
      ep: enemy_team[i]?.ep,
      radius: radiusEnemy,
      enemy:true,
    }
  };
  if(lastAnimationStepNum < battle.step_num){
    let pos_array :Pos[] =  [...posArrayEnemy,...posArrayAlly]
    setPosArray( pos_array)
    setLastAnimationStepNum(battle.step_num)
  }

  const memoizedCommandAnimations = useMemo(() => {
        console.log('animation memo rerender')
    return <CommandAnimations posArray={posArray} battle={battle} />
  }, [posArray]);


  const memoizedIndicators = useMemo(() => {
      console.log('indicators memo rerender')
  return <Indicators posArrayAlly={posArrayAlly} posArrayEnemy={posArrayEnemy} battle={battle} onClickFunc={selectTarget}  />
  }, [posArray]);


  const Sphere1 = useMemo(() => {
    console.log('Sphere rerender')
    const particlesCnt = 150
    const pos_array = new Float32Array(particlesCnt*3)
    const radius = radiusEnemy
    let alpha = 0
    let beta = 0
    for (let i = 0; i < particlesCnt*3; i+=3){
      alpha = PseudoRand(i+14) * 2 *Math.PI 
      beta = PseudoRand(i+182) * 2  * Math.PI
      pos_array[i] = Math.sin(alpha)* Math.cos(beta) * radius
      pos_array[i+1] = Math.cos(alpha) * Math.cos(beta) * radius
      pos_array[i+2] = Math.sin(beta) * radius
    }

    return(
      <points
      rotation={[Math.PI/2,0,0]}
      >
        <bufferGeometry>
        <bufferAttribute
                // ref={positionsRef}
                  attach='attributes-position'
                  array={pos_array}
                  count={pos_array.length / 3}
                  itemSize={3}
              />
        </bufferGeometry>
        <pointsMaterial size={0.015} color={main_color}/>
      </points>
    );
        return(
      <points>
        <sphereGeometry args ={[2, 30, 20 ]}/>
      <pointsMaterial size={0.01} sizeAttenuation  color={light_color}/*color={"#6c6c6c"}*//>
      </points>
      
    );

    return(
      <points>
        <sphereGeometry args ={[2, 30, 20 ]}/>
      <pointsMaterial size={0.015} sizeAttenuation  color={darker_color}/*color={"#6c6c6c"}*//>
      </points>
      
    );
    return(
      <>

    <points 
    rotation={[Math.PI/2,0,0]}
    >
      <sphereGeometry args ={[2, 6, 50]}/>
    <pointsMaterial size={0.01} sizeAttenuation  color={darker_color}/>
    </points>
    <points 
    >
      <sphereGeometry args ={[2, 6, 50]}/>
    <pointsMaterial size={0.01} sizeAttenuation  color={darker_color}/>
    </points>
    </>
    );
  }, []);

  function tanRand(x:any) { 
    let new_rand = (Math.tan((x * Math.PI / 2) - Math.PI/4) + 1)/2
    return new_rand
  } // возвращает от 0 до 1, но менее вероятно 0 или 1


  const Sphere1_extra = useMemo(() => {
    const particlesCnt = 350
    const pos_array = new Float32Array(particlesCnt*3)
    const radius = radiusEnemy
    let alpha = 0
    let beta = 0
    for (let i = 0; i < particlesCnt*3; i+=3){
      alpha = PseudoRand(i+185) * 2 *Math.PI 
      beta = (tanRand(PseudoRand(i+179)))* Math.PI + Math.PI /2
      pos_array[i] = Math.sin(alpha)* Math.cos(beta) * radius
      pos_array[i+1] = Math.cos(alpha) * Math.cos(beta) * radius
      pos_array[i+2] = Math.sin(beta) * radius
    }

    return(
      <points>
        <bufferGeometry>
        <bufferAttribute
                // ref={positionsRef}
                  attach='attributes-position'
                  array={pos_array}
                  count={pos_array.length / 3}
                  itemSize={3}
              />
        </bufferGeometry>
        <pointsMaterial size={0.01} color={main_color}/>
      </points>
    );
  }, []);



  const Sphere2 = useMemo(() => {
    return(
      <>
      <points>
        <sphereGeometry args ={[radiusAlly, 21, 14]}/>
      <pointsMaterial size={0.01} sizeAttenuation color={light_color}/*color={"#53483C"}*//>
      </points>
      </>
    );
  }, []);

  const InnerCircle = useMemo(() => {
    return(
      <>
      <points>
        <sphereGeometry args ={[radiusAlly, 40, 2]}/>
      <pointsMaterial size={0.01} sizeAttenuation color={'cyan'}/*color={"#53483C"}*//>
      </points>
      </>
    );
  }, []);

  const InnerCircle2 = useMemo(() => {
    return(
      <>
      <points>
        <sphereGeometry args ={[radiusAlly-0.02, 40, 2]}/>
      <pointsMaterial size={0.02} sizeAttenuation color={main_color}/*color={"#53483C"}*//>
      </points>
      </>
    );
  }, []);


  const RandomSphere = useMemo(() => {
    const particlesCnt = 250
    const pos_array = new Float32Array(particlesCnt*3)
    const radius = 10

    for (let i = 0; i < particlesCnt*3; i+=3){
      let alpha = PseudoRand(i+14) * 2 *Math.PI 
      let beta = PseudoRand(i+182) * 2  * Math.PI
      pos_array[i] = Math.sin(alpha)* Math.cos(beta) * radius
      pos_array[i+1] = Math.cos(alpha) * Math.cos(beta) * radius
      pos_array[i+2] = Math.sin(beta) * radius
    }

    return(
      <points>
        <bufferGeometry>
        <bufferAttribute
                // ref={positionsRef}
                  attach='attributes-position'
                  array={pos_array}
                  count={pos_array.length / 3}
                  itemSize={3}
              />
        </bufferGeometry>
        <pointsMaterial size={0.05} color={star_color}/>
      </points>
    );
  }, []); // Empty dependency array ensures it's computed only once

  function selectTarget(user_id : number, type: string){
    setSelectedId(user_id)
    setSelectedCommand('')
    setTargetType(type)
    setErrorCommand(false)
  }

  function selectNextTarget(){
    let old_user_id = selectedId
    let index = posArrayEnemy.findIndex((e)=>(e.id == selectedId))
    if(index == posArrayEnemy.length - 1){ index = -1 }
    selectTarget(posArrayEnemy[index + 1].id, 'enemy')
  }


  const MarksAlly=() =>{
    const { camera } = useThree(); // Access the camera from the Canvas context
    const groupRef :any = useRef();
    const markRefs :any = useRef([]);
    const textRefs :any = useRef([]);
    let last_command = null;
    let elapsedTime = 0
    let alpha = 0
    let beta = 0 
    let d = 0
    let sc = 0
    let new_pos = {x:0, y: 0, z:0}
    let j = 0
    let time_shift = 0
    useEffect(() => {
      markRefs.current = markRefs.current.slice(0, posArrayAlly.length);
      textRefs.current = textRefs.current.slice(0, posArrayAlly.length);
    }, [posArrayAlly]);



    useFrame(() => {
      elapsedTime = Date.now();

      time_shift = elapsedTime*animation_speed_coef;
      //disposeGroup(groupRef.current)

      for (j = 0; j < particlesCntAlly; j++) {

        //position change
          
        alpha = posArrayAlly[j].alpha + time_shift * posArrayAlly[j].alpha_delta / 2;
        beta = posArrayAlly[j].beta + time_shift * posArrayAlly[j].beta_delta / 2;

        new_pos = getCoords(alpha, beta, posArrayAlly[j].radius)
        markRefs.current[j].position.x = new_pos.x
        markRefs.current[j].position.y = new_pos.y
        markRefs.current[j].position.z = new_pos.z
        //textRefs.current[j].lookAt(camera.position); // Make the text face the camera

        //d = Math.floor(time_shift +3) % 2
        //sc = d * 0.05 + 0.20; // 0 - 0.25
        //markRefs.current[j].scale.x = sc
        //markRefs.current[j].scale.y = sc
       // markRefs.current[j].scale.z = sc

        //markRefs.current[index].visible = !(d < 2 || d >= 5 && d <8) ;

      }
  
    })
    
    return (
      <>
      {posArrayAlly.map((pos, index)=>{
        last_command = battle.battle_logs.filter((el:any) => el.user_id == pos?.id && el.step_num == battle.step_num - 1)[0]
        return(
          <group
            key={"mark-ally"+pos.x +"-"+pos.y}            
            ref={pos => markRefs.current[index] = pos} 
            position={[pos.x, pos.y, pos.z]}
          >

                  <sprite 
                    scale={0.25}
                    onClick = {()=>{ selectTarget(pos?.id, 'ally'); console.log('target', pos, markRefs.current[index])}}
                    >
                    
                    <spriteMaterial depthWrite={true} depthTest={true} map={!pos?.is_alive ?
                    texture_ally_dead :
                    pos.x == 0 && pos.y == 0 && pos.z == 0 ? 
                    texture_ally_target :
                    pos.id == selectedId ? texture_ally_target : texture_ally } 
                        
                          rotation={Math.PI}
                    />
                  </sprite>

          </group>
        )
      }
      )}
    </>
    )

  };


  

  const MarksEnemy=() =>{
    const { camera } = useThree(); // Access the camera from the Canvas context

    const groupRefs :any = useRef();
    const markRefs :any = useRef([]);
    const textRefs :any = useRef([]);
    let last_command = null;
    // you can access the elements with itemsRef.current[n]
    let elapsedTime = 0
    let alpha = 0
    let beta = 0 
    let d = 0
    let sc = 0
    let new_pos = {x:0, y: 0, z:0}
    let j = 0
    let time_shift = 0

    useEffect(() => {
      markRefs.current = markRefs.current.slice(0, posArrayEnemy.length);
      textRefs.current = textRefs.current.slice(0, posArrayAlly.length);
      console.log()
    }, [posArrayEnemy, selectedId]);


    //const markRefs :any = useRefArray(posArrayEnemy.length);
    useFrame(() => {
      elapsedTime = Date.now();

      time_shift = elapsedTime*animation_speed_coef;
      //disposeGroup(groupRef.current)


      for (j = 0; j < particlesCntEnemy; j++) {

        
        //position change
            
        alpha = posArrayEnemy[j].alpha + time_shift * posArrayEnemy[j].alpha_delta / 2;
        beta = posArrayEnemy[j].beta + time_shift * posArrayEnemy[j].beta_delta / 2;

        new_pos = getCoords(alpha, beta, posArrayEnemy[j].radius)

        if (markRefs.current[j]) {  
          markRefs.current[j].position.x = new_pos.x;
          markRefs.current[j].position.y = new_pos.y;
          markRefs.current[j].position.z = new_pos.z;
        }

        //d = Math.floor(time_shift +3) % 2
        //sc = d * 0.05 + 0.20; // 0 - 0.25
        //markRefs.current[j].scale.x = sc
        //markRefs.current[j].scale.y = sc
        //markRefs.current[j].scale.z = sc

        //markRefs.current[index].visible = !(d < 2 || d >= 5 && d <8) ;

      }
  
    });

    return (
      <>
        {posArrayEnemy.map((pos, index)=>{
        last_command = battle.battle_logs.filter((el:any) => el.user_id == pos?.id && el.step_num == battle.step_num - 1)[0]

        return(
          <group 
            ref={(sprite) => (markRefs.current[index] = sprite)}  // Use the ref from the array
            position={[pos.x, pos.y, pos.z]}
            key={"mark-enemy"+pos.x +"-"+pos.y}            

          >
          <sprite 
            onClick = {()=>{ selectTarget(pos?.id, 'enemy')}}
            scale={0.25}
          >
              
            <spriteMaterial depthWrite={true} depthTest={true} map={!pos?.is_alive ? texture_enemy_dead : pos.id == selectedId ? texture_enemy_target : texture_enemy} 
                
                  rotation={Math.PI}
            />
            </sprite>
            </group>)

        }
      )}

      </>

    );

  };



  const TraceTorus = () => {
    //useFrame(() => (ref.current.rotation.x = ref.current.rotation.y += 0.01));

    return (
      <>
          <mesh
          visible
          position={[0,0,0]}
          rotation={[-Math.PI/2 + angArrayEnemy[0].alpha, -angArrayEnemy[0].alpha,  -Math.PI/2 + angArrayEnemy[0].beta]}
          >
            <torusGeometry args={[2, 0.005, 6, 150, Math.PI]} />
            <meshBasicMaterial attach="material" color="white" />
      </mesh>
      <mesh
          visible
          position={[0,0,0]}
          rotation={[0, -angArrayEnemy[0].alpha,0]}
          >
            <torusGeometry args={[2, 0.005, 4, 150, 6 ]} />
            <meshBasicMaterial attach="material" color="blue" />
      </mesh>
      <mesh
      visible
          position={[0,0,0]}
          rotation={[0, -angArrayEnemy[0].alpha, angArrayEnemy[0].beta]}

          >
            <torusGeometry args={[2, 0.005, 6 , 150, 0.1]} />
            <meshBasicMaterial attach="material" color="red" />
      </mesh>
      <mesh
      visible
          position={[0,0,0]}
          rotation={[10, 0, 1]}

          >
            <torusGeometry args={[2, 0.005, 6 , 150, 10]} />
            <meshBasicMaterial attach="material" color="grey" />
      </mesh>
      <mesh
      visible
          position={[0,0,0]}
          >
            <torusGeometry args={[2, 0.005, 4, 150, 0.1]} />
            <meshBasicMaterial attach="material" color="green" />
      </mesh>
      </>
    );
  }

  const TracesEnemy = () => {
    const groupRef :any = useRef();
    let elapsedTime, alpha,beta, orig_alpha,orig_beta,alpha_delta,beta_delta = 0
    let j, i, time_shift, a, d, sc, disc_time_shift = 0
    let new_pos = {x:0, y: 0, z:0}
    let points = [];
    let n = 15;
    let path_n = 15;
    let shift = 0.01;
    let curve1, curve2, geometry, material, tubeMesh, curvePoints, vertices, pointsGeometry, pointsMaterial, path= null;

    useFrame(() => {
      elapsedTime = Date.now();

      time_shift = elapsedTime*animation_speed_coef;  
      disc_time_shift = Math.floor(time_shift *10) /10

      disposeGroup(groupRef.current)

      for (j = 0; j < particlesCntEnemy; j++) {
        if(posArrayEnemy[j].is_alive){
          points = [];
      
          // ... Generate points based on your logic ...
      
          orig_alpha = posArrayEnemy[j].alpha;
          orig_beta = posArrayEnemy[j].beta;
          alpha_delta = posArrayEnemy[j].alpha_delta;
          beta_delta = posArrayEnemy[j].beta_delta;
      
          for (i = 0; i < n; i++) {
            alpha = orig_alpha - (i / n + shift - time_shift) * alpha_delta / 2;
            beta = orig_beta - (i / n + shift - time_shift) * beta_delta / 2;
            new_pos = getCoords(alpha, beta, radiusEnemy)

            points.push(new THREE.Vector3(new_pos.x,new_pos.y,new_pos.z))
          }
      
          curve1 = new THREE.CatmullRomCurve3(points);
      
          points = [];
          for (i = 0; i < path_n; i++) {
            alpha = orig_alpha - (-i / n - shift - disc_time_shift) * alpha_delta/ 2;
            beta = orig_beta - (-i / n - shift- disc_time_shift) * beta_delta/ 2;
            new_pos = getCoords(alpha, beta, radiusEnemy)
            points.push(new THREE.Vector3(new_pos.x,new_pos.y,new_pos.z))
          }
          curve2 = new THREE.CatmullRomCurve3(points);
      
          // Create a tube geometry
          geometry = new THREE.TubeGeometry(curve1, 70, 0.004, 4, false);
          material = new THREE.MeshBasicMaterial({ color: darker_color });
          tubeMesh = new THREE.Mesh(geometry, material);
          
          // Extract points from the curve
          curvePoints = points; // 'someNumberOfPoints' is the number of points you want to sample

            // Convert the curve points to a flat Float32Array
            vertices = new Float32Array(curvePoints.flatMap(point => [point.x, point.y, point.z]));

            // Create a buffer geometry and set the 'position' attribute
            pointsGeometry = new THREE.BufferGeometry();
            pointsGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

            // Create a points material
            pointsMaterial = new THREE.PointsMaterial({ size: 0.01, color: darker_color });

          path = new THREE.Points(pointsGeometry, pointsMaterial); // Create the Points object

          //d = 1//Math.floor(a +3) % 4

          // Set the position and rotation of the tube mesh if needed
          tubeMesh.position.set(0, 0, 0);
          tubeMesh.rotation.set(0, 0, 0);
      
          // Add the tube mesh to the Three.js scene
          if (groupRef && groupRef.current){
            groupRef.current.add(tubeMesh);
            groupRef.current.add(path)
          }
          
        }
      }
    });
  
    return <group ref={groupRef} />;
  };


  function getCoords(alpha:number, beta:number, radius:number){
    return {x : Math.cos(alpha) * Math.cos(beta) * radius,
      y: Math.sin(beta) * radius, 
      z: Math.sin(alpha) * Math.cos(beta) * radius
    }
  }

  const Aim = () =>{
    // Create a curve based on the points
    const groupRef :any = useRef();

    let elapsedTime, alpha,beta, from_alpha,from_beta,to_alpha,to_beta = 0
    let j, i, d, sc, disc_time_shift = 0
    let time_shift = 0
    let from, to, from_pos, to_pos= {x:0, y: 0, z:0}
    let enemy_points, ally_points = [];
    let n = 15;
    let shift = 0.01;
    let curve1, curve2, geometry, material, tubeMesh, curvePoints, vertices, pointsGeometry, pointsMaterial, path = null;
    let old_tubeMesh:any = Array(target_array)
    let coef = 0.3

    //posArrayEnemy
    //enemy_target_array
    useFrame(() => {
     
      elapsedTime = Date.now();

      time_shift = elapsedTime*animation_speed_coef;

      disposeGroup(groupRef.current)

      target_array.forEach((e:any, index: number)=>{

        from = posArrayEnemy.find((p) => p.id == e.from_id)
        to = posArrayAlly.find((p) => p.id == e.to_id)
        if(from && to && from.is_alive && to.is_alive){
          j = from.id

          from_alpha = from.alpha + (time_shift) * from.alpha_delta / 2
          from_beta = from.beta + (time_shift) * from.beta_delta / 2
          from_pos = getCoords(from_alpha, from_beta, from.radius) 

          to_alpha = to.alpha + (time_shift) * to.alpha_delta / 2
          to_beta = to.beta + (time_shift) * to.beta_delta / 2
          to_pos = getCoords(to_alpha, to_beta, to.radius) 

          enemy_points = [
              new THREE.Vector3(from_pos.x, from_pos.y, from_pos.z), 
              new THREE.Vector3( to_pos.x*(1-coef) + from_pos.x*coef, to_pos.y*(1-coef) + from_pos.y*coef, to_pos.z*(1-coef) + from_pos.z*coef ),
              //new THREE.Vector3(to.x, to.y, to.z), 
            ]

          geometry = new THREE.TubeGeometry(new THREE.CatmullRomCurve3(enemy_points), 5, 0.006, 4, false);
          material = new THREE.MeshBasicMaterial({ color: "crimson" });
          tubeMesh = new THREE.Mesh(geometry, material);

          if (groupRef && groupRef.current){
            groupRef.current.add(tubeMesh);
          }

        }
      })

      target_array.forEach((e:any)=>{
        from = posArrayAlly.find((p) => p.id == e.from_id)
        to = posArrayEnemy.find((p) => p.id == e.to_id)

        if(from && to && from.is_alive && to.is_alive){
          from_alpha = from.alpha + (time_shift) * from.alpha_delta / 2
          from_beta = from.beta + (time_shift) * from.beta_delta / 2
          from_pos = getCoords(from_alpha, from_beta, from.radius) 

          to_alpha = to.alpha + (time_shift) * to.alpha_delta / 2
          to_beta = to.beta + (time_shift) * to.beta_delta / 2
          to_pos = getCoords(to_alpha, to_beta, to.radius) 

          ally_points =
            [
              new THREE.Vector3(from_pos.x, from_pos.y, from_pos.z), 
              new THREE.Vector3( to_pos.x*(1-coef) + from_pos.x*coef, to_pos.y*(1-coef) + from_pos.y*coef, to_pos.z*(1-coef) + from_pos.z*coef ),
              //new THREE.Vector3(to.x, to.y, to.z), 
            ]

          geometry = new THREE.TubeGeometry(new THREE.CatmullRomCurve3(ally_points), 5, 0.004, 4, false);
          material = new THREE.MeshBasicMaterial({ color: "cyan" });
          tubeMesh = new THREE.Mesh(geometry, material);
    
          if (groupRef && groupRef.current){
            groupRef.current.add(tubeMesh);
          }
        }
      })

        
      // Create a tube geometry

  })
    return <group ref={groupRef} />;
  }
    

  const TracesAlly = () => {
    const groupRef :any = useRef();

    let elapsedTime, alpha,beta, orig_alpha,orig_beta,alpha_delta,beta_delta = 0
    let j, i, time_shift, d, sc, disc_time_shift = 0
    let new_pos = {x:0, y: 0, z:0}
    let points = [];
    let n = 15;
    let shift = 0.01;
    let curve1, curve2, geometry, material, tubeMesh, curvePoints, vertices, pointsGeometry, pointsMaterial, path = null;

    useFrame(() => {
      elapsedTime = Date.now();

      time_shift = elapsedTime*animation_speed_coef;
      disc_time_shift = Math.floor(time_shift *10) /10

      disposeGroup(groupRef.current)

      for (j = 0; j < particlesCntAlly; j++) {
        points = [];
    
        // ... Generate points based on your logic ...
    
        orig_alpha = posArrayAlly[j].alpha;
        orig_beta = posArrayAlly[j].beta;
        alpha_delta = posArrayAlly[j].alpha_delta;
        beta_delta = posArrayAlly[j].beta_delta;
        n = 15;
        shift = 0.01;
    
        for (i = 0; i < n; i++) {
          alpha = orig_alpha - (i / n + shift - time_shift) * alpha_delta / 2;
          beta = orig_beta - (i / n + shift - time_shift) * beta_delta / 2;
          new_pos = getCoords(alpha, beta, radiusAlly)

          points.push(new THREE.Vector3(new_pos.x,new_pos.y,new_pos.z))
        }
    
        curve1 = new THREE.CatmullRomCurve3(points);
    
        points = [];
        for (i = 0; i < n; i++) {
          alpha = orig_alpha - (-i / n - shift - disc_time_shift) * alpha_delta/ 2;
          beta = orig_beta - (-i / n - shift- disc_time_shift) * beta_delta/ 2;
          new_pos = getCoords(alpha, beta, radiusAlly)
          points.push(new THREE.Vector3(new_pos.x,new_pos.y,new_pos.z))
        }
        curve2 = new THREE.CatmullRomCurve3(points);
    
        // Create a tube geometry
        geometry = new THREE.TubeGeometry(curve1, 70, 0.004, 4, false);
        
        material = new THREE.MeshBasicMaterial({ color: darker_color });
        tubeMesh = new THREE.Mesh(geometry, material);
        
        // Extract points from the curve
        curvePoints = points; // 'someNumberOfPoints' is the number of points you want to sample

          // Convert the curve points to a flat Float32Array
          vertices = new Float32Array(curvePoints.flatMap(point => [point.x, point.y, point.z]));

          // Create a buffer geometry and set the 'position' attribute
          pointsGeometry = new THREE.BufferGeometry();
          pointsGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

          // Create a points material
          pointsMaterial = new THREE.PointsMaterial({ size: 0.01, color: darker_color });

        path = new THREE.Points(pointsGeometry, pointsMaterial); // Create the Points object

        //d = 1//Math.floor(a +3) % 4

        // Set the position and rotation of the tube mesh if needed
        tubeMesh.position.set(0, 0, 0);
        tubeMesh.rotation.set(0, 0, 0);
    
        // Add the tube mesh to the Three.js scene
        if (groupRef && groupRef.current){
          groupRef.current.add(tubeMesh);
          groupRef.current.add(path)
        }
      }
    });
  
    return <group ref={groupRef} />;
  };

  const TracesAlly_old = () =>{
    // Create a curve based on the points
    if(particlesCntAlly < 2)
    {return <></>}
    let result_trace = Array(particlesCntAlly - 1)
    let result_path = Array(particlesCntAlly - 1)
    for(let j=0; j < particlesCntAlly; j++)
    {
        if (ally_team[j]?.id != user.id)
        {
          // Create an empty array to stores the points
          let points = []
          // Define points along Z axis


          let orig_alpha = posArrayAlly[j].alpha;
          let orig_beta = posArrayAlly[j].beta;
          let alpha_delta = posArrayAlly[j].alpha_delta;
          let beta_delta = posArrayAlly[j].beta_delta;
          const n = 10
          const shift = 0.01
          for (let i = 0; i < n; i++){  
            let alpha = orig_alpha + (i/n + shift) * alpha_delta
            let beta = orig_beta + (i/n + shift) * beta_delta
            let new_pos = getCoords(alpha, beta, radiusAlly)
            points.push(new THREE.Vector3(new_pos.x,new_pos.y,new_pos.z))
          }
          let curve1 = new THREE.CatmullRomCurve3(points)
          result_trace.push(curve1)
          points = []
          for (let i = 0; i < n; i++){  
            let alpha = orig_alpha - (i/n + shift) * alpha_delta
            let beta = orig_beta - (i/n + shift) * beta_delta
            let new_pos = getCoords(alpha, beta, radiusAlly)
            points.push(new THREE.Vector3(new_pos.x,new_pos.y,new_pos.z))
          }
          let curve2 = new THREE.CatmullRomCurve3(points)
          result_path.push(curve2)
        }
    }
    //console.log(result_trace)
    return (
      <>
              {result_trace.map((c)=> {
          return(
            
            <mesh 
            key={"trace-ally"+c?.points[0].x.toString()+c?.points[0].y.toString()}>
              <tubeGeometry args={[c, 70, 0.004, 4, false]} />
              <meshBasicMaterial attach="material" color={main_color} />
            </mesh>)
            
        })}
        {result_path.map((c)=> {
          return(
            <points 
            key={"trace-ally"+c?.points[0].x.toString()+c?.points[0].y.toString()}>
            <tubeGeometry args={[c, 12, 0.005, 4, false]} />
            <pointsMaterial size={0.01} color={main_color}/>
            </points>)
            
        })}
      </>
    )
  }

  async function fetchBattle(battle_id: number) {
    try {
      // Extract the hash_id from the URL query parameters
      // Add hash_id to the fetch request URL
      //const requestUrl = `${API_BASE_URL}/battle?hash_id=${hash_id}`;
      
      const requestUrl = `${API_BASE_URL}/gameplay/fights/get_fight/?fight_id=${battle_id}&hash_id=${hash_id}`;
      const result :any = await ky.get(requestUrl, {credentials: 'include'}).json();
      //console.log('get_fight', {'result': result})
      if (result['step_num'] != battle.step_num || (result['step_num'] == 0 && result['status'] == 'archived' )){
        dispatch(setBattle(result));
      }
    } catch (error) {
      console.error('Could not fetch battle:', error);
    }
  };

  
  async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async function fetchBattleWithRetries(battle_id: number, retries = 5, delay = 2000) {
    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        await fetchBattle(battle_id); // Call the original fetchBattle function
        // If fetchBattle succeeds, exit the loop
        return;
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error);
        
        // If this was the last attempt, throw the error
        if (attempt === retries - 1) {
          console.error('All retry attempts failed.');
          throw error;
        }
  
        // Wait for the specified delay before retrying
        await sleep(delay);
      }
    }
  }
  
  

  async function sendCommand() {
    try {
      // Extract the hash_id from the URL query parameters
      if(
        selectedCommand == '' || 
        (selectedId == 0 && battle.step_num > 0 ) || 
        ( battle.step_num == 0 && (selectedCommand != 'trade' &&  selectedCommand != 'fight' &&  selectedCommand != 'evade'))
        )
      {
        return false
      }

      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/fights/send_command/?hash_id=${hash_id}`;
      console.log('sendCommand', {command: selectedCommand, target_id:selectedId, step_num: battle.step_num, battle_id: battle.id})
      const result = await ky.put(requestUrl, { json: {command: selectedCommand, target_id:selectedId, step_num: battle.step_num, battle_id: battle.id},  credentials: 'include' }).json();
      console.log('sendCommand result', result)
      if(result){
        setLastCommandStep(battle.step_num)
        //fetchBattle()
        setSelectedCommand('')
      }

    } catch (error) {
      console.error('Could not send command:', error);
      setErrorCommand(true)
      setSelectedCommand('')
    }
  };

  async function exitRoom() {
    try {
      // Extract the hash_id from the URL query parameters
      if(!battle || battle.status== 'active')
      {
        return false
      }

      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/fights/exit/?hash_id=${hash_id}`;
      console.log('exitRoom')
      const result = await ky.put(requestUrl, { json: {battle_id: battle.id},  credentials: 'include' }).json();
      if(result){
        navigate(`/inventory?hash_id=${hash_id}`)
      }
      else{
        console.log('error on exit')
      }
    } catch (error) {
      console.error('Could not exit room', error);
    }
  };

  //function getItemJson(card_id:number, item_type:string){
  //  return items[item_type]?.find((el:any) => el.card_id === card_id)
  //}
  function getItemHull(ship : any){
    if (ship && ship?.equips){
      return ship?.equips?.find((el:any) => el.item_type === "hull")
    }
    return null
  }
  
  function indicator(square_num:number){
    if (square_num && square_num > 0)
    {
        //return "■ ".repeat(square_num)
        //return <div className='square-indicator'></div>
        return(<>
        {[...Array(square_num)].map((e, i) => <div className='square-indicator'></div>)}
        </>)
    }
    return ""
  }

  function crossIndicator(square_num:number){
    if (square_num && square_num > 0)
    {
        //return "x ".repeat(square_num)
        //return <div className='square-indicator-empty'></div>
        return(<>
          {[...Array(square_num)].map((e, i) => <div className='square-indicator-empty'></div>)}
          </>)
    }
    return ""
  }

  const OrbitLunar = () =>{
    // Create a curve based on the points
    let result_trace = Array(particlesCntAlly - 1)
    let result_path = Array(particlesCntAlly - 1)
  
        // Create an empty array to stores the points
        let points = []
        // Define points along Z axis
  
        let a = radiusAlly
        let b = 1
        let n = 80
        let slice_n = 8
        let alpha = 0
        let shift_x = 0
        let shift_z = 0
        points = Array(n)
  
        for (let i = 0; i < n; i++){  
          alpha =  2*Math.PI *(i/n) + 0.1
          let x = (a*Math.cos(alpha) +shift_x)
          let z = (b*Math.sin(alpha) + shift_z)
          let y =  0
          points[i] = new THREE.Vector3(x,y,z)
        }
        let curve2 = new THREE.CatmullRomCurve3(points)
        let curve1 = new THREE.CatmullRomCurve3(points.slice(0,slice_n))
    return (
      <>          
              <mesh>
              <tubeGeometry args={[curve1, slice_n, 0.01, 4, false]} />
              <meshBasicMaterial attach="material" color={"#A28C75"} />
            </mesh>
            
  
  
            <points>
            <tubeGeometry args={[curve2, n, 0.005, 4, false]} />
            <pointsMaterial size={0.01} color={"#A28C75"}/>
            </points>
            
        
      </>
    )
  }

  return (
    <>

    
<div className='hud no-opacity'>

{battle && battle.status != 'active' && battle.status != 'waiting' && battle.status != '' && user_in_battle?
  <div className={ battle?.result && (battle?.result?.status == 'win' || battle?.result?.status == 'mexican'|| 
  battle?.result?.status == 'trade'|| battle?.result?.status == 'evade') ? 'info-panel-center-modal fade-in' : 'info-panel-center-modal'}>
    {battle?.result && battle?.result?.status == 'win' && battle?.result?.team == user_in_battle.team.id?
    <p>Противник повержен</p>
    :
    battle?.result && battle?.result?.status == 'trade' ?
    <div>
      <p>Обмен товарами успешен...</p>
      <p>+2 груза</p>
    </div>
    :
    battle?.result && battle?.result?.status == 'evade' ?
    <p>Тепловые сигнатуры исчезли, продолжаем движение</p>
    :
    <p>Поражение...</p>
    }
    <div onClick={() => {fetchUser(); handleReset(); navigate('/voyage' + `/?hash_id=${hash_id}`)}}> <p className='order-btn' >ВЕРНУТЬСЯ К УПРАВЛЕНИЮ{/*tg://resolve?domain=hyperdivebot/*/}</p></div>
  </div>
  :<></>
}
  {battle && (!battle.id || battle.id == 0)?
  <div className={'info-panel-center-modal fade-in'}>
      <p>Бой не найден</p>
      <div onClick={() => {fetchUser(); handleReset(); navigate('/voyage' + `/?hash_id=${hash_id}`)}}> <p className='order-btn' >ВЕРНУТЬСЯ К УПРАВЛЕНИЮ{/*tg://resolve?domain=hyperdivebot/*/}</p></div>
    </div>
    :<></>
  }
</div>

    <Canvas>
    <PerspectiveCamera
        makeDefault// Set this camera as the default camera
        fov={100}
        aspect={sizes.width / sizes.height}
        position={[3.6, 3.6, -3.6]} // Camera positioned at (0, 2, 6)
        near={0.7}
        far={10}
      />
      {/*Sphere1*/}
      {InnerCircle}
      {Sphere1_extra}
      {/*RandomSphere*/}
      {/*



      */}
      
      <TracesAlly key={"traces-ally"}/>
      <TracesEnemy key={"traces-enemy"}/>

      <Aim/>
      <MarksEnemy key={"marks-enemy"}/>
      <MarksAlly key={"marks-ally"}/>

      
      {memoizedCommandAnimations}
      {memoizedIndicators}
      {/*<RotatingShield  pos={posArrayEnemy[0]} />*/}

      <OrbitControls minZoom={1} maxZoom={1} minDistance={2.1} maxDistance={3.7} enablePan={false}/>


    </Canvas>


    <div className='hud noselect'>
    <div className='commands-panel'>

    <div className='commands-list'>
    { commandsDescriptionVisible ? 
    <div className='commands-descriptions appear-with-shift-left'>
      {
        selectedCommand ? <></> :
        <div className='commands-description'>
            
        <small >Выберите действие</small>

        <small className='clickable-field' onClick={()=> setCommandsDescriptionVisible(0)} >{"< скрыть"}</small>
    </div>
      }

      {skills.map((s :any)=>{
        return(
          <>
          <div className='commands-description' style={{display: s.ref_name == selectedCommand ? 'flex' : 'none'}}>
            
              <p >{s.small_description}</p>
              <small>{s.description}</small>
              <small className='clickable-field' onClick={()=> setCommandsDescriptionVisible(0)} >{"< скрыть"}</small>
          </div>
          </>
        )
      })

      }
      {meeting_skills.map((s :any)=>{
        return(
          <>
          <div className='commands-description' style={{display: s.ref_name == selectedCommand ? 'flex' : 'none'}}>
              <p >{s.small_description}</p>
              <small>{s.description}</small>
              <small className='clickable-field' onClick={()=> setCommandsDescriptionVisible(0)} >{"< скрыть"}</small>
          </div>
          </>
        )
      })

      }
      {/*
        selectedCommand == ''?
        <div className='commands-decription' >
          <b>Выберите команду:</b>
      </div> :<></>
    */}
    </div>:
    <div className='commands-descriptions disabled clickable-field' onClick={()=> setCommandsDescriptionVisible(1)}>
      <small className='commands-description'>
      {"> > >"}
      </small>
    </div>
    }
    <div className='commands-right-block'>

      {(user.hp <= 0) ?
      <>
      <p>SIGNAL LOST</p>
      <small>critical damage</small>
      </>
      :
      
      (battle && battle.step_num == 0) && !(lastCommandStep >= battle.step_num || user_is_waiting)?
      <>
        {command_options["pre_fight"].map((c)=>{
          return <p className={c.ref_name == selectedCommand ?'selected command':'command'} 
          key={Math.ceil(Math.random() * 999999)}
          onClick={()=>{setSelectedCommand(c.ref_name); setErrorCommand(false); setSelectedId(user.id)}}
          >{c.short_name} </p>
        })}
      </>
      :

        (targetType == 'enemy' && selectedId != user_target_id && user_in_battle) ?
        <>
          {command_options["vs_enemy"].map((c)=>{
            let skill = getSkillJson(c.ref_name)
            if(skill){
              if (skill?.attribute_required && !user_in_battle.total_stats[skill?.attribute_required]){
                return <></>
              }
              if(skill?.cost.hp > user_in_battle?.hp ||
                skill?.cost.ep > user_in_battle?.ep ||
                skill?.cost.sp > user_in_battle?.sp ||
                skill?.cost.ai > user_in_battle?.ai){
                return <p className='disabled-command'>{c.short_name}</p>
              }
              return (
                <p className={c.ref_name == selectedCommand ?'selected command':'command'} 
                key={Math.ceil(Math.random() * 999999)}
                onClick={()=>{setSelectedCommand(c.ref_name); setErrorCommand(false)}}
                >{c.short_name}</p>
              )
            }
          })}
        </>
        :
        (targetType === 'enemy' && selectedId === user_target_id && user_in_battle) ?

          command_options["vs_target"].map((c)=>{
            let skill = getSkillJson(c.ref_name)

            if(skill){
              if (skill?.attribute_required && !user_in_battle.total_stats[skill?.attribute_required]){
                return <></>
              }
              if(skill?.cost.hp > user_in_battle?.hp ||
                skill?.cost.ep > user_in_battle?.ep ||
                skill?.cost.sp > user_in_battle?.sp ||
                skill?.cost.ai > user_in_battle?.ai){
                return <p className='disabled-command'>{c.short_name}</p>
              }
              return (
                <p className={c.ref_name == selectedCommand ?'selected command':'command'} 
                key={Math.ceil(Math.random() * 999999)}
                onClick={()=>{setSelectedCommand(c.ref_name); setErrorCommand(false)}}
                >{c.short_name}</p>
              )
            }
          })
          :
          <>
          </>
      }    
      </div>

    </div>     

  <div className='command-order-btns-row'>
  { battle && battle.status == 'active'?
  <div className='next-target-btn' onClick={selectNextTarget}>
    <p>СМЕНА</p>
    <p>ЦЕЛИ</p>
  </div>
  :
  <div className='next-target-btn inactive'>
    <p>СМЕНА</p>
    <p>ЦЕЛИ</p>
  </div>
  }
    {   errorCommand?
        <div className={'order-btn error'}>ОШИБКА
        </div>
        :
        user_in_battle && user_in_battle?.hp <= 0 ? 
        <div className={'order-btn inactive'}>
          УПРАВЛЕНИЕ ПОТЕРЯНО
        </div>
        :
        lastCommandStep >= battle.step_num || user_is_waiting ? 
        <div className={'order-btn inactive pulsate'}>///// ОЖИДАНИЕ /////
          </div>
        :
        (selectedCommand !== '' && selectedId !== 0) || 
        ( battle.step_num == 0 && (selectedCommand == 'trade' ||  selectedCommand == 'fight' ||  selectedCommand == 'evade')) ?
        <div className={'order-btn'} onClick={sendCommand}>///// ОТДАТЬ ПРИКАЗ /////
        </div>
        :    
        <div className={'order-btn inactive'}>
          УКАЖИТЕ ЦЕЛЬ И КОМАНДУ
        </div>
    }
  </div>

    </div>

    <div className='info-panel-center'>
      <div className='counter-center'>
        <Counter/>
        {/*<small>ФАЗА #{battle.step_num}</small>
        <small>{'\u00A0'}</small>*/}
      </div>
    </div>

    <div className='text-stats-center'> 
    <p><span>КОР</span> </p>
    <p><span>ЭНР</span> </p>
    <p><span>СПЦ</span> </p>
    <p><span>ИИ</span> </p>
    </div>

    {/*(battle.step_end_date?.getTime() - (new Date()).getTime())/1000*/}

    {user_in_battle ?
    <>
      <div className='info-panel-left appear-with-shift-left'>
        <div className='faction-logo'>

        </div>
        <div className='ship-name-left'>
          <p style={{color:'cyan'}}>{idToLetter(user_in_battle?.id)}-{user_in_battle?.id}</p>
          {/*<small>{getItemHull(user_in_battle)?.name}</small>*/}
            {/*
            <p >{targetType == 'enemy' ? "противник" : "союзник"}</p>
            <small>{getItemJson(target?.card_id, "ship")?.name}</small>
            <small>{getItemJson(target?.card_id, "ship")?.ship_class}</small>*/}
            {/*<small>{getItemHull(target)?.name}</small>*/}
            <small>{getItemHull(user_in_battle)?.name}</small>
            <small>{getItemHull(user_in_battle)?.stats?.ship_class}</small>

        </div>
      </div>

        <div className='text-stats-left'> 
            <div className='indicators-row red-indicators'>{/*user_in_battle?.hp +'\u00A0'*/}
            {user_in_battle?.hp <= user_in_battle?.total_stats.hp?crossIndicator(user_in_battle?.total_stats.hp-user_in_battle?.hp):""}{indicator(user_in_battle?.hp)}</div>
            <div className={'cyan-indicators indicators-row'} >{/*user_in_battle?.ep+'\u00A0'*/}{user_in_battle?.ep <= user_in_battle?.total_stats.ep?crossIndicator(user_in_battle?.total_stats.ep-user_in_battle?.ep):""}{indicator(user_in_battle?.ep)} </div>
            <div className='indicators-row'>{/*user_in_battle?.sp+'\u00A0'*/}{user_in_battle?.sp <= user_in_battle?.total_stats.sp?crossIndicator(user_in_battle?.total_stats.sp-user_in_battle?.sp):""}{indicator(user_in_battle?.sp)} </div>
            <div className={'white-indicators indicators-row'} >{/*user_in_battle?.ai+'\u00A0'*/}{user_in_battle?.ai <= user_in_battle?.total_stats.ai?crossIndicator(user_in_battle?.total_stats.ai-user_in_battle?.ai):""}{indicator(user_in_battle?.ai)}</div>
        </div>
    </>

    :    
    <>           
    <div className='info-panel-left appear-with-shift-left'>
    <div className='ship-name-left'>
      Данные не найдены
      </div>
    </div>

      <div className='text-stats-left'> 
          <div className='indicators-row '>{'\u00A0'}</div>
          <div className='indicators-row '>{'\u00A0'}</div>
          <div className='indicators-row '>{'\u00A0'}</div>
          <div className='indicators-row '>{'\u00A0'}</div>

      </div>
    </>
    }

      <div className='faction-logo'>

      </div>
      {target ?
      <>
        <div className='info-panel-right appear-with-shift-right'>
          <div className='ship-name-right'>
          <p style={targetType == 'enemy' ? {color:'red'}:{color:'cyan'}}>
            {idToLetter(target?.id)}-{target?.id}
          </p>
        
            {/*
            <p >{targetType == 'enemy' ? "противник" : "союзник"}</p>
            <small>{getItemJson(target?.card_id, "ship")?.name}</small>
            <small>{getItemJson(target?.card_id, "ship")?.ship_class}</small>*/}
            {/*<small>{getItemHull(target)?.name}</small>*/}
            <small>{getItemHull(target)?.name}</small>
            <small>{getItemHull(target)?.stats?.ship_class}</small>
          </div>
        </div>

        <div className='text-stats-right'> 
            <div  className='indicators-row red-indicators'>
            {indicator(target.hp)}{target.hp <= target.total_stats.hp?crossIndicator(target.total_stats.hp-target.hp):""} 
            {'\u00A0'}</div>
            <div className={'cyan-indicators indicators-row'} >{indicator(target.ep)}{target.ep <= target.total_stats.ep?crossIndicator(target.total_stats.ep-target.ep):""} 
            {'\u00A0'}</div>
            <div  className='indicators-row'>{indicator(target.sp)}{target.sp <= target.total_stats.sp?crossIndicator(target.total_stats.sp-target.sp):""}
            {'\u00A0'}</div>
            <div className={'white-indicators indicators-row'} >{indicator(target.ai)}{target.ai <= target.total_stats.ai?crossIndicator(target.total_stats.ai-target.ai):""}
            {'\u00A0'}</div>
        </div>
      </>
      :
      <>
          <div className='info-panel-right appear-with-shift-right'>
            <div className='ship-name-right'>
              <p>укажите цель</p>
            </div>
        </div>

        <div className='text-stats-right'> 
            <div className='indicators-row '>{'\u00A0'}</div>
            <div className='indicators-row '>{'\u00A0'}</div>
            <div className='indicators-row '>{'\u00A0'}</div>
            <div className='indicators-row '>{'\u00A0'}</div>

        </div>

      </>}

    </div>
    </>
    //      <axesHelper />

  )
}
