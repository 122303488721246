import React, { useRef, useState, useEffect, useMemo } from 'react';
import Typewriter from "../components/Voyage/Typewriter";
import InventoryComponent from "../components/Voyage/InventoryComponent";
import ShipCardComponent from "../components/Voyage/ShipCardComponent";
import HyperdiveMap from "../components/Voyage/HyperdiveMapComponent";
import RadarWaveComponent from "../components/Voyage/RadarWaveComponent";
import PlanetComponent from "../components/Voyage/PlanetComponent";
import TeamComponent from "../components/Voyage/TeamComponent";
import NavigationComponent from "../components/Voyage/NavigationComponent";
import StationsGraph from "./StationsGraph";
import DescriptionAppear from "../components/Voyage/DescriptionAppear";
import ky from 'ky';
import locales from '../utils/locales/locales'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../store/store";
import './App.css'
// import styles from "./About.module.css"
import { DoubleSide } from 'three'
import { useNavigate } from "react-router-dom";
import RandomDotsBackground from "../components/Voyage/RandomDotsBackground"
import GridBackground from "../components/Voyage/GridBackground"
import RandomMarkerLabelBackground from "../components/Voyage/RandomMarkerLabelBackground"

import {setUser} from '../slices/userSlice';
import {setUserLocations} from '../slices/userLocationsSlice';
import {setTeam} from '../slices/teamSlice';
import {setScreenTab} from '../slices/screenTabSlice';
import {setShowShipCardId} from '../slices/showShipCardIdSlice';
import {setVoyageHistory} from '../slices/voyageHistorySlice';
import {setSound} from '../slices/soundSlice';

import { Console } from 'console';


import { ReactComponent as ArrowNortheast } from '../utils/icons/arrow-northeast.svg';  // Import as a React component
import { ReactComponent as ArrowRight } from '../utils/icons/arrow-right.svg';  // Import as a React component
import { ReactComponent as ArrowLeft } from '../utils/icons/arrow-left.svg';  // Import as a React component
import { ReactComponent as ArrowSoutheast } from '../utils/icons/arrow-southeast.svg';  // Import as a React component
import { ReactComponent as ArrowDown } from '../utils/icons/arrow-down.svg';  // Import as a React component
import { setTeamPath } from '../slices/teamPathSlice';
import { transform } from 'typescript';



const FIGHT_SEARCH_TIME_LIMIT = 30
const REPAIR_PRICE_PER_ATTRIBUTE = 10
const PRICE_PER_DELIVERED_CARGO = 200
const PRICE_PER_CARGO = 100
const TAX_COEF = 0.1

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
//import {setEvent} from '../slices/eventSlice';

/*

import {Canvas, useFrame, useThree} from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import * as THREE from "three";
import { Line } from "@react-three/drei";

/*#FEDBB7
#CBAF92
#A28C75
#82705E
#685A4B
#53483C
#423A30
#352E26
#2A251E
#221E18
Black
 */

let stats_list = [
  'dive_level','karma',  'supply',  'morale',  'order',  'aggression',  'diplomacy',  'faith',  'freight',  'freight_limit',  'valuables',  'frags',  'steps_after_dive'
]

let stats_translate :any = {
  'dive_level' : 'слой пространства',
  'karma' : 'карма',
  'supply': 'снабжение',
  'morale': 'мораль',
  'order': 'дисциплина',
  'aggression': 'агрессия',
  'diplomacy': 'дипломатия',
  'faith': 'резонанс',
  'freight': 'груз',
  'freight_limit': 'ограничение груза',
  'valuables': 'ценности',
  'frags': 'фраги',
  'steps_after_dive' : 'пройденный путь'
  } 

  function indicator(square_num:number){
    if (square_num && square_num > 0)
    {
        //return "■ ".repeat(square_num)
        //return <div className='square-indicator'></div>
        return(<>
        {[...Array(square_num)].map((e, i) => <div className='square-indicator'></div>)}
        </>)
    }
    return ""
  }

  function VibrateOnClick(){
    // Check if vibration is supported by the browser
    if (navigator.vibrate) {
      navigator.vibrate(200);  // Vibrate for 200 milliseconds
    } else {
      console.log("Vibration API is not supported in this browser.");
    }
  };

  function karmaToProbability(karma: number){
    let k= 0.02
            
    let n = 0
    if (karma < 0){n = -karma }
        
    if(karma < -25){ n = 25}
    return Math.round(k*n*100) + '%' 
  }

  function diveLevelToDanger(lvl: number){
    if (lvl == 5){
      return 'БЕЗОПАС'
    }
    if (lvl == 4){
      return 'НИЗК'
    }
    if (lvl == 3){
      return 'СРЕД'
    }
    if (lvl == 2){
      return 'ВЫСОК'
    }
    return '???'
  }



  function crossIndicator(square_num:number){
    if (square_num && square_num > 0)
    {
        //return "x ".repeat(square_num)
        //return <div className='square-indicator-empty'></div>
        return(<>
          {[...Array(square_num)].map((e, i) => <div className='square-indicator-empty'></div>)}
          </>)
    }
    return ""
  }

function GetCost(cost_json : any) {
  try{
    if(!cost_json){
      return ''
    }
    let cost_string = ''
    stats_list.forEach((stat_name : string, index) => {
      if (cost_json[stat_name]){
        if (cost_json[stat_name]  > 0){
          cost_string += -1 *  cost_json[stat_name] + ' ' + stats_translate[stat_name] + ', '
        }else{
          cost_string += '+' +  -1 * cost_json[stat_name] + ' ' + stats_translate[stat_name] + ', '
        }
      }
    })
    if (cost_string.length > 2){
      return cost_string.slice(0, -2)
    }
    return ''

  } catch (error) {
    console.error('cost parse error', error);
    return ''
  }
}


function quote_stylizer_helper1(freight : number, cargo : number, members: number, is_export: boolean){
  try{
    if (freight + cargo > 0){
      let PRICE_PER_FREIGHT = 100
      let answer = 'Грузов дост. из гип/дв:\n'
      answer += `${PRICE_PER_FREIGHT}.00 x ${freight} = ${freight * PRICE_PER_FREIGHT}.00\n`
      if (is_export){
        answer += `Товаров экспортировано:\n`
        answer += `${PRICE_PER_DELIVERED_CARGO}.00 x ${cargo} = ${cargo * PRICE_PER_DELIVERED_CARGO}.00\n`
      }
      else{
        answer += `Товаров возвращено:\n`
        answer += `${PRICE_PER_CARGO}.00 x ${cargo} = ${cargo * PRICE_PER_CARGO}.00\n`
      }
      answer += `-------------------\n`
      return answer
    }else{
      return "Грузовые отсеки пусты. Чтобы заработать валюту - добывайте ценные грузы в экспедициях или экспортируйте грузы."
    }
  }catch(error) {
      console.error('quote stylizer parse error', error);
      return ''
  }
}
function quote_stylizer_helper2(freight : number, cargo : number, members: number, is_export: boolean){
  try{
    if (freight + cargo > 0){
      let PRICE_PER_FREIGHT = 100
      if (is_export){
        return `ИТОГ = + ${Math.round((freight * PRICE_PER_FREIGHT + cargo * PRICE_PER_DELIVERED_CARGO) * (1 - TAX_COEF)/ members)}.00 ВС\n`
      }
      return `ИТОГ = + ${Math.round((freight * PRICE_PER_FREIGHT + cargo * PRICE_PER_CARGO)* (1 - TAX_COEF)/ members)}.00 ВС\n`
    }else{
      return ""
    }
  }catch(error) {
      console.error('quote stylizer parse error', error);
      return ''
  }
}
function quote_stylizer_helper3(freight : number, cargo : number, members: number, is_export: boolean){
  try{
    if (freight + cargo > 0){
      let PRICE_PER_FREIGHT = 100
      let answer = '-------------------\n'
      answer += `Экип. в кмнд. = ${members}\n`
      if (is_export){
        answer += `Сумм. кмнд. = ${freight * PRICE_PER_FREIGHT + cargo * PRICE_PER_DELIVERED_CARGO}.00 ВС\n`
        answer += `НДС ${TAX_COEF * 100}% = ${(freight * PRICE_PER_FREIGHT + cargo * PRICE_PER_DELIVERED_CARGO)*(TAX_COEF)}.00 ВС\n`
      }
      else{
        answer += `Сумм. кмнд. = ${freight * PRICE_PER_FREIGHT + cargo * PRICE_PER_CARGO}.00 ВС\n`
        answer += `НДС ${TAX_COEF * 100}% = ${(freight * PRICE_PER_FREIGHT + cargo * PRICE_PER_CARGO) * (TAX_COEF)}.00 ВС\n`
      }
      return answer
    }else{
      return ""
    }
  }catch(error) {
      console.error('quote stylizer parse error', error);
      return ''
  }
}

    

function GetRequirements(req_json : any) {
  try{
    if(!req_json){
      return ''
    }
    let req_string = ''
    stats_list.forEach((stat_name : string, index) => {
      if (req_json[stat_name]){
        req_string += req_json[stat_name] + ' ' + stats_translate[stat_name] + ', '
      }
    })
    if (req_string.length > 2){
      return req_string.slice(0, -2)
    }
    return ''
  } catch (error) {
    console.error('req parse error', error);
    return ''
  }
}



export default function Voyage() {
  const dispatch = useDispatch();

  const [openCard, setOpenCard] = useState(0)
  const [acceptModal, setAcceptModal] = useState(0)
  const [selectedOption, setSelectedOption] = useState(0)
  const [selectedExtraOption, setSelectedExtraOption] = useState('reverse_direction')

  const [freightDelivered, setFreightDelivered] = useState(0)
  const [cargoDelivered, setCargoDelivered] = useState(0)
  const [selectedOptionType, setSelectedOptionType] = useState('consequence')
  const [cargoModal, setCargoModal] = useState(0)
  const [counter, setCounter] = useState(FIGHT_SEARCH_TIME_LIMIT);
  const [lastCounterResponse, setLastCounterResponse] = useState(-1);
  const [taxModal, setTaxModal] = useState(0)
  const [cargoIsReturned, setCargoIsReturned] = useState(1)
  const [cargoPurchaseQuantity, setCargoPurchaseQuantity] = useState(0)
  const navigate = useNavigate()
  const [pathScreenOn, setPathScreenOn] = useState(0)
  const [gridBackgroundOn, setGridBackgroundOn] = useState(0)
  const [markerOn, setMarkerOn] = useState(1)
  const [specialOptionsMenuOn, setSpecialOptionsMenuOn] = useState(0)
  const [manualModal, setManualModal] = useState(0)
  const [starShiftAnimation, setStarShiftAnimation] = useState(false)
  const [hyperDiveAnimation, setHyperDiveAnimation] = useState(false)

  const [optionsTab, setOptionsTab] = useState(0)
  const user = useSelector((state:RootState)=> state.user);
  const voyageHistory = useSelector((state:RootState)=> state.voyageHistory);
  const userLocations = useSelector((state:RootState)=> state.userLocations);
  const team = useSelector((state:RootState)=> state.team);
  const screenTab = useSelector((state:RootState)=> state.screenTab);
  const showShipCardId = useSelector((state:RootState)=> state.showShipCardId);
  //const actvie_event = useSelector((state:RootState)=> state.activeEvent);
  const team_without_user = team?.users?.filter( (e : any) => e.id != user.id )
  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');
  const selectedUserSafeArray = team?.users?.filter( (e : any) => e.id == showShipCardId)
  const selectedUser = showShipCardId && selectedUserSafeArray.length > 0 ? selectedUserSafeArray[0] : user
  const sound = useSelector((state:RootState)=> state.sound);

  useEffect(() => {
    fetchTeam();
    fetchUserLocations()
   }, [dispatch]);


  let repair_count = 0

  repair_count += user?.total_stats?.hp > user.hp ? user?.total_stats?.hp - user.hp : 0
  repair_count += user?.total_stats?.sp > user.sp ? user?.total_stats?.sp - user.sp : 0
  repair_count += user?.total_stats?.order > user.order ? user?.total_stats?.order - user.order : 0
  repair_count += user?.total_stats?.supply > user.supply ? user?.total_stats?.supply - user.supply : 0
  repair_count += user?.total_stats?.morale > user.morale ? user?.total_stats?.morale - user.morale : 0

  async function fetchUser() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/players/get_user?hash_id=${hash_id}`;

      const result = await ky.get(requestUrl, { credentials: 'include' }).json();
      dispatch(setUser(result));
      fetchUserLocations()
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };

  async function fetchUserLocations() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/players/get_user_locations?hash_id=${hash_id}`;
      const result = await ky.get(requestUrl, { credentials: 'include' }).json();
      console.log('fetchUserLocations', result)
      dispatch(setUserLocations(result));
    } catch (error) {
      console.error('Could not fetch user locations:', error);
    }
  };


 // async function performStarShiftAnimation(){
 //   setStarShiftAnimation(false); // Remove the class to reset it
//    requestAnimationFrame(() => setStarShiftAnimation(true)); // Reapply on next frame
 // };

  async function fetchTeamPath() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/teams/get_path?hash_id=${hash_id}`;
      const result = await ky.get(requestUrl, { credentials: 'include' }).json();
      console.log('fetchTeamPath', result)
      dispatch(setTeamPath(result));
    } catch (error) {
      console.error('Could not fetch user locations:', error);
    }
  };

  async function changeCargoPQ(delta : number){
    try{
      if(cargoPurchaseQuantity + delta < 0){
        return setCargoPurchaseQuantity(0)

      }
      if(cargoPurchaseQuantity + delta > team?.freight_limit - team?.cargo - team?.freight){
        return false
      }

      return setCargoPurchaseQuantity(cargoPurchaseQuantity + delta)
    }catch (error) {
      console.error('Could not cargoPurchaseQuantity:', error);
    }
  }

  async function fetchTeam() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/teams/get_full_team?hash_id=${hash_id}`;
      let old_steps_after_dive = -1
      let old_dive_level = 7
      if(team?.id){
        old_steps_after_dive = team?.steps_after_dive
        old_dive_level = team?.dive_level
      }
      const new_team :any = await ky.get(requestUrl, { credentials: 'include' }).json();
      console.log('fetch team')
      if(new_team){
        dispatch(setTeam(new_team));

        if(new_team?.dive_level && old_dive_level != 7  && old_dive_level != new_team?.dive_level){
          setHyperDiveAnimation(true)
          setStarShiftAnimation(true)
        }else if(new_team?.steps_after_dive && old_steps_after_dive != -1 && old_steps_after_dive != new_team?.steps_after_dive){
          setStarShiftAnimation(true)

          //setStarShiftAnimation(true)
        }

        if(new_team?.voyage){
          console.log('fetch team' , new_team)


          if(['start','middle' , 'delayed_start' , 'delayed_mining'].includes(new_team?.voyage?.card_type)){
            if(new_team?.voyage?.options && new_team?.voyage?.options.length > 0){
              setSelectedOptionType('consequence')
              setSelectedOption(new_team?.voyage?.options[0].id)
  
            }
            else{
              setSelectedOptionType('next_event')
              setSelectedOption(-1)
            }
          }else if(new_team?.voyage?.card_type == 'dive' || new_team?.voyage?.card_type == 'station'){
            setSelectedOptionType('hyperdive')
            if(new_team?.dive_level >= 5){
              setSelectedOption(-1)
            }else{
              setSelectedOption(new_team?.steps_after_dive % 3 + 2)
            }
            if(new_team?.voyage?.card_type == 'station'){
              setOptionsTab(0)
              setPathScreenOn(0)
              await fetchUser()
              console.log('fetch user due to station card_type')
            }
          }else if(new_team?.voyage?.card_type == 'fight'){
            setSelectedOptionType('start_fight_search')
            setSelectedOption(1)
          }else if(new_team?.voyage?.card_type == 'zero_morale' || new_team?.voyage?.card_type == 'zero_order' || new_team?.voyage?.card_type == 'zero_supply' || new_team?.voyage?.card_type == 'team_wipe'){
            setSelectedOptionType('team_wipe')
            setSelectedOption(1)
          }
          else {
            setSelectedOptionType('next_event')
            setSelectedOption(-1)
          } 
          //await fetchTeamPath()
          return new_team?.voyage
        }
      } 
      return false
      
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };

  if(team?.voyage?.fight && team?.voyage?.fight?.status == 'active'){
      navigate('/battle' + `/?hash_id=${hash_id}`)
  }


  React.useEffect(() => {

    let counter_time_left = -1

    if(team?.voyage?.fight && team?.voyage?.fight?.status == 'pending'){
      counter_time_left = Math.floor(((new Date(team?.voyage?.fight?.ending_search_at)).getTime() -(new Date()).getTime() )/1000)
    }
        
    if(team?.voyage?.fight && team?.voyage?.fight?.status == 'pending'){
      if (lastCounterResponse != counter){

        if(counter && counter < -1){
            console.log('otrpravka')
              fightSearchStatus()
              setLastCounterResponse(counter)
              setTimeout(() => setCounter(/*counter - 1*/counter_time_left - 1), 1000);
        }else{
            if(counter % 10 == 0){
              setLastCounterResponse(counter)
              console.log('fightcheck', counter)
              fightSearchStatus()
            }
            setTimeout(() => setCounter(/*counter - 1*/counter_time_left - 1), 1000);
        }
      }

    }
  }, [team, counter]);

  async function selectOption() {
    try {

      let tax_modal_prepared = 0
      let cargo_was_returned = 0

      if(team?.steps_after_dive == 1 && team?.direction < 0){
        tax_modal_prepared = 1
        cargo_was_returned = 1
      }

      if(team?.steps_after_dive + 1 == team?.steps_limit && team?.direction > 0){
        tax_modal_prepared = 1
        cargo_was_returned = 0
      }

      if(selectedOptionType == "team_wipe"){
        tax_modal_prepared = 0
      }

      if(selectedOptionType == 'buy_cargo_modal'){
        setCargoModal(1)
        return true
      }
      // Extract the hash_id from the URL query parameters
      if(selectedOption ==  null || (selectedOption == 0 && selectedOptionType != 'hyperdive' && selectedOptionType != 'start_fight_search'))
      {
        return false
      }
      setFreightDelivered(team?.freight)
      setCargoDelivered(team?.cargo)
      // Add hash_id to the fetch request URL
      if(selectedOptionType == 'hyperdive' && selectedOption == 1 && team?.dive_level == 4){
        setCargoIsReturned(1)
        setTaxModal(1)
        setOptionsTab(0)
        setPathScreenOn(0)
      }

      if(selectedOptionType == 'hyperdive' && selectedOption == -1 && team?.dive_level == -4){

        if(userLocations?.location?.id == userLocations?.destination.id){
          setCargoIsReturned(1)
          setTaxModal(1)
          setOptionsTab(0)
          setPathScreenOn(0)

        }else{
          setCargoIsReturned(0)
          setTaxModal(1)
          setOptionsTab(0)
          setPathScreenOn(0)
        }

      }

      const requestUrl = `${API_BASE_URL}/gameplay/teams/select_option/?hash_id=${hash_id}`;
      console.log('selectedOption', {command: selectedOption, selectedOptionType: selectedOptionType})
      const result = await ky.put(requestUrl, { json: {option_id: selectedOption, option_type:selectedOptionType},  credentials: 'include' }).json();
      console.log('select option result', result)
      if(result){
        //performStarShiftAnimation()
        let op = team?.voyage?.options?.filter((e:any) => e.id == selectedOption)
        let old_event = team?.voyage?.event
        setStarShiftAnimation(false)
        setHyperDiveAnimation(false)
        let v = await fetchTeam()
        if(v){
          if(v?.card_type == 'station'){
            if(tax_modal_prepared){
              setTaxModal(1)
              setCargoIsReturned(cargo_was_returned)
              setOptionsTab(0)
              setPathScreenOn(0)
            }

          }
          if (v?.card_type != 'middle'){
            dispatch(setVoyageHistory({events: []}))
          }else{
            if (op && op.length > 0){
              dispatch(setVoyageHistory({events: [{type: 'answer',id: op[0]?.id, description: op[0]?.name },{type: 'event', public_name: old_event?.public_name,id: old_event?.id, description: old_event?.description} ,  ...voyageHistory.events]}))
            }else{
              dispatch(setVoyageHistory({events: [{type: 'event',id: team?.voyage?.event?.id, public_name: old_event?.public_name, description: team?.voyage?.event?.description}, ...voyageHistory.events]}))
            }
          }
          
        }
      }
      else{
        console.log('error selectedOption', result)
      }
    } catch (error) {
      console.error('Could not send command:', error);
    }
  };

  async function selectSpecialOption() {
    try {
      if(!["mining","relax","mobilize","feast","reverse_direction"].includes(selectedExtraOption)){
        return false
      }
      const requestUrl = `${API_BASE_URL}/gameplay/teams/select_option/?hash_id=${hash_id}`;
      const result = await ky.put(requestUrl, { json: {option_id: 1, option_type:selectedExtraOption},  credentials: 'include' }).json();
      console.log('select option result', result)
      if(result){
        fetchUser()
        fetchTeam()
      }
      else{
        console.log('error selectedOption', result)
      }
    } catch (error) {
      console.error('Could not send command:', error);
    }
  };

  async function purchaseCargo() {
    try {
      let cargo_q = cargoPurchaseQuantity
      setCargoPurchaseQuantity(0)

      if(!team?.voyage || team?.voyage?.card_type != "station"){
        console.log('can purchase only on a station')
        return false
      }

      
      if(team?.freight_limit - cargo_q - team?.cargo - team?.freight < 0){
        console.log('not enough space')
        return false
      }   

      if(cargo_q < 0){
        console.log('cargo_q must be > 0')
        return false
      }

      if(user.hyperpoints - cargo_q* PRICE_PER_CARGO < 0){
        console.log('not enought hpts')
        return false
      }
      
      const requestUrl = `${API_BASE_URL}/gameplay/ships/purchase_cargo/?hash_id=${hash_id}`;
      console.log('purchase Cargo', {cargo_quantity: cargo_q})
      const result = await ky.put(requestUrl, { json: {cargo_quantity: cargo_q},  credentials: 'include' }).json();
      if(result){
        fetchUser()
        fetchTeam()
      }
      else{
        console.log('error selectedOption', result)
      }
    } catch (error) {
      console.error('Could not send command:', error);
    }
  };

  async function fightSearchStatus() {
    try {
      // Extract the hash_id from the URL query parameters
      //if(selectedOption ==  null || (selectedOption == 0 && selectedOptionType != 'hyperdive'))
      //{
      //  return false
      //}

      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/teams/select_option/?hash_id=${hash_id}`;
      const result : any = await ky.put(requestUrl, { json: {option_id: 1, option_type:'start_fight_search'},  credentials: 'include' }).json();
      console.log('fight check', result, result?.result )

      if(result && result?.result != 'not found'){
        fetchTeam()
      }
      else{
        console.log('error selectedOption', result)
        fetchTeam()
      }
    } catch (error) {
      console.error('Could not send command:', error);
    }
  };
  /*
  const memoizedPlanetComponent = useMemo(() => {
    return <PlanetComponent/>
  }, [dispatch]);
  */
  const memoizedRadarWaveComponent = useMemo(() => {
    return <RadarWaveComponent karma={1}/>
  }, [dispatch]);
  

  const memoizedDotsBackground = useMemo(() => {
    return <div className='background-canvas background-canvas-shift'>
            <RandomDotsBackground/>
            </div>
  }, [dispatch]);

  const memoizedDotsBackground2 = useMemo(() => {
    return     <div className='background-canvas background-canvas-shift-far'>
      <RandomDotsBackground/>
    </div>
  }, [dispatch]);

  const memoizedGridBackground = useMemo(() => {
    return     <div className='background-canvas background-canvas-shift-close '>
      <GridBackground/>
    </div>
  }, [dispatch, team?.steps_after_dive]);
  

  const memoizedMarkerLabelBackground = useMemo(() => {
    return <div className='anomaly-canvas'>
            <RandomMarkerLabelBackground text='магнитная буря'/>
            </div>
  }, [dispatch]);

  const deltaStat=(stat_name : any) => {
    if(selectedOptionType != 'consequence'){return <></>}

    let opt = team?.voyage?.options?.find((el:any) => el.id == selectedOption)

    if(!opt || !opt.stats || !opt.stats?.cost){return <></>}

    let stat_in_opt = opt.stats?.cost[stat_name]

    return(<>
                
      { stat_in_opt ? 
          <span className={stat_in_opt > 0 ? 'negative-stat' : 'positive-stat'}>{stat_in_opt > 0 ? '':'+'}{stat_in_opt * -1}</span>
        :<></>}     
      
      </>)
  };


  return (
  <>{
    screenTab == 'voyage' && team && team?.voyage ?
    <>

    <div  className='voyage'>

      <div key={'StarShiftAnimation'}
       className={''+ (hyperDiveAnimation ? 'full-absolute-div hyperdive-animation ' :'full-absolute-div ') 
        + ( starShiftAnimation ? " star-shift-animation " : " ")}
      >

        {memoizedDotsBackground}
        {memoizedDotsBackground2}
        {gridBackgroundOn ? memoizedGridBackground :<></>}
        
      </div>
      <div className='voyage-section'>

      { team?.voyage?.card_type == 'station' && false?     
          <div className='info-panel-top not-fixed'>           
          <div className={optionsTab || taxModal || cargoModal ? 'tabs-row opacity-transform-full' : 'tabs-row'}>
              <p className='selected tab'>ПУТЬ</p>
              <p className='tab' onClick={() => dispatch(setScreenTab('navigation'))}>КАРТА</p>
          </div> 
          </div>
      :<></>}

      <div className={'screen' + (!showShipCardId ? '' : ' shrinked')}>
      {/*memoizedMarkerLabelBackground*/}

      {
              screenTab == 'voyage' && team?
        <>
          <div className={(specialOptionsMenuOn || optionsTab) && !showShipCardId?'info-panel-left not-fixed appear-with-shift-left':'info-panel-left not-fixed display-none'}>

                <div className='voyage-stats-list'>    

                  <>
                    {/*
                    <div className='indicators-row cyan-indicators'>
                      {indicator(team?.supply)}{team?.supply <= team?.supply_limit?crossIndicator(team?.supply_limit-team?.supply):""}⠀{deltaStat('supply')}⠀снабжение
                    </div>
                    <div className='indicators-row cyan-indicators'>
                      {indicator(team?.order)}{team?.order <= team?.order_limit?crossIndicator(team?.order_limit-team?.order):""}⠀{deltaStat('order')}⠀дисциплина
                    </div>
                    <div className='indicators-row cyan-indicators'>
                      {indicator(team?.morale)}{team?.morale <= team?.morale_limit?crossIndicator(team?.morale_limit-team?.morale):""}⠀{deltaStat('morale')}⠀мораль
                    </div>
                    */}
                    <div className='stat'>
                        {team?.supply}<span className='inactive'>/{team?.supply_limit}</span> {deltaStat('supply')} снабжение 
                    </div>
                    <div className='stat'>
                        {team?.order}<span className='inactive'>/{team?.order_limit}</span> {deltaStat('order')} дисциплина  
                    </div>
                    <div className='stat'>
                        {team?.morale}<span className='inactive'>/{team?.morale_limit}</span> {deltaStat('morale')} мораль 
                    </div>
                    
                    <div className='stat inactive'>
                    <>{deltaStat('freight')} {team?.freight}<span className='inactive'>{team?.cargo > 0 ? '+' + team?.cargo:''}/{team?.freight_limit}</span> груз </>
                    </div>

                  </>
              </div>
          </div>
          <div className={showShipCardId ?'info-panel-left not-fixed appear-with-shift-left':'info-panel-left not-fixed display-none'}>
              
              <div className='voyage-stats-list'>

                <div className='indicators-row red-indicators'>КОР {/*user?.hp +'\u00A0'*/}{indicator(selectedUser?.hp)}{selectedUser?.hp <= selectedUser?.total_stats?.hp?crossIndicator(selectedUser?.total_stats?.hp-selectedUser?.hp):""}</div>
                <div className={'cyan-indicators indicators-row'}>ЭНР {/*user?.ep+'\u00A0'*/}{indicator(selectedUser?.ep)}{selectedUser?.ep <= selectedUser?.total_stats?.ep?crossIndicator(selectedUser?.total_stats?.ep-selectedUser?.ep):""} </div>
                <div className='indicators-row'>{/*selectedUser?.sp+'\u00A0'*/}СПЦ {indicator(selectedUser?.sp)}{selectedUser?.sp <= selectedUser?.total_stats?.sp?crossIndicator(selectedUser?.total_stats?.sp-selectedUser?.sp):""} </div>
                <div className={'white-indicators indicators-row'} >ИИ+ {/*selectedUser?.ai+'\u00A0'*/}{indicator(selectedUser?.ai)}{selectedUser?.ai <= selectedUser?.total_stats?.ai?crossIndicator(selectedUser?.total_stats?.ai-selectedUser?.ai):""}</div>
       
              </div> 
          
          </div>

          <div className={showShipCardId  ? 'info-panel-left-bottom appear-with-shift-left':'info-panel-left-bottom display-none'}>
          тип корабля
          </div>

          <div className={showShipCardId  ? 'info-panel-right-bottom appear-with-shift-right':'info-panel-right-bottom display-none'}>
          досье капитана
          </div>
          <div className={(specialOptionsMenuOn || optionsTab) && !showShipCardId?'info-panel-right not-fixed appear-with-shift-right':'info-panel-right not-fixed display-none'}>
          <div className='voyage-stats-list right '>

                    <div className='stat inactive'>
                    резонанс {deltaStat('faith')} {team?.faith}/10
                    </div>
                    <div className='stat inactive'>
                    дипломатия {deltaStat('diplomacy')} {team?.diplomacy}/10
                    </div>
                    <div className='stat inactive'>
                    агрессия {deltaStat('aggression')} {team?.aggression}/10
                    </div>
                    <div className='stat '>
                    путь⠀{team?.steps_after_dive}/{team?.steps_limit}
                    </div>
            </div>


          </div>

          <div className={showShipCardId ?'info-panel-right not-fixed appear-with-shift-right':'info-panel-right not-fixed display-none'}>
          
              <div className='voyage-stats-list right'>

                  <div className='stat inactive'><span className=''>экипажи {selectedUser?.equips.filter((e : any) => e.item_type=="crew")?.length}/{selectedUser?.total_stats?.crew_capacity} </span></div>
                  <div className='stat inactive'><span className=''>модули {selectedUser?.equips.filter((e : any) => e.item_type=="module")?.length}/{selectedUser?.total_stats?.modules_capacity} </span></div>
                  <div className='stat inactive'>судьба {selectedUser?.equips.filter((e : any) => e.item_type=="navigator")?.length}/1</div>

                  { showShipCardId && showShipCardId != user.id?
                  <></>
                  :
                    team?.dive_level != 5?
                  <div className='stat inactive'>вне станции</div>:
                  <>
                      <div className='cyan-color'>{user.hyperpoints} ВС</div>

                  </>

                  }

              </div>

          </div>
        </>
        :<></>
        }
                      {team?.voyage?.card_type == "dive" ? 
              <>
                <div className='hyperdive-portal-1'/>
                  <div className='hyperdive-portal-2'/>
              <div className='hyperdive-portal-3'/>
              </>
              :<></>}
              {team?.voyage?
                <>
                  <div className='player-marker marker-1'>
                    <img className='marker' src={'../markers/triangle1.svg'} alt="React Logo" />

                        {
                        <div className= {!(specialOptionsMenuOn || optionsTab || showShipCardId) && pathScreenOn && markerOn? 'label' : 'label opacity-transform-full'}>

                            <small>
                              <span className='main-color'>КОР:</span> {user?.hp} / {user?.total_stats?.hp}
                              <br/><span className='main-color'>ЭНР:</span> {user?.ep} / {user?.total_stats?.ep}
                              <br/><span className='main-color'>СПЦ:</span> {user?.sp} / {user?.total_stats?.sp}
                            </small>
                            {pathScreenOn?
                              <div className='extra-label  hide-after-animation'>
                                <small className='inactive'>
                                  для открытия<br/><span className='main-color'>инвентаря</span><br/>нажмите на метку
                                </small>
                              </div>
                            :<></>}
                        </div>}
                        
                        <div className='tracer' />
                        <div className='scan-wave' />

                    <div className={'overfloat-inventory ' + (showShipCardId && showShipCardId == user?.id ? ' reveal':'')} onClick={() => dispatch(setShowShipCardId(showShipCardId == user?.id ? 0 : user?.id))} >
                      <div className='inventory-circle'/>
                      <small className='hull-name'>
                          {user?.equips.filter((e : any) => e.item_type=="hull").map((e: any, i:number) => <small className='list-item'>{e.name}</small>)}
                        </small>

                    </div>
                  </div>
                  {
                    team_without_user.length > 0 ?
                    <>
                    <div className='player-marker marker-2' >
                      <img className='marker' src={'../markers/triangle1.svg'} alt="React Logo" />
                      <div className= {!(specialOptionsMenuOn || optionsTab || showShipCardId) && pathScreenOn && markerOn? 'label' : 'label opacity-transform-full'}>

                            <small >
                              <span className='main-color'>КОР:</span> {team_without_user[0]?.hp} / {team_without_user[0]?.total_stats?.hp}
                              <br/><span className='main-color'>ЭНР:</span> {team_without_user[0]?.ep} / {team_without_user[0]?.total_stats?.ep}
                              <br/><span className='main-color'>СПЦ:</span> {team_without_user[0]?.sp} / {team_without_user[0]?.total_stats?.sp}
                            </small>

                        </div>
                        
                        
                        <div className='tracer' />
                        <div className='scan-wave' />

                      <div className={'overfloat-inventory ' + (showShipCardId && showShipCardId == team_without_user[0]?.id ? ' reveal':'')} onClick={() => dispatch(setShowShipCardId(showShipCardId == team_without_user[0]?.id ? 0 : team_without_user[0]?.id))} >
                      <div className='inventory-circle'/>
                      <small className='hull-name'>
                          {team_without_user[0]?.equips.filter((e : any) => e.item_type=="hull").map((e: any, i:number) => <small className='list-item'>{e.name}</small>)}
                        </small>

                    </div>
                    </div>
                    </> 
                    :
                    <></>
                  }
                  {
                    team_without_user.length > 1  ?
                    <>
                    <div className='player-marker marker-3'>
                      <img className='marker' src={'../markers/triangle1.svg'} alt="React Logo" />
                      <div className= {!(specialOptionsMenuOn || optionsTab || showShipCardId) && pathScreenOn && markerOn? 'label' : 'label opacity-transform-full'}>


                            <small >
                              <span className='main-color'>КОР:</span> {team_without_user[1]?.hp} / {team_without_user[1]?.total_stats?.hp}
                              <br/><span className='main-color'>ЭНР:</span> {team_without_user[1]?.ep} / {team_without_user[1]?.total_stats?.ep}
                              <br/><span className='main-color'>СПЦ:</span> {team_without_user[1]?.sp} / {team_without_user[1]?.total_stats?.sp}
                            </small>

                        </div>


                        <div className='tracer' />
                        <div className='scan-wave' />

                      <div className={'overfloat-inventory ' + (showShipCardId && showShipCardId == team_without_user[1]?.id ? ' reveal':'')} onClick={() => dispatch(setShowShipCardId(showShipCardId == team_without_user[1]?.id ? 0 : team_without_user[1]?.id))} >
                        <div className='inventory-circle'/>
                        <small className='hull-name'>
                            {team_without_user[1]?.equips.filter((e : any) => e.item_type=="hull").map((e: any, i:number) => <small className='list-item'>{e.name}</small>)}
                          </small>

                      </div>
                    </div>
                    </> 
                    :
                    <></>
                  }
                  {
                    team?.voyage?.card_type == "station" ?
                    <div className='event-card '>
                      <div className='event-card-description '>
                        <div className='station-description'>
                          <div className=' station-marker-2'/>
                          <div className={showShipCardId? 'zero-opacity description ':'description'}>

                          </div>
                        </div>
                      </div>
                    </div>:<></>
                  }
                </>
                :<></>
              }
              {team?.voyage?.card_type == "fight" && team?.voyage?.fight?.status == "pending"? 
              <div className='event-card warning-message'>

                <h2 className='red-highlight'>{counter >= 0 ? counter + ' СЕК' : 'СКАНИРОВАНИЕ'}</h2>

                  {
                    counter >= -1 && counter <= FIGHT_SEARCH_TIME_LIMIT ?
                    <div className='loading-bar'><div className='bar' style={{transform: 'scaleX(' + (1 - counter/FIGHT_SEARCH_TIME_LIMIT) +')'}}></div></div>
                    : 
                    <div className='loading-bar'><div className='bar' style={{transform: 'scaleX(' + 0 +')'}}></div></div>
                  }                

              </div> :<></>}
                
              {team?.voyage?.card_type == "mining" && team?.voyage?.fight?.status == "pending"? 
              <div className='event-card warning-message cyan-warning'>

                <h2 className='cyan-highlight'>{counter >= 0 ? counter + ' СЕК' : 'СКАНИРОВАНИЕ'}</h2>

                  {
                    counter >= -1 && counter <= (Math.floor(((new Date(team?.voyage?.fight?.ending_search_at)).getTime() -(new Date(team?.voyage?.fight?.created_at)).getTime() )/1000)) ?
                      <div className='loading-bar'><div className='bar' style={{transform: 'scaleX(' + (1 - counter/(Math.floor(((new Date(team?.voyage?.fight?.ending_search_at)).getTime() -(new Date(team?.voyage?.fight?.created_at)).getTime() )/1000))) +')'}}></div></div>

                    : 
                    <div className='loading-bar'><div className='bar' style={{transform: 'scaleX(' + 0 +')'}}></div></div>
                  }                

              </div> :<></>}

                {team?.voyage?.card_type == "fight" && !team?.voyage?.fight? 
                  <div className='event-card warning-message'>
                  <h2 className='main-border-highlight  '>ВНИМАНИЕ</h2>
                  <div className='main-border-highlight pulsate'>СИГНАТУРЫ НА РАДАРЕ</div>
                  
              </div> :<></>}
              
              <>
                <div className= {pathScreenOn && !specialOptionsMenuOn && !optionsTab && !showShipCardId ? 'info-panel-right appear-with-shift-right not-fixed':'info-panel-right not-fixed display-none'}>
                  <div className='header'><h2 className=''>{team?.voyage?.card_type == "fight"? karmaToProbability(team?.karma) : karmaToProbability(team?.karma)}</h2></div>
                          <small className='label-body'>вероятность контакта</small>
                </div>
                

                <div className={pathScreenOn && !specialOptionsMenuOn && !optionsTab && !showShipCardId  ? 'info-panel-left appear-with-shift-left not-fixed':'info-panel-left not-fixed  display-none'}>
                <div className='header'><h2 className=''>
                  {diveLevelToDanger(team?.dive_level)}
                </h2></div>
                <small className='label-body'>опасность среды</small>
                <br/>
                  <>
                  <div className='altitude-bar left'>
                      <>
                          <div className='scale-marker' style={{
                            height : 
                            Math.abs(team?.dive_level) < 5 ? 
                              ( (Math.abs(team?.dive_level) -1 )/4*100) + '%' : '100%'
                            }}>

                            <div className='marker-label' ><small>угроза</small></div>
                          </div>


                          <div className={Math.abs(team?.dive_level) == 0?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>0</small>
                          </div>
                          <div className='half-scale'/>

                          <div className={Math.abs(team?.dive_level) == 1?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>1</small>
                          </div>
                          <div className='half-scale'/>

                          <div className={Math.abs(team?.dive_level) == 2?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>2</small>
                          </div>
                          <div className='half-scale'/>


                          <div className={team?.dive_level == 3 ?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>3</small>
                          </div>
                          <div className='half-scale'/>

                      </>
                    
                </div>
                  </>

                </div>
                <div className={pathScreenOn && !specialOptionsMenuOn && !optionsTab && !showShipCardId  ? 'info-panel-right-bottom appear-with-shift-right no-border':'info-panel-right-bottom no-border display-none non-clickable-field'}>
                
                  <div className='toggler-boxes-row'>
                    <div className={gridBackgroundOn ?'toggler-box on' : 'toggler-box off'} onClick={()=>{setGridBackgroundOn(1 - gridBackgroundOn); VibrateOnClick()}}>
                      <p className='title'>МЕТК
                      </p>
                      <small className='label on'><div className='lamp' />ВКЛ</small>

                      <div className='vertical-toggler' >
                        <div className='white-space on'></div>

                        <div className='toggler' >

                        </div>
                        <div className='white-space off'></div>

                      </div>
                      <small className='label off'><div className='lamp' />ВЫКЛ</small>


                    </div>
                    <div className={markerOn ?'toggler-box on' : 'toggler-box off'} onClick={()=>setMarkerOn(1 - markerOn)}>
                      <p className='title'>ЭКИП</p>

                      <small className='label on'><div className='lamp' />ВКЛ</small>
                      <div className='vertical-toggler' >
                        
                        <div className='white-space on'></div>
                        <div className='toggler' >

                        </div>
                        <div className='white-space off'></div>

                      </div>
                      <small className='label off'><div className='lamp' />ВЫКЛ</small>

                    </div>
                    <div className={sound ?'toggler-box on' : 'toggler-box off'} onTouchStart={()=>VibrateOnClick()} onClick={()=>dispatch(setSound(1 - sound))}>
                      <p className='title'>ЗВУК</p>
                      <small className='label on'><div className='lamp' />ВКЛ</small>

                      <div className='vertical-toggler'>
                        
                        <div className='white-space on' key="sound-"></div>
                        <div className='toggler' key="sound-toggler">

                        </div>
                        <div className='white-space off' key="sound+"></div>

                      </div>
                      <small className='label off'><div className='lamp' />ВЫКЛ</small>

                    </div>

                  </div>

                </div>

                <div style={{cursor:'pointer'}}
                onClick={()=>setPathScreenOn(1 - pathScreenOn)}
                 className={pathScreenOn && !specialOptionsMenuOn && !optionsTab && !showShipCardId  ? 'info-panel-left-bottom appear-with-shift-left':'info-panel-left-bottom display-none'}>
                          <div className='voyage-stats-list '>
                              <div className={!userLocations?.destination || team?.dive_level > 0 ?'stat inactive':'stat inactive'}>
                              <p className='location-type-name'>ВЫЛЕТ</p>
                              <div className='location-name'><small>{userLocations?.location?.name}</small></div>

                              </div>

                              {
                                userLocations?.destination && userLocations?.destination?.id != userLocations?.location?.id ?
                                <div className={userLocations?.destination && team?.dive_level < 0 ?'stat inactive':'stat inactive'}>
                                  <div className='location-type-name'>ЦЕЛЬ</div>
                                  <div className='location-name'><small>{userLocations?.destination ? userLocations?.destination?.name : "--"}</small></div>
                                </div>
                                :
                                <div className='stat inactive'>
                                                                <div className='location-type-name'>ЦЕЛЬ</div>
                                  <div className='location-name'><small>Не выбрана</small></div>

                                </div>
                              }
                          </div>
                          <small className='cyan-color'>{"<⠀скрыть"}</small>
                </div>
              </>
                
              {team?.voyage?.card_type == "fight"?
              <>{memoizedRadarWaveComponent}</>
              :<></>
              }

              
              { !pathScreenOn && !showShipCardId?
                <div className='info-panel-left-bottom appear-with-shift-left ' style={{cursor:'pointer'}} onClick={()=>setPathScreenOn(1 - pathScreenOn)}>
                  {'>' }<br/>{'>' }<br/>{'>' }
                </div>:<></>}
              
                { !pathScreenOn && !specialOptionsMenuOn && !optionsTab && !showShipCardId ?
                <div className='info-panel-left no-borders not-fixed appear-with-shift-left ' style={{cursor:'pointer'}}>
                  <div className='voyage-stats-list'>
                    {team?.voyage?.card_type == 'station' ?
                    <>
                      <div className='indicators-row'>
                        <h2 className='uppercase'>СТАНЦИЯ {userLocations?.location?.name}</h2>
                        <p className='inactive'>ВАЛЮТА: <br/>{user.hyperpoints} ВС</p>
                        <br/>
                        <p className=' uppercase navigation-btn highlight' onClick={() => dispatch(setScreenTab('navigation'))}><><ArrowRight className='inline-svg-h2'/>⠀КАРТА</></p>

                    </div> 
                  </>
                  :
                  <div className='indicators-row'>
                    <h2 className='uppercase'>ДЕНЬ {team?.days_count}</h2>
                    {team?.direction > 0 ? 
                      <p className='inactive'>ЦЕЛЬ <ArrowRight className='inline-svg' /> {Math.floor(team?.steps_after_dive/team?.steps_limit * 100)}%</p>
                      :
                      <p className='inactive'>ВОЗВРАТ <ArrowLeft className='inline-svg' /> {100 - Math.floor(team?.steps_after_dive/team?.steps_limit * 100)}%</p>
                    }
                    </div>}
                  </div>
                  
                </div>:<></>}

                { !pathScreenOn && !specialOptionsMenuOn && !optionsTab && !showShipCardId && team?.voyage?.card_type == 'station' ?
                <div className='info-panel-right no-borders not-fixed appear-with-shift-left ' style={{cursor:'pointer'}} >
                  <div className='voyage-stats-list' onClick={() => dispatch(setScreenTab('navigation'))}>

                  </div>
                  
                </div>:<></>}

              <div className={pathScreenOn && !specialOptionsMenuOn && !optionsTab && !showShipCardId  ? 'top-center-loc-bar':'top-center-loc-bar opacity-transform-full'}>

                { pathScreenOn && !showShipCardId?
                <>
                  {/* team?.dive_level == 5 || team?.dive_level == -5 ? 
                    
                    <div className='btn destination-btn'  onClick={() => dispatch(setScreenTab('navigation'))} >НАВИГАЦИЯ</div>
                    
                  :<></>*/}
                    <div className='label-bar' > 
                      {/*
                        userLocations?.destination && userLocations?.destination?.id && userLocations?.destination?.id != userLocations?.location?.id ?
                        <small><ArrowRight className='inline-svg' /> {userLocations?.destination?.name}</small>
                        :
                        team?.dive_level == 5 || team?.dive_level == -5 ?
                          <small onClick={() => dispatch(setScreenTab('navigation'))}>выберите маршрут</small> :
                          <small>пункт не выбран</small>
                        
                      */}

                      <div className='position-bar'> 
                        <div className='rail-bar danger-2-ddd'>
                          <div className='position-mark' 
                          style={
                            {width : 
                              (team?.steps_after_dive > 0 ?
                               team?.steps_after_dive < team?.steps_limit ? 
                               Math.floor(team?.steps_after_dive/team?.steps_limit * 100) + '%' : 
                               '100%' : '0%')}
                            }/>
                          
                          <>{/*<div className='danger-mark'/>
                          <div className='danger-mark '/>
                          <div className='danger-mark disabled'/>*/}</>
                          
                        </div>
                      </div>    
                      <small className=''>маршрут до цели</small>
                      <div className={pathScreenOn? 'label' : 'label opacity-transform-full' }>
                        {/*(team?.steps_after_dive >= 0 ?
                               team?.steps_after_dive < team?.steps_limit ? 
                               Math.floor(team?.steps_after_dive/team?.steps_limit * 100) + '%' : 
                               '100%' : '0%')*/}
                        {team?.steps_after_dive}/{team?.steps_limit}
                      </div>


                    </div>
                </>
                :
                <>
                    <div className='label-bar display-none'> 

                        <div><small className='cyan-color'>судьба: </small></div>
                        
                        <div>
                          {
                            team?.users[0]?.equips.filter((e : any) => e.item_type=="navigator").length ?
                            team?.users[0]?.equips.filter((e : any) => e.item_type=="navigator").map((e: any, i:number) => <small className='cyan-highlight'>{e.name.toLowerCase()}</small>)
                            : <small className='inactive'>--</small>
                          }
                        </div>
                        
                      </div>
                </>
                }
              </div>
      {/*
        pathScreenOn? 
        <HyperdiveMap karma={1}/> : <></>
      */}
      {/*
      <div className={pathScreenOn?'screen-bottom opacity-transform  display-none':'screen-bottom  display-none'}>

              <div className='left-info-bar'>
                <div className='altitude-bar left'>

                  {
                      <>
                        {team?.dive_level > 0 || true? 
                        <>
                          <div className='scale-marker' style={{height : Math.abs(team?.dive_level) >= 0 ? Math.abs(team?.dive_level) < 5 ? (Math.abs(team?.dive_level))/5*100 + '%' : '0%' : '100%'}}>
                            <div className='marker-label' ><small>дистанция</small></div>
                          </div>
                          <div className={Math.abs(team?.dive_level) == 5 ?'empty highlighted' : 'empty'}>
                          <small className='label'>5</small>
                          </div><div className='half-scale'/>


                          <div className={Math.abs(team?.dive_level) == 4 ?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>4</small>
                          </div>
                          <div className='half-scale'/>

                          <div className={Math.abs(team?.dive_level) == 3?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>3</small>
                          </div>
                          <div className='half-scale'/>

                          <div className={Math.abs(team?.dive_level) == 2?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>2</small>
                          </div>
                          <div className='half-scale'/>

                          <div className={Math.abs(team?.dive_level) == 1?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>1</small>
                          </div>
                          <div className='half-scale'/>


                          <small className={team?.dive_level == 0 ?'end-label highlighted' : 'end-label'}>0</small>
                        </>
                        :
                        <>
                          <div className='scale-marker' style={{height : Math.abs(team?.dive_level) >= 0 ? Math.abs(team?.dive_level) < 6 ? (100 - (Math.abs(team?.dive_level))/5*100) + '%' : '0%' : '100%'}}>
                            <div className='marker-label' ><small>слой</small></div>
                          </div>

                          <div className={Math.abs(team?.dive_level) == 0 ?'empty highlighted' : 'empty'}>
                          <small className='label'>0</small>
                          </div><div className='half-scale'/>
                          
                          <div className={Math.abs(team?.dive_level) == 1 ?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>-1</small>
                          </div>
                          <div className='half-scale'/>

                          <div className={Math.abs(team?.dive_level) == 2?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>-2</small>
                          </div>
                          <div className='half-scale'/>

                          <div className={Math.abs(team?.dive_level) == 3?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>-3</small>
                          </div>
                          <div className='half-scale'/>

                          <div className={Math.abs(team?.dive_level) == 4?'full-scale highlighted' : 'full-scale'}>
                          <small className='label'>-4</small>
                          </div>
                          <div className='half-scale'/>


                          <small className={Math.abs(team?.dive_level) == 5 ?'end-label highlighted' : 'end-label'}>-5</small>
                        </>
                        }
                      </>
                    }
                </div>
              </div>

              <div className='right-info-bar'> 
                <div className='altitude-bar'>
                    
                    {
                      <>
                      <div className={showShipCardId ? 'scale-marker ': 'scale-marker'} style={{
                          height : team?.dive_level >= 5 ? '0%' : 
                          team?.steps_after_dive > -1 ? (team?.steps_after_dive < 6 ? (5 - team.steps_after_dive + 1)/7*100 + '%' : '0%') : '100%'
                        }}>
                        <div className='marker-label'><small>переход</small></div>
                      </div>
                      
                        <div className={team?.dive_level < 5 && (5 - team.steps_after_dive + 1) >= 7 ?'empty highlighted' : 'empty'}>
                          <small className='label'>7</small>
                        </div>
                        <div className='half-scale'/>

                        <div className={(5 - team.steps_after_dive + 1) == 6 ?'full-scale highlighted' : 'full-scale'}>
                        <small className='label'>6</small>
                        </div>
                        <div className='half-scale'/>
                        
                        <div className={(5 - team.steps_after_dive + 1) == 5 ?'full-scale highlighted' : 'full-scale'}>
                        <small className='label'>5</small>
                        </div>
                        <div className='half-scale'/>
                        
                        <div className={(5 - team.steps_after_dive + 1) == 4 ?'full-scale highlighted' : 'full-scale'}>
                        <small className='label'>4</small>
                        </div>
                        <div className='half-scale'/>

                        <div className={(5 - team.steps_after_dive + 1) == 3 ?'full-scale highlighted' : 'full-scale'}>
                        <small className='label'>3</small>
                        </div>
                        <div className='half-scale'/>

                        <div className={(5 - team.steps_after_dive + 1) == 2 ?'full-scale highlighted' : 'full-scale'}>
                        <small className='label'>2</small>
                        </div>
                        <div className='half-scale'/>

                        <div className={(5 - team.steps_after_dive + 1) == 1 ?'full-scale highlighted' : 'full-scale'}>
                        <small className='label'>1</small>
                        </div>
                        <div className='half-scale'/>

                        <small className={team?.dive_level >= 5 || (5 - team.steps_after_dive + 1) <= 0 ?'end-label highlighted' : 'end-label'}>0</small>
                        </>
                    }
                </div>
              </div>


        </div>
        */}
        
    </div>
        
        { !showShipCardId? 
        <>
        {/*<TeamPathComponent logs={''}/>*/}
          <div className={optionsTab || taxModal || cargoModal ? 'secondary-screen opacity-transform display-none' : 'secondary-screen display-none'}>

          <div className='indicators-boxes'>
            {
              pathScreenOn? 
              <>
              <div className='indicator-box locations-list' >

                <div className={!userLocations?.destination || team?.dive_level > 0 ?'sector selected':'sector'}>
                  <p>ВЫЛЕТ</p>
                <small className='location-name'> {userLocations?.location?.name}</small>

                </div>
                {
                  userLocations?.destination && userLocations?.destination?.id != userLocations?.location?.id ?
                  <div className={userLocations?.destination && team?.dive_level < 0 ?'sector selected':'sector'}>
                    <p>ЦЕЛЬ</p>
                    <small className='location-name'>{userLocations?.destination ? userLocations?.destination?.name : "--"}</small>

                  </div>
                  :
                  <div className='sector not-selected'>
                    <p>ЦЕЛЬ</p>
                    <small className='location-name'>НЕ ВЫБРАНА</small>

                  </div>
                }
                



              </div>
                <small className='inactive'>СЕКТОР Г/Д</small>

              </>
              :
              <>
                <div className='indicator-box '>
                  
                  {memoizedRadarWaveComponent }
                  <div className='probability-label'>
                  {team?.voyage?.card_type == "fight" && team?.voyage?.fight?.status == "pending"? 
            
                      <div className='red-highlight pulsate'>
                      <p>ВВЕДЕН</p> 
                      <p>РЕЖИМ ЧП</p> 
                      </div>
                      :
                      team?.voyage?.card_type == "mining" && team?.voyage?.fight?.status == "pending"?
                        <div className='cyan-highlight pulsate'>
                          <p>ВЕДЕТСЯ ДОБЫЧА</p> 
                          <p>МИНЕРАЛОВ</p> 
                        </div>
                      :
                      <>
                        <div className='header'><h2 className=''>{team?.voyage?.card_type == "fight"? "100%" : karmaToProbability(team?.karma)}</h2></div>
                        <small className='label-body'>вероятность</small>
                        <small className='label-body'>контакта</small>
                      </>
                    }

                  </div>

                </div>
                <small className='inactive'>РАДИОЛОКАЦИЯ</small>
              </>


            }


          </div>

          {/*<div className={pathScreenOn ?'toggler-box on' : 'toggler-box off'} onClick={()=>setPathScreenOn(1 - pathScreenOn)}>
            <p className='title'>ПУТЬ
            </p>
            <small className='label on'><div className='lamp' />ВКЛ</small>
            <div className='vertical-toggler' >
              
               <div className='white-space on'></div>
              <div className='toggler' >

              </div>
              <div className='white-space off'></div>

            </div>
            <small className='label off'><div className='lamp' />ВЫКЛ</small>
          </div>*/}
          <div className={markerOn ?'toggler-box on' : 'toggler-box off'} onClick={()=>setMarkerOn(1 - markerOn)}>
            <p className='title'>МЕТК
            </p>
            <small className='label on'><div className='lamp' />ВКЛ</small>
            <div className='vertical-toggler' >
              
               <div className='white-space on'></div>
              <div className='toggler' >

              </div>
              <div className='white-space off'></div>

            </div>
            <small className='label off'><div className='lamp' />ВЫКЛ</small>
          </div>
          <div className={sound ?'toggler-box on' : 'toggler-box off'}  onClick={()=>dispatch(setSound(1 - sound))}>
            <p className='title'>ЗВУК
            </p>
            <small className='label on'><div className='lamp' />ВКЛ</small>
            <div className='vertical-toggler'>
              
               <div className='white-space on' key="sound+"></div>
              <div className='toggler' key="sound-toggler">

              </div>
              <div className='white-space off' key="sound-"></div>

            </div>
            <small className='label off'><div className='lamp' />ВЫКЛ</small>
          </div>


          </div>
        </>
        :
        <>
        {showShipCardId == user.id?
          <InventoryComponent fetch_team_func={fetchTeam}/>
          : 
          <ShipCardComponent ship={selectedUser} />
        }
        </>
        }

            
      </div>

    </div>



    <div className={showShipCardId ? 'hud no-opacity display-none': 'hud no-opacity'}>
        <div className="commands-panel">
          



          <div className="command-order-btns-row">
          {screenTab == 'voyage'?
            <>
            {/*team?.voyage?.card_type == 'nothing_event' || 
            ((team?.voyage?.card_type == 'start' || team?.voyage?.card_type == 'middle' || team?.voyage?.card_type == 'delayed_start') && (!team?.voyage?.options || team?.voyage?.options.length <= 1))?
            <div className="secondary-order-btn" onClick={() => selectOption()}> 
              ДАЛЕЕ 
            </div>
            :
            <>

              <div className="secondary-order-btn" onClick={() => setOptionsTab(1 - optionsTab) }> 

                {
                    team?.voyage?.card_type == 'station' ?
                    <>НАЧАТЬ ПУТЕШЕСТВИЕ</>
                    :
                    <>ВЫБОР ДЕЙСТВИЯ</>
                  }
              </div>
            </>
            */}
              { team?.voyage?.card_type == 'station' ?
                  <>
                    <div className="next-target-btn" onClick={() => setManualModal(1 - manualModal) }> 
                      <>ОБУЧЕНИЕ</>
                    </div>          

                    
                    <div className="secondary-order-btn" onClick={() => setOptionsTab(1 - optionsTab) }> 
                      <>НАЧАТЬ ПУТЕШЕСТВИЕ</>
                    </div>              
                  </>     
                  :
                  <>
                    <div className="next-target-btn" onClick={() => {setSpecialOptionsMenuOn(1);}}> 
                      <>ОСОБЫЕ</>
                    </div> 
                    <div className="secondary-order-btn" onClick={() => {setOptionsTab(1 - optionsTab)}}> 
                      <>ВЫБОР ДЕЙСТВИЯ</>
                    </div>  
                  </>                  
              }
            </>
            :
            <div className="order-btn inactive">ПЕРЕДИТЕ В УПРАВЛЕНИЕ</div>
          }
          </div>

        </div>
        


        <div className={specialOptionsMenuOn? 'bottom-drawer open-voyage-hat': 'bottom-drawer hidden open-voyage-hat'}>
          
              <div className='journal'>
                <div className='events'>

                <div className='event-options'>
                  <div className='options-list'>
                        <div className={'reverse_direction' == selectedExtraOption ? 'selected option': 'option'}
                            onClick={() => {if(true){setSelectedExtraOption('reverse_direction');}}}
                            >
                              <div className='title'><Typewriter text={'Обратный курс'} delay={10} /></div>
                              <div className='option-body'>
                                  <div className='cost'><small>{team?.direction > 0 ? 'Двигаться к точке вылета' : 'Двигаться к цели'}</small></div>
                              </div>
                        </div>
                        <div className={'mobilize' == selectedExtraOption ? 'selected option': 'option'}
                            onClick={() => {if(true){setSelectedExtraOption('mobilize');}}}
                            >
                              <div className='title'><Typewriter text={'Давление на экипаж'} delay={10} /></div>
                              <div className='option-body'>
                                  <div className='cost'><small>-2 мораль, +1 дисциплина</small></div>
                              </div>
                        </div>
                        <div className={'relax' == selectedExtraOption ? 'selected option': 'option'}
                            onClick={() => {if(true){setSelectedExtraOption('relax');}}}
                            >
                              <div className='title'><Typewriter text={'Снизить нагрузку на экипаж'} delay={10} /></div>
                              <div className='option-body'>
                                  <div className='cost'><small>-2 дисциплина, +1 мораль</small></div>
                              </div>
                        </div>
                        <div className={'feast' == selectedExtraOption ? 'selected option': 'option'}
                            onClick={() => {if(true){setSelectedExtraOption('feast');}}}
                            >
                              <div className='title'><Typewriter text={'Устроить пир'} delay={10} /></div>
                              <div className='option-body'>
                                  <div className='cost'><small>-2 снабжения, +1 мораль</small></div>
                              </div>
                        </div>
                        {
                        <div className={'mining' == selectedExtraOption ? 'selected option': 'option'}
                            onClick={() => {if(true){setSelectedExtraOption('mining');}}}
                            >
                              <div className='title'><Typewriter text={'Поиск месторождения'} delay={10} /></div>
                              <div className='option-body'>
                                <>
                                <div className='cost'><small>Вероятность найти месторождения на 3 слое гиперпространства</small></div>
                                </>
                              </div>
                        </div>
                        }
                    </div>
                  </div>
                  <div className='event description-appear'>
                      <div className='inactive'>
                        <p>Особые действия капитана (Не уменьшают расстояние до перехода)</p>

                      </div>
                  </div>
                  <div className='event-title'> <h2 >ОСОБЫЕ ДЕЙСТВИЯ КАПИТАНА</h2></div>

                </div>
              </div>

              <div className="command-order-btns-row">
                <div className="next-target-btn"  onClick={() => setSpecialOptionsMenuOn(0)}>ОТМЕНА</div>  
                <div className="order-btn"  onClick={() => {selectSpecialOption(); setSpecialOptionsMenuOn(0)}}>ПОДТВЕРДИТЬ</div>
              </div>       
        </div>

        <div className={optionsTab? 'bottom-drawer open-voyage-hat': 'bottom-drawer open-voyage-hat hidden'}>
        {
          <>
          </>
        }
        {team?.voyage ?
          <>

          <div key={'journal'} className={optionsTab || taxModal || cargoModal ? 'journal' : 'journal'}>
          <div className='events'>

          <div className='event-options'>
          {user && !user?.is_captain ? 
              <div className='options-list'>
              Управление доступно только капитану
              </div>
              :
            <div className='options-list' key={'op-list'}>
              { 
                 ['start','middle' , 'delayed_start' , 'delayed_mining', 'team_wipe', 'zero_order',
                   'zero_supply', 'zero_morale', 'nothing_event'].includes(team?.voyage?.card_type)?
                <>
                    {team?.voyage?.options && team?.voyage?.options.length > 0  ?
                    <>
                      {team?.voyage?.options.map((e: any, index : number) => {
                        let req_str = GetRequirements(e?.stats?.requirements)
                        let cost_str = GetCost(e?.stats?.cost)
                        let option_is_possible = team?.voyage?.options_possibility[index]
                        return(
                        <div className={!option_is_possible ? 'option disabled-option' : e.id == selectedOption ? 'selected option': 'option'}
                          onClick={() => {if(option_is_possible){setSelectedOption(e.id);setSelectedOptionType('consequence')}}}
                          key={'opti-'+e?.id}
                          >
                              <div className='title'><Typewriter text={e.name} delay={10} /></div>
                              <div className='option-body'>
                                {cost_str + req_str != '' ?
                                <>
                                  <div className='cost'><small>{cost_str + ' '}</small></div>
                                  <div className='requirements'><small>{req_str !='' ? 'Требования: ' + req_str : ''}</small></div>
                                </>
                                : 
                                <>
                                  <div className='cost'><small>...</small></div>
                                </>}
                              </div>
                        </div>
                        )})
                      }
                    </>
                    :
                      <>
                      { 
                      ['start','middle' , 'delayed_start' , 'nothing_event'].includes(team?.voyage?.card_type) ?
                      <div className={selectedOptionType == 'next_event' ? 'selected option': 'option'}
                        onClick={() => setSelectedOptionType('next_event')}
                        key={'opti-zero-'+team?.steps_after_dive}
                        >
                            <div className='title'><Typewriter key={'tw-zero-'+team?.steps_after_dive} text={"Двигаться дальше"} delay={10} /></div>
                            <div className='option-body'>
                              <div className='cost'><small>...</small></div>
                            </div>
                      </div>
                      :<></>
                      }
                      </>
                   }
                   {
                      team?.voyage?.card_type == 'team_wipe'  || team?.voyage?.card_type == 'zero_order' 
                      || team?.voyage?.card_type == 'zero_supply' || team?.voyage?.card_type == 'zero_morale' ?
                      <div className={selectedOptionType == 'team_wipe' ? 'selected option': 'option'}
                        onClick={() => setSelectedOptionType('team_wipe')}>
                            <div className='title'><Typewriter text={"Перезапуск"} delay={10} /></div>
                            <div className='option-body'>
                              <div className='cost'><small>Корабли и инвентарь обнулятся</small></div>
                            </div>
                      </div>
                    :<></>

                   }
                </>:<></>
              }
              
              {
                team?.voyage?.card_type == 'dive'? 
                <>
 
 
                {
                  <div className={selectedOption == (team?.steps_after_dive%3+2) ? 'selected option': 'option'}
                    onClick={() => {setSelectedOption(team?.steps_after_dive%3+2); setSelectedOptionType('hyperdive')}}
                    key={'opti-hyp-down'}
                    >
                      <div className='title'><ArrowRight className='inline-svg'/> <Typewriter text={"Продолжить движение"} delay={10} /></div>
                        <>
                          <div className='option-body'>
                            <div className='cost'><small>Опасности среды: {diveLevelToDanger(team?.steps_after_dive%3+2)}</small></div>
                          </div>
                        </>

                  </div>
                }
               

                {team?.steps_after_dive%3+2 != team?.dive_level ?
                  <div className={selectedOption == team?.dive_level ? 'selected option': 'option'}
                  onClick={() => {setSelectedOption(team?.dive_level); setSelectedOptionType('hyperdive')}}
                  key={'opti-hyp-same'}
                  >
                      <div className='title'><ArrowSoutheast className='inline-svg'/> <Typewriter text={"Обходной маршрут"} delay={10} /></div>
                      <div className='option-body'>
                        <div className='cost'><small>Сохранить уровень опасности ({diveLevelToDanger(team?.dive_level)})</small></div>
                        <div className='cost red-color'><small>+5 длина маршрута</small></div>

                      </div>
                  </div> 
                  :
                  <>
                      {team?.steps_after_dive%3+2 == 3?
                      <div className={selectedOption == 4 ? 'selected option': 'option'}
                      onClick={() => {setSelectedOption(4); setSelectedOptionType('hyperdive')}}
                      key={'opti-hyp-same'}
                      >
                          <div className='title'><ArrowSoutheast className='inline-svg'/> <Typewriter text={"Безопасный обходной маршрут"} delay={10} /></div>
                          <div className='option-body'>
                            <div className='cost'><small>Уровень опасности ({diveLevelToDanger(4)})</small></div>
                            <div className='cost red-color'><small>+5 длина маршрута</small></div>

                          </div>
                      </div> :
                      team?.steps_after_dive%3+2 == 2 ? 
                      <>
                        <div className={selectedOption == 3 ? 'selected option': 'option'}
                        onClick={() => {setSelectedOption(3); setSelectedOptionType('hyperdive')}}
                        key={'opti-hyp-same'}
                        >
                            <div className='title'><ArrowSoutheast className='inline-svg'/> <Typewriter text={"Безопасный обходной маршрут"} delay={10} /></div>
                            <div className='option-body'>
                              <div className='cost'><small>Уровень опасности ({diveLevelToDanger(3)})</small></div>
                              <div className='cost red-color'><small>+5 длина маршрута</small></div>

                            </div>
                        </div> 
                      </>
                      :
                      <>
                      </>
                      }
                  </>
                }

              </>

                :<></>
              }
              {
                team?.voyage?.card_type == 'station'? 
                <>
                  <div className={selectedOption == 2 ? 'selected option': 'option'}
                    onClick={() => {setSelectedOption(2); setSelectedOptionType('buy_cargo_modal')}}
                    key={'opti-buy-cargo'}
                    >
                        <div className='title'> <Typewriter text={"Купить экспортные товары"} delay={10} /></div>
                        <div className='option-body'>
                          <div className='cost'><small>х2 ВС на другой станции</small></div>
                        </div>
                  </div>
                  <div className={selectedOption == 1 ? 'selected option': 'option'}
                    onClick={() => {setSelectedOption(1); setSelectedOptionType('repair')}}
                    key={'opti-repair'}
                    >
                        <div className='title'><Typewriter text={"Ремонт / Восстановление"} delay={10} /></div>
                        <div className='option-body'>
                          <div className='cost'><small>восстановление ресурсов (-{repair_count*REPAIR_PRICE_PER_ATTRIBUTE} ВС)</small></div>
                        </div>
                  </div>

                  <div className={selectedOption == -1 ? 'selected option': 'option'}
                    onClick={() => {setSelectedOption(-1); setSelectedOptionType('hyperdive')}}
                    key={'opti-st-hyp-down'}
                    >
                        <div className='title'> <Typewriter text={"Отправиться в путь"} delay={10} /></div>
                        <div className='option-body'>
                          <div className='cost'>
                            {
                              userLocations?.destination && userLocations?.destination?.id && userLocations?.destination?.id != userLocations?.location?.id ?
                              <small><ArrowRight className='inline-svg' /> к станции {userLocations?.destination?.name}</small>
                              :
                                <small className='red-color'>Выберите маршрут на карте</small>
                              
                            }
                            
                            </div>
                        </div>
                  </div>
                  {/*

                    <div className={selectedOption == -2 ? 'selected option': 'option'}
                      onClick={() => {setSelectedOption(-2); setSelectedOptionType('hyperdive_for_hunting')}}
                      key={'opti-st-hyp-down-hunt'}
                      >
                          <div className='title'><ArrowDown className='inline-svg'/> <Typewriter text={"4 слой (Режим охоты)"} delay={10} /></div>
                          <div className='option-body'>
                            <div className='cost'><small>на слой ниже (-5 кармы)</small></div>
                          </div>
                    </div>                    
                  */}
                </>
                :<></>
              }
              {
                team?.voyage?.card_type == 'fight'?
                <>
                  {team?.voyage?.fight?.status != "pending" ?
                    <>
                        <div className={selectedOptionType == 'start_fight_search' && selectedOption == 1 ? 'selected option': 'option'}
                            onClick={() => {setSelectedOptionType('start_fight_search'); setSelectedOption(1)}}>
                            <div className='title'><Typewriter text={"Готовность"} delay={10} /></div>
                            <div className='option-body'>
                              <div className='cost'><small>Ожидание маневра противника</small></div>
                              <div className='requirements'><small>30 сек ожидания</small></div>
                            </div>
                        </div>
                          {/*
                            <div className={selectedOptionType == 'start_fight_search' && selectedOption == 1 ? 'selected option': 'option'}
                            onClick={() => {setSelectedOptionType('start_fight_search'); setSelectedOption(1)}}>
                                <div className='title'>Напасть</div>
                                <div className='option-body'>
                                  <div className='cost'>Победитель забирает все</div>
                                  <div className='requirements'>60 сек ожидания</div>
                                </div>
                          </div>
                          <div className={selectedOptionType == 'start_fight_search'&& selectedOption == 0 ? 'selected option': 'option'}
                            onClick={() => {setSelectedOptionType('start_fight_search'); setSelectedOption(0)}}>
                                <div className='title'>Торговать</div>
                                <div className='option-body'>
                                <div className='cost'>+2 груза обеим командам</div>
                                <div className='cost'>Штраф ЭНР при засаде</div>
                                <div className='requirements'>60 сек ожидания</div>
                                </div>
                          </div>
                          <div className={selectedOptionType == 'start_fight_search'&& selectedOption == -1 ? 'selected option': 'option'}
                            onClick={() => {setSelectedOptionType('start_fight_search'); setSelectedOption(-1)}}>
                                <div className='title'>Скрыться</div>
                                <div className='option-body'>
                                  <div className='cost'>30% вероятность уйти от нападения</div>
                                  <div className='requirements'>60 сек ожидания</div>
                                </div>
                          </div>
                        */}
                          
                    </> 
                    :
                    <>
                      <div className={selectedOptionType == 'test_bot_fight display-none'&& selectedOption == -1 ? 'selected option display-none': 'option display-none'}
                            onClick={() => {setSelectedOptionType('test_bot_fight'); setSelectedOption(-1);selectOption(); setOptionsTab(1 - optionsTab)}}>
                                <div className='title'>тест с ботами</div>
                                <div className='option-body'>
                                  <div className='cost'><small>ДЛЯ ТЕСТА НЕ ТЫКАТЬ</small></div>
                                </div>
                          </div>
                      <p className='option inactive'> Ожидание маневра оппонента </p>
                    </>
                  }
                </>  
                :<></>
                
              }
              {
                team?.voyage?.card_type == 'mining'?
                <>                    
                      <div className='option inactive'> <p>Ожидание завершения бурения</p><p>Есть опасность быть атакованным</p></div>
                </>  
                :<></>
                
              }
            </div>
          }
          </div>


        {
          user && user.is_captain ?

          <>
            {team?.voyage?.event ? 
              <>
                {
                  team?.voyage?.card_type == "fight" ? 
                    !team?.voyage?.fight ?
                      <>
                      <DescriptionAppear  key={team?.voyage?.event?.id +' ' + team?.steps_after_dive} text='<a href="https://telegra.ph/Pravila-Srazhenij-06-11" target="_blank">Тепловые сигнатуры</a> на радаре. Неопознанные корабли в пределах обнаружения.'/>

                      {/*
                          <div className='event'>

                          <Typewriter key={team?.voyage?.event?.id +' ' + team?.steps_after_dive} text="Тепловые сигнатуры на радаре. Неопознанные корабли в пределах обнаружения."
                        delay={10} />
                        </div>*/}
                      </>
                    :
                    <div className='event description-appear'>
                        {/*<p className='highlight'>Ожидание маневра оппонента</p>                                
                        <br/>*/}                                       
                        <p>В случае начала боестолкновения - управление будет переведено в боевой режим автоматически и всем игрокам отправится уведомление в чат с ботом</p>
                    </div>

                  :
                  team?.voyage?.card_type == "mining" ? 
                    <>
                      <DescriptionAppear  key={team?.voyage?.event?.id +' ' + team?.steps_after_dive} 
                      text='<a href="https://telegra.ph/Razrabotka-mestorozhdenij-11-30" target="_blank">Идет добыча минералов</a> Возможно обнаружение вражескими кораблями. В случае начала боестолкновения - управление будет переведено в боевой режим автоматически и всем игрокам отправится уведомление в чат с ботом.'
                      />
                    </>
                  :

                  team?.voyage?.event?.description ?
                    <>

                      {/*
                        ((team?.voyage?.card_type == 'start' || team?.voyage?.card_type == 'middle' || team?.voyage?.card_type == 'delayed_start') && (!team?.voyage?.options || team?.voyage?.options?.length == 1))?
                        
                          team?.voyage?.options ?

                          team?.voyage?.options.map( (e:any) => {

                              let req_str = GetRequirements(e?.stats?.requirements)
                              let cost_str = GetCost(e?.stats?.cost)
                              return(
                                <div className='event answer inactive  description-appear '>
                  
                  
                                      <p className=''>
                                      {team?.voyage?.options[0].name} </p><p className=''>
                                            {cost_str + req_str != '' ?
                                                  <>
                                                <span className='cost'> {cost_str + ' '}</span>
                                                <span className='requirements'> {req_str !='' ? 'Требования: ' + req_str : ''}</span>
                                              </>
                                              : 
                                              <>
                                                
                                              </>}                
                                      </p>
                              </div>
                              )
                          })
                          <></>
                          :
                          <div className='event answer inactive  description-appear '><p className=' '>Далее</p></div>                    
                        
                        :
                        <></>*/
                      }
                      {team?.voyage?.card_type == "delayed_mining" ? 
                      <div className='event description-appear cyan-color'>Рассчетное время добычи: {(team?.freight_limit - team?.freight - team?.cargo) * 20} СЕК</div>
                      :<></>}

                      <div className='event description-appear' key={'event-d' + team?.voyage?.event?.id + ' ' + team?.steps_after_dive} dangerouslySetInnerHTML={{__html: team?.voyage?.event?.description}}/>

                      {team?.voyage?.event?.public_name ? <div className='event-title' key={'titletypewriter-' + team?.voyage?.event?.id}><h2 ><Typewriter text={team?.voyage?.event?.public_name} delay={20} /> <span className='inactive'>// {team?.days_count} ДЕНЬ</span></h2></div> :<></>}
                      {team?.voyage?.card_type == 'station' ? <div className='event-title' ><h2 className='uppercase'>СТАНЦИЯ {userLocations?.location?.name}</h2></div> :<></>}

                      {
                        voyageHistory?.events?.slice(0).map((e : any, index) => {
                          return(<>
                          {
                            e?.type == 'event'?
                            <>
                            <div className='event' key={'event-d' + e?.id + ' ' + index} dangerouslySetInnerHTML={{__html: e.description}}/>
                            {e?.public_name ? <div className='event-title' key={'titletypewriter-' + e?.id}><h2 >{e?.public_name} <span className='inactive'>// {team?.days_count} ДЕНЬ</span></h2></div> :<></>}
                            </>
                            :
                            <>
                            <div className='event answer'> {e.description}</div>
                            </>
                            
                          }
                          </>
                          )
                        })
                      }
                        {
                        team?.voyage?.card_type == "delayed_start" ?
                        <div className='event-note'><span className='inactive'>Последствия прошлых решений:</span></div>
                        :<></>
                      }
                      {/*
                      <Typewriter key={team?.voyage?.event?.id +' ' + team?.steps_after_dive} text={team?.voyage?.event?.description }
                    delay={10} />
                      */}
                    </>
                  :
                  <></>
                  
                }
              </>
              :
              <></>
            }
          

                      
          </>:
          <div className='event'>
            <p><span className='highlight'> Управление передано лидеру группы</span></p>
            <br/>
            <p>Вы будете получать уведомления о прибытии на станцию, начале сражений и гибели экипажа.</p>
            <p>Вы может закрыть приложение до дальнейших указаний. </p>
          </div>
          }

          

          </div>

          </div>

          <div className="command-order-btns-row">
              { !user.is_captain?
                <>
                  <div className="next-target-btn"  onClick={() => setOptionsTab(1 - optionsTab)}>ОТМЕНА</div>
                  <div className="order-btn inactive"  onClick={() => {}}>НЕ ДОСТУПНО</div>
                </>
              :
              team?.voyage?.card_type == 'fight' && team?.voyage?.fight?.status == "pending" || team?.voyage?.card_type == 'mining'?
              <>
                <div className="secondary-order-btn"  onClick={() => {setOptionsTab(1 - optionsTab)}}>ЗАКРЫТЬ</div>  
              </>
              :
              <>
                  <div className="next-target-btn"  onClick={() => setOptionsTab(1 - optionsTab)}>ОТМЕНА</div>
                  <div className="order-btn"  onClick={() => {selectOption()}}>ПОДТВЕРДИТЬ</div>
              </>
              }
            </div>
          </>
          :<></>}

        </div>
          {/*
          <div className={team?.voyage?.card_type == 'station' && !markerOn ? 'bottom-drawer open-voyage-hat station-card-drawer dotted-background ': 'bottom-drawer open-voyage-hat hidden'}>
              
                <div className='station-card-div'>
                    <img 
                    src={'/IMG_5125.JPG'} 
                    className={'station-card-background'}
                    onLoad={() => {}}
                    alt="Loaded content"/>
                </div>
            </div>        

          */}

          <div className={taxModal? 'bottom-drawer': 'bottom-drawer hidden'}>
          <div className='drawer-header'>Продажа груза</div>

              <div className='freight-tax-text'>
                {quote_stylizer_helper1(freightDelivered, cargoDelivered, team?.users?.length, !cargoIsReturned)}
                <span className='main-color'>                
                  {quote_stylizer_helper2(freightDelivered, cargoDelivered, team?.users?.length, !cargoIsReturned)}
                </span>
                {quote_stylizer_helper3(freightDelivered, cargoDelivered, team?.users?.length, !cargoIsReturned)}

              </div>   
              <div className="command-order-btns-row">

              <div className="secondary-order-btn"  onClick={() => {setTaxModal(0)}}>ЗАКРЫТЬ</div>  
              </div>       
            </div>
          {team?.dive_level == 5? 
            <div  className={manualModal? 'manual-modal': 'manual-modal hidden'} onClick={()=>setManualModal((manualModal + 1) %9)}>
              <div className='voyage'>
                <div className='voyage-section' >

                <div className='tabs-row'>
                  <div className='tab'>{manualModal == 6? <p className="main-border-highlight" style={{margin: -1}}>КАРТА ВЫБОРА МАРШРУТА</p> : "ОБУЧЕНИЕ"} </div>
                  
                </div>
                <div className='screen'>
                  
                  <div className='info-panel-left not-fixed '>
                    <p className={manualModal == 2? 'voyage-stats-list ' :"display-none"}>
                      <span className='highlight'>основные показатели</span>держите их выше нуля для выживания
                      <br/><br/>
                      <span className='main-border-highlight' style={{textAlign:"left", width: 200, padding: 4}}>Карма - влияет на вероятность встречи с другими игроками</span> 
                      </p>
                    </div>
                  
                  <div className='info-panel-right not-fixed '>
                    <p className={manualModal == 3? 'voyage-stats-list  right' :"display-none"}>
                    <span className='highlight'>очки репутации</span>по мере вашего путешествия будут накапливаться, открывая вам новые опции выбора<br/>
                    <br/><span className='main-border-highlight' style={{textAlign:"left", width: 200, padding: 4}}>грузы/товары- основная ценность, добывается в гипердайве или закупается на станции для перевозки</span> 
                      </p>
                    </div>
                  
                  <div className='event-card'>
                    <div className={manualModal == 1? 'main-border-highlight':"display-none"}>

                    <h2 className='main-border-highlight  ' style={{margin: 4}}>ГИПЕРДАЙВ</h2>
                    
                    <p className="main-color">
                      Приветствую, капитан. Давайте повторим инструктаж перед отправкой в путешествие.
                    </p>

                    </div> 
                    <div className={manualModal == 4? 'main-border-highlight':"display-none"} style={{padding:4}}>
                    <span className='highlight'>Индикаторы кораблей</span> <br/>Нажмите на метку корабля, чтобы открыть его карточку.
                    <br/><br/>
                    <span className='highlight'>Объединяйтесь в группы</span> чтобы увеличить выживаемость в бою. 
                    <br/><br/>
                    <small className='inactive'>Показатели считаются средними для экипажей, грузоподъемность суммируется, доход делится поровну. Управление полетом переходит лидеру группы.</small>

                      </div>

                    <p className={manualModal == 6? 'main-border-highlight':"display-none"} style={{padding:4}}>
                          <span className='highlight'>Нулевой переход</span> - единственный способ межзвездных перелетов на досветовых скоростях
                          <br/><br/>
                          Для перелета между станциями нужно пройти через нулевой слой гипердайва. Так корабль окажется в секторе вселенной, где можно подняться к выбранной станции.

                    </p>

                    <p className={manualModal == 7? 'main-border-highlight':"display-none"} style={{padding:4}}>
                          <span className='highlight'>ДРУГИЕ ИГРОКИ</span> <br/><br/>Иногда вам на пути будут встречаться другие караванщики, которые могут обменяться с вами товарами или напасть.
                          <br/><br/>
                          Заранее ознакомьтесь с боевыми механиками, чтобы быть готовыми к любым поворотам судьбы.
                          <br/><br/>
                          <a href="https://telegra.ph/Pravila-Srazhenij-06-11" target="_blank" className='cyan-highlight clickable-field' >БОЕВЫЕ МЕХАНИКИ {">"}</a>
                    </p>
                    <p className={manualModal == 8? 'main-border-highlight':"display-none"} style={{padding:4}}>
                        <h2 className='main-border-highlight  '>MEMENTO MORI</h2>

                          Смерть экипажа — естественная часть игры. Это завершение одной истории и начало другой — таков цикл жизни. Примите ее с честью и усмешкой, ведь вы постарались на славу.
                          <br/><br/>
                          С уважением,
                          Редакция "Путеводитель Караванщика"
                    </p>

                      </div> 
                  <div className='top-center-loc-bar'>
                    <div  className={manualModal == 6? 'label-bar cyan-color':"display-none"}>
                      <small>прогресс перелета</small>
                      <div className='position-bar'>
                      <div className='rail-bar'>
                          <div className='position-mark' style={{width : '30%' }}/>
                          </div>
                        </div>
                    </div>
                  </div>
                  
                  <div className='screen-bottom'>
                    <div className='left-info-bar'>
                      <div className={manualModal == 5? 'altitude-bar left':"display-none"}>
                        <p className='main-border-highlight' style={{padding:4, margin: 4}}>
                          Индиктор слоя гиперпространства

                          </p>
                          <p className='main-color' style={{padding:4, margin: 4, width: 200}}>

                          На 5 слое распологается известная нам вселенная.
                          <br/>
                          Чтобы попасть на станцию - поднимитесь на 5 слой
                          <br/>
                          <br/>
                            Более глубокие слои приносят больше награды, но и более опасны для экипажа

                        </p>
                        </div>
                    </div>
                    <div className='right-info-bar'>
                    <div className={manualModal == 5? 'altitude-bar right':"display-none"}>
                    <p className='main-border-highlight' style={{padding:4, margin: 4, textAlign:"right"}}>
                      Расстояние до точки перехода между слоями
                      </p>
                      <p className='main-color' style={{padding:4, margin: 4, width: 150, textAlign:"right"}}>
                      (Количество событий)
                      </p>
                    </div>


                    </div>
                  </div>
                  </div>
                  <div className={manualModal == 6  ? 'secondary-screen':"display-none"}>
                    <p className='main-border-highlight' style={{padding:4, width:200}}>
                      Тумблер ПУТЬ включает индикацию сектора вселенной<br/>относительно нулевого слоя<br/>
                    </p>
                  </div>
                  <div className={manualModal == 7  ? 'secondary-screen':"display-none"}>
                    <p className='main-border-highlight' style={{padding:4, width:200}}>
                      Показатель вероятности встречи зависит от кармы<br/>
                    </p>
                  </div>
                  <div className={manualModal == 8  ? 'secondary-screen':"display-none"}>
                  <p className='main-border-highlight' style={{padding:4, width:"100%"}}>
                  При уничтожении корабля, весь экипаж обнуляется, сохранится лишь валюта на станции.
                          </p>

                    </div>
                </div>

              </div>

            </div>
          :
          <></>}

          <div className={cargoModal? 'bottom-drawer': 'bottom-drawer hidden'}>
          <div className='drawer-header'>Покупка экспортного товара</div>

                <p><span className='main-color'>  Кол-во: {cargoPurchaseQuantity}</span><span className='inactive'>/{team?.freight_limit - team?.cargo - team?.freight}</span></p>
                <span className='btn' onClick={() => changeCargoPQ(1)}>+</span>
                <span className='btn' onClick={() => changeCargoPQ(-1)}>-</span>
              <div className='freight-tax-text'>

                <span className='main-color'>  Итоговая цена: {cargoPurchaseQuantity * PRICE_PER_CARGO} ВС{"\n"}</span>
                <span>Цена при продаже на другой станции: {"\n"}{cargoPurchaseQuantity * PRICE_PER_DELIVERED_CARGO} ВС</span>

              </div>   
              <div className="command-order-btns-row">
                <div className="next-target-btn"  onClick={() => {setCargoModal(0); setCargoPurchaseQuantity(0)}}>ОТМЕНА</div>
                {
                cargoPurchaseQuantity > 0 ? 
                <div className="order-btn"  onClick={() => {setCargoModal(0); purchaseCargo()}}>ПОДТВЕРДИТЬ</div>
                :
                <div className="order-btn inactive">ПОДТВЕРДИТЬ</div>

                }
              </div>       
            </div>

      </div>
      </>

    : screenTab == 'stats' ?
      <TeamComponent/>
    : screenTab == 'navigation' ?
      <StationsGraph/>
    :
    <>
    нет доступа
    
    <div className='hud no-opacity noselect'>
        
        <div className="commands-panel">

          
          <div className="command-order-btns-row">
          {screenTab == 'voyage'?
            <div className="secondary-order-btn" onClick={() => setOptionsTab(1 - optionsTab)}>ВЫБОР ДЕЙСТВИЯ</div>
            :
            <div className="order-btn inactive">ПЕРЕДИТЕ В УПРАВЛЕНИЕ</div>
          }
          </div>
        </div>
      </div>
    </>
    }
  </>
  //      <axesHelper />

  )
}
