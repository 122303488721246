import React, { useRef, useState, useEffect } from 'react';

function createDot(x: any, y: any) {
    var elem = document.createElement("div");
    elem.setAttribute("class", "dot");
    elem.setAttribute("style", "left:" + x + "px;top:" + y + "px;");
    if(document.getElementsByClassName("root") && document.getElementsByClassName("root")[0]){
      document.getElementsByClassName("root")[0].appendChild(elem);
      return elem;
    }
  }
  
  function anotherDot() {
    createDot(Math.floor(Math.random() * 200), Math.floor(Math.random() * 200));
  }
  
  function createDots(){
    for (var i = 0; i < 100; i++) {
      anotherDot();
    }
  }

  export default function GridBackground () {
    return <>
        {
          [...Array(23)].map((e, i) => {
            let x = Math.floor(Math.random() * 100)
            let y = Math.floor(Math.random() * 100)
            //x= i % 5 * 100/ 5 + 10
            //y = Math.floor(i / 5) * 100 /8 + 10
            return <>

                    <div 
                    className= {'cross ' + (i % 5 == 0 ? 'light' : '')}
                    style={{
                    left: x + '%', 
                    top: y  + '%'}}
                    >
                      </div>

                    <div className= {'cross ' + (i % 5 == 0? 'light' : '')}
                    style={{
                        left: (x)  + '%', 
                        top: (y - 100)  + '%'}}
                    >
                    </div>
                                    
            </>
        })
        }
    </>
  }