import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { SelectedCoords } from '../models/types';

const initialState: SelectedCoords = {
  loc_x:-1,
  loc_y:-1,
};

export const selectedCoordsSlice = createSlice({
  name: 'selectedCoords',
  initialState,
  reducers: {
      setSelectedCoords: (state, action) => {
        state.loc_x = action.payload?.loc_x;
        state.loc_y = action.payload?.loc_y;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setSelectedCoords } = selectedCoordsSlice.actions;

export default selectedCoordsSlice.reducer;
