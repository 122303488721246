import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import {setSelectedDirection} from '../../slices/selectedDirectionSlice';
import RandomDotsBackground from "./RandomDotsBackground"
import { ReactComponent as ArrowRight } from '../../utils/icons/arrow-right.svg';  // Import as a React component
import { ReactComponent as ArrowDown } from '../../utils/icons/arrow-down.svg';  // Import as a React component
import { teamPathSlice } from '../../slices/teamPathSlice';

//import items from '../utils/items';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const path_width = 100
const path_height = 100
const event_cropped_height = 80

const padding = 10
const card_width = 70

interface HyperdiveMapProps {
  karma: any /*change:boolean, equip_id:number,*/ 
  }
    
const HyperdiveMap: React.FC<HyperdiveMapProps> = ({karma}) =>{
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');

  const Width = 500
  const Q_of_lines = 41
  let karma_coef = 1
  const lines = Array.from({ length: Q_of_lines }, (_, index) => 2 + index * Math.floor(Width/Q_of_lines));
  let duration = 1
  let shrinkTo = 1


  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
 // const [selectedCoords, setSelectedCoords] = useState({loc_x: 1, loc_y: 1});
  const team = useSelector((state:RootState)=> state.team);
  const selected_direction = useSelector((state:RootState)=> state.selectedDirection);
  const userLocations = useSelector((state:RootState)=> state.userLocations);


  //console.log("selected_direction",selected_direction)


    const handleMouseDown = (e:any) => {
      
      if (!scrollRef.current) return; // Prevent accessing null ref

      setIsDragging(true);
      setStartX(e.pageX - scrollRef.current.offsetLeft);
      setStartY(e.pageY - scrollRef.current.offsetTop);
      setScrollLeft(scrollRef.current.scrollLeft);
      setScrollTop(scrollRef.current.scrollTop);
  };

  const handleMouseMove = (e:any) => {
      if (!isDragging || !scrollRef.current) return;
      e.preventDefault();
      const x = e.pageX - scrollRef.current.offsetLeft;
      const y = e.pageY - scrollRef.current.offsetTop;
      const walkX = (x - startX) * -1;
      const walkY = (y - startY) * -1;

      scrollRef.current.scrollLeft = scrollLeft + walkX;
      scrollRef.current.scrollTop = scrollTop + walkY;
  };

  useEffect(() => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      scrollRef.current.scrollTo({
        left: (team?.loc_x -1 ) * 70 + 50 - container.clientWidth / 2 + 50,
        top: (team?.loc_y  + 1) * -70 + container.clientHeight / 3//, behavior: "smooth" 
      });
      }
    }, [dispatch]); // Scrolls when x or y changes

  const handleMouseUp = () => setIsDragging(false);

  // <button onClick={() => scrollToPosition(400, 300)}>Scroll to (400, 300)</button>


  // Helper function to generate random animation duration and shrink values
  const getRandomAnimation = () => {
    duration = Math.random()/3 + 0.4 ; // Random duration between 1s and 3s
    shrinkTo = 0; // Random shrink point between 50 and 100

    return [
    {
      animation: `shrinkAnimation ${duration}s ease-in-out infinite`,
      '--shrink-to': shrinkTo,
    },
    {
      animation: `shrinkAnimation ${duration}s ease-in-out 0.11s infinite`,
      '--shrink-to': shrinkTo,
    },
    ]
  };

  const GRID_SIZE = 14

  const xorShift = (i : number, salt = 96103) => {
    let x = i ^ salt; // Mix index and salt for variation
  
    // Apply XorShift operations
    x ^= x << 13;
    x ^= x >> 17;
    x ^= x << 5;
  
    return Math.abs(x); // Ensure non-negative results
  };
  

  const generateGrid = (size:any, keyString:any) => {

    
    const HARD_SECTOR_POBABILITY = 11
    const MEDIUM_SECTOR_POBABILITY = 9
    const STATION_SECTOR_POBABILITY = 37
    const MINERALS_SECTOR_POBABILITY = 31
    const NULL_SECTOR_POBABILITY = 38


    const lined_coords = [];
    let coord_value = 'low'; 



    // Generate pseudo-random numbers based on the key string
    for (let i = 0; i < size * size; i++) {
      //const char = keyString[i % keyString.length]; // Cycle through the key
      //const charCode = char.charCodeAt(0); // Get character code
      const randomValue = xorShift(i); // Add index for variation


      if (randomValue % HARD_SECTOR_POBABILITY == 0){
        coord_value = 'hard'
      }else if (randomValue % MEDIUM_SECTOR_POBABILITY == 0){
        coord_value = 'medium'
      }else if (randomValue % STATION_SECTOR_POBABILITY == 0){
        coord_value = 'station'
      }else if (randomValue % MINERALS_SECTOR_POBABILITY == 0){
        coord_value = 'minerals'
      }else if (randomValue % NULL_SECTOR_POBABILITY == 0){
        coord_value = 'null_sector'
      }else{
        coord_value = 'low'; 
      }

      lined_coords.push(coord_value);


    }

    // Split the numbers into NxN grid
    const grid = [];
    for (let row = 0; row < size; row++) {
      grid.push(lined_coords.slice(row * size, (row + 1) * size));
    }

    return grid;
  };
  const updateGrid = (grid:any) => {
    const size = grid.length;
    const newGrid = grid.map((row:any) => [...row]); // Create a copy of the grid

    // Define directions for neighbors (top, bottom, left, right, diagonals)
    const directions = [
      [-1, 0], // Up
      [1, 0],  // Down
      [0, -1], // Left
      [0, 1],  // Right
    ];

    for (let i = 0; i < size; i++) {
      for (let j = 0; j < size; j++) {
        if (grid[i][j] === 'hard') {
          for (const [dx, dy] of directions) {
            const newX = i + dx;
            const newY = j + dy;

            // Check bounds
            if (newX >= 0 && newX < size && newY >= 0 && newY < size && grid[newX][newY] != 'hard') {
              newGrid[newX][newY] = 'medium';
            }
          }
        }
      }
    }

    return newGrid;
  };

  let grid = generateGrid(GRID_SIZE, 'adasf32i09f0vs9dfv9');
  grid = updateGrid(grid);

  let close_direction_x = 0
  let close_direction_y = 0
  
  if (team?.loc_x != team?.target_x || team?.loc_y != team?.target_y){

    if (team?.loc_x == team?.target_x){
      if(team?.loc_y < team?.target_y){
        close_direction_x = 0
        close_direction_y = 1
      }else{
        close_direction_x = 0
        close_direction_y = -1
      }
    }
    else{
        
      let dx = team?.target_x - team?.loc_x
      let dy = team?.target_y - team?.loc_y
      let arc_tan = Math.atan(dy/dx)
      if(arc_tan >Math.PI  / 3){
        close_direction_y = 1
        close_direction_x = 0
      }else if(arc_tan > Math.PI / 6){
        close_direction_y = 1
        close_direction_x = 1
      }
      else if(arc_tan > -Math.PI  / 6){
        close_direction_y = 0
        close_direction_x = 1
      }
      else if(arc_tan > -Math.PI / 3){
        close_direction_y = -1
        close_direction_x = 1
      }
      else {
        close_direction_y = -1
        close_direction_x = 0
      }

     // реверсируем для противоположных значений
      if (team?.target_x < team?.loc_x){
        close_direction_x = -1 * close_direction_x
        close_direction_y = -1 * close_direction_y
      }
      }

  }

  let close_selected_direction_x = 0
  let close_selected_direction_y = 0

  if (team?.loc_x != selected_direction.loc_x || team?.loc_y != selected_direction.loc_y){

    if (team?.loc_x == selected_direction.loc_x){
      if(team?.loc_y < selected_direction.loc_y){
        close_selected_direction_x = 0
        close_selected_direction_y = 1
      }else{
        close_selected_direction_x = 0
        close_selected_direction_y = -1
      }
    }
    else{
        
      let dx = selected_direction.loc_x - team?.loc_x
      let dy = selected_direction.loc_y - team?.loc_y
      let arc_tan = Math.atan(dy/dx)
      if(arc_tan >Math.PI  / 3){
        close_selected_direction_y = 1
        close_selected_direction_x = 0
      }else if(arc_tan > Math.PI / 6){
        close_selected_direction_y = 1
        close_selected_direction_x = 1
      }
      else if(arc_tan > -Math.PI  / 6){
        close_selected_direction_y = 0
        close_selected_direction_x = 1
      }
      else if(arc_tan > -Math.PI / 3){
        close_selected_direction_y = -1
        close_selected_direction_x = 1
      }
      else {
        close_selected_direction_y = -1
        close_selected_direction_x = 0
      }

     // реверсируем для противоположных значений
      if (selected_direction.loc_x < team?.loc_x){
        close_selected_direction_x = -1 * close_selected_direction_x
        close_selected_direction_y = -1 * close_selected_direction_y
      }
      }

  }

  


  const memoizedMapComponent = useMemo(() => {
    return (<>
    {
    [...Array(GRID_SIZE)].map((e1, i1) => <div className={i1%2 == 0 ? 'row' : 'row '}>
        {[...Array(GRID_SIZE)].map((e2, i2) => <div className={'loc-box '} onClick={()=> {/*setSelectedCoords({loc_x: i2,loc_y:i1}); */dispatch(setSelectedDirection({loc_x: i2,loc_y:i1}))}}>
          <div className={
            'dive-level' + ((i1%3) * (i2%3) == 1 ? ' ' : ' ')
          }
          style={grid[i1][i2] == 'hard1'? {backgroundColor:'#888'}:
          grid[i1][i2] == 'medium1'? {backgroundColor:'#666'}:{}
          }
          >
            
                      {<div className={
                        (
                        i1 == 1 && i2 == 1 || (i1 == GRID_SIZE - 2 && i2 == GRID_SIZE - 2)? 'hyperdive-loc':
                        grid[i1][i2] == 'hard' ? '' :  
                        grid[i1][i2] == 'medium' ? '' : 
                        grid[i1][i2] == 'station' ? 'station-loc ' : 
                        grid[i1][i2] == 'minerals' ? 'minerals-loc' : 
                        grid[i1][i2] == 'null_sector' ? 'storm null-sector' : 
                        '') +' non-clickable-field noselect'
                      }>
                      {i1 == 1 && i2 == 1?<ArrowDown className='inline-svg'/> :<></>}
                      {(i1 == GRID_SIZE - 2 && i2 == GRID_SIZE - 2) ?<ArrowDown className='inline-svg' style={{rotate:'180deg'}}/> :<></>}

                      </div>}



                      <small className={(
                        grid[i1][i2] == 'station' || (i1 == 1 && i2 == 1) || (i1 == GRID_SIZE - 2 && i2 == GRID_SIZE - 2)  ? 'loc-label cyan-color' : 
                        grid[i1][i2] == 'null_sector' ? 'loc-label red-color': 

                        
                        'loc-label') + ' non-clickable-field noselect' +
                        ((i1 == 1 && i2 == 1) || (i1 == GRID_SIZE - 2 && i2 == GRID_SIZE - 2)  ? ' hyperdive-loc-label ' :'')
                        }>
                          {
                            i1 == 1 && i2 == 1 ? 'вход\n'+userLocations?.location?.name:
                            (i1 == GRID_SIZE - 2 && i2 == GRID_SIZE - 2) ? 'выход\n' +(userLocations?.destination ? userLocations?.destination?.name : userLocations?.location?.name) :
                            grid[i1][i2] == 'station' ? 'станция': 
                            grid[i1][i2] == 'minerals' ? 'месторождение':
                            grid[i1][i2] == 'null_sector' ? 'аномалия': ''
                            
                          }
                      </small>


                      {
                        
                      }
                      {grid[i1][i2] == 'hard' ?
                         <div className='background-canvas'>
                          <RandomDotsBackground dots_num={33} duplicate={false}/>
                        </div>
                        :
                        grid[i1][i2] == 'medium' ?
                         <div className='background-canvas'>
                          <RandomDotsBackground dots_num={10} duplicate={false}/>
                        </div>
                        :
                        <div className='background-canvas'>
                            <RandomDotsBackground dots_num={1} duplicate={false}/>

                      </div>}
          
          </div>

        </div>)}

    </div>)
    }
    </>)
  }, [dispatch]);




  const memoizedMapComponentArrows = useMemo(() => {
    return (<>
  <svg className='svg-arrows' width={(GRID_SIZE)*70} height={(GRID_SIZE)*70}
    >
    
          <>
          {/*Q - это сглаживание */}
            <path
             
              stroke={'cyan'}
              strokeDasharray={true ? '4' : 'none'}
              data-arrow={'target-destination'}
              key={Math.ceil(Math.random() * 999999)}
              strokeWidth="1px"
              fill="none"
              className=" arrow-path"
              
              d={
                (true )? 
                  `M ${(team?.loc_x+0.5) * card_width} , ${(team?.loc_y+0.5) *card_width}

                   L ${(team?.target_x + 0.5)*card_width}, ${(team?.target_y + 0.5)*card_width}`
              :
              
              `]`
              }
              
            />
                            <circle 
                  cx={(team?.target_x+0.5) * card_width} 
                  cy={(team?.target_y+0.5) * card_width} 
                  r={1.5} 
                  strokeDasharray={false ? '4' : 'none'}
                  data-arrow={'target-circle'}
                  key={Math.ceil(Math.random() * 999999)}
                  strokeWidth="1px"
                  opacity={1}
                  fill="cyan"
                  className='arrow-path'
                />

            {
              close_direction_x != 0 || close_direction_y != 0 ?
              <>
                <path
                  
                  stroke={'cyan'}
                  strokeDasharray={false ? '5,5' : 'none'}
                  data-arrow={'target-destination-close'}
                  key={Math.ceil(Math.random() * 999999)}
                  strokeWidth="1px"
                  fill="none"
                  className='arrow-path'
                  
                  d={
                    (true )? 
                      `M ${(team?.loc_x+0.5) * card_width} , ${(team?.loc_y+0.5) *card_width}
                      L ${(team?.loc_x+close_direction_x+0.5) * card_width} , ${(team?.loc_y+close_direction_y+0.5) *card_width}`
                  :
                  
                  `M ${(team?.loc_x+0.5) * card_width} , ${(team?.loc_y+0.5) *card_width}
                  L ${(team?.loc_x+1.5) * card_width} , ${(team?.loc_y+1.5) *card_width}
                  L ${(team?.target_x + 0.5)*card_width}, ${(team?.target_y + 0.5)*card_width}`
                  }
                />
                                <circle 
                  cx={(team?.loc_x+0.5) * card_width} 
                  cy={(team?.loc_y+0.5) *card_width} 
                  r={70 * Math.sqrt(close_direction_x*close_direction_x +close_direction_y*close_direction_y)} 
                  stroke={'#888'}
                  strokeDasharray={false ? '5,5' : 'none'}
                  data-arrow={'target-circle'}
                  key={Math.ceil(Math.random() * 999999)}
                  strokeWidth="1px"
                  opacity={1}
                  fill="none"
                  className='arrow-path'

                />
                
                <circle 
                  cx={(team?.loc_x+close_direction_x+0.5) * card_width} 
                  cy={(team?.loc_y+close_direction_y+0.5) * card_width} 
                  r={1.5} 
                  strokeDasharray={false ? '5,5' : 'none'}
                  data-arrow={'target-circle'}
                  key={Math.ceil(Math.random() * 999999)}
                  strokeWidth="1px"
                  opacity={1}
                  className='arrow-path'
                  fill="cyan"
                />


              </>
              :<></>
            }
          </>
        
    </svg>
    </>)
  }, [dispatch, team]);

  let days_to_target = Math.max(Math.abs(team?.target_x - team?.loc_x), Math.abs(team?.target_y - team?.loc_y) )
  let days_to_selected_tile = Math.max(Math.abs(selected_direction.loc_x - team?.loc_x), Math.abs(selected_direction.loc_y - team?.loc_y) )

  return (
    <>
      <div className='hyperdive-map-header'>// карта вер.32.01</div>
    <div className='map-grid scroll-bar-hide'  
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      
      {
      !selected_direction || selected_direction.loc_x < 0 || selected_direction.loc_y < 0 ?
      <></>:
      <>
      <svg className='svg-arrows selected-target' width={(GRID_SIZE)*70} height={(GRID_SIZE)*70}>

        {close_direction_x == 0 && close_direction_y == 0?
          <>
            {
              close_selected_direction_x != 0 || close_selected_direction_y != 0 ?
              <>
                <path
                  
                  stroke={'#888'}
                  strokeDasharray={false ? '4' : 'none'}
                  data-arrow={'target-selected-destination-close'}
                  key={Math.ceil(Math.random() * 999999)}
                  strokeWidth="1px"
                  fill="none"
                  className='arrow-selected-path'
                  d={
                    (true )? 
                      `M ${(team?.loc_x+0.5) * card_width} , ${(team?.loc_y+0.5) *card_width}
                      L ${(team?.loc_x+close_selected_direction_x+0.5) * card_width} , ${(team?.loc_y+close_selected_direction_y+0.5) *card_width}`
                  :
                  
                  `M ${(team?.loc_x+0.5) * card_width} , ${(team?.loc_y+0.5) *card_width}
                  L ${(team?.loc_x+1.5) * card_width} , ${(team?.loc_y+1.5) *card_width}
                  L ${(team?.target_x + 0.5)*card_width}, ${(team?.target_y + 0.5)*card_width}`
                  }
                />
                                <circle 
                  cx={(team?.loc_x+0.5) * card_width} 
                  cy={(team?.loc_y+0.5) *card_width} 
                  r={70 * Math.sqrt(close_selected_direction_x*close_selected_direction_x +close_selected_direction_y*close_selected_direction_y)} 
                  stroke={'#888'}
                  strokeDasharray={false ? '5,5' : 'none'}
                  data-arrow={'target-selected-circle'}
                  key={Math.ceil(Math.random() * 999999)}
                  strokeWidth="1px"
                  fill="none"
                  className='arrow-selected-path'

                />
                
                <circle 
                  cx={(team?.loc_x+close_selected_direction_x+0.5) * card_width} 
                  cy={(team?.loc_y+close_selected_direction_y+0.5) * card_width} 
                  r={1.5} 
                  strokeDasharray={false ? '5,5' : 'none'}
                  data-arrow={'target-selected-circle'}
                  key={Math.ceil(Math.random() * 999999)}
                  strokeWidth="1px"
                  opacity={1}
                  className='arrow-path'
                  fill="#888"
                />


              </>
              :<></>
            }
          </>      
          :<></>    
          }

          <>
          {/*Q - это сглаживание */}
            <path
             
              stroke={'#888'}
              strokeDasharray={true ? '4' : 'none'}
              data-arrow={'target-destination'}
              key={Math.ceil(Math.random() * 999999)}
              strokeWidth="1px"
              fill="none"
              className="arrow-path-pulsate"
              
              d={
                (true )? 
                  `M ${(team?.loc_x+0.5) * card_width} , ${(team?.loc_y+0.5) *card_width}

                   L ${(selected_direction.loc_x + 0.5)*card_width}, ${(selected_direction.loc_y + 0.5)*card_width}`
              :
              
              `]`
              }
            />

              <circle 
                  cx={(selected_direction.loc_x+0.5) * card_width} 
                  cy={(selected_direction.loc_y+0.5) * card_width} 
                  r={1.5} 
                  strokeDasharray={false ? '5,5' : 'none'}
                  data-arrow={'target-circle'}
                  key={Math.ceil(Math.random() * 999999)}
                  strokeWidth="1px"
                  opacity={1}
                  fill="#888"
                  className="arrow-path-pulsate"

                />
            
          </>

         

    </svg>
    </>
    
    
    }

    {memoizedMapComponent}
    {memoizedMapComponentArrows}
    
    <div className='loc-selection' 
    style={{transform: 'translate('+ selected_direction.loc_x * 70  +'px ,' + (selected_direction.loc_y ) * -70 +'px )'}}>
    <div className='selection-border-1'/><div className='selection-border-2'/>
    </div>

    {/*<div className=
      {selected_direction.loc_y == 0 && selected_direction.loc_x == 0 ? 'selected-direction cyan-highlight display-none'
        : 'selected-direction'}
      style={{transform: 'translate('+ (team?.target_x) * 70  +'px ,' + (team.target_y ) * -70 +'px )'}}
      >
      <ArrowDown className='inline-svg'/>
    </div>*/}
    <div 
      className='arrow-days-label'
      style={{transform: 'translate('+ ((selected_direction.loc_x +0.5)*70)+'px ,' + (( selected_direction.loc_y+0.5)* -70  - 16) +'px )'}}
    >
      <small className=' grey-label'>{days_to_selected_tile}дн</small>
    </div>

    
    {days_to_target > 0? <div 
          className='arrow-days-label'
          style={{transform: 'translate('+ ((team?.target_x + 0.5)*70 ) +'px ,' + ((team?.target_y +0.5)* -70  - 16)+'px )'}}
    >
      <small className=' cyan-label'>{days_to_target}дн</small>
    </div> :<></>}



    

      <div className='team-location'
      style={{transform: 'translate('+  (team?.loc_x ) * 70  +'px ,' + (team?.loc_y) * -70 +'px )'}}>

            <img className='marker' src={'../markers/triangle1.svg'} alt="React Logo" />

      </div> 

    
  </div>
  </>

  );

}


export default HyperdiveMap


