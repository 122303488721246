import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE } from '../utils/consts';
import { Direction } from '../models/types';

const initialState: Direction = {
  loc_x: 1,
  loc_y: 1
};

export const selectedDirectionSlice = createSlice({
  name: 'selectedDirecion',
  initialState,
  reducers: {
      setSelectedDirection: (state, action) => {
        state.loc_x = action.payload?.loc_x;
        state.loc_y = action.payload?.loc_y;
      }
  },
    extraReducers: (builder) => {
        builder.addCase(RESET_STORE, () => initialState);
    },
});

export const { setSelectedDirection } = selectedDirectionSlice.actions;

export default selectedDirectionSlice.reducer;
