import React, { useRef, useState, useEffect} from 'react';
import ky from 'ky';
import EquipCard from '../inventory/EquipCard';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
//import items from '../utils/items';
import {setUser} from '../../slices/userSlice';
import {setAvailableItems} from '../../slices/availableItems';
import {setScreenTab} from '../../slices/screenTabSlice';
import {setShowShipCardId} from '../../slices/showShipCardIdSlice';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

interface InventoryProps {
  fetch_team_func: () => void; // Define the function's type
}

export default function Inventory({ fetch_team_func }: InventoryProps) {
  const dispatch = useDispatch();
  const [equipModal, setEquipModal] = useState(0)
  const [hullModal, setHullModal] = useState(0)
  const [crewModal, setCrewModal] = useState(0)
  const [navigatorModal, setNavigatorModal] = useState(0)
  const [moduleTab, setModuleTab] = useState('module')
  const [newPrice, setNewPrice] = useState(0)
  const [changeItemTargetId, setChangeItemTargetId] = useState(0)
  const [newItemTargetId, setNewItemTargetId] = useState(0)
  const [hullIsLoaded, setHullIsLoaded] = useState(false);
  const [hullClickAnimation, setHullClickAnimation] = useState(0);

  const user = useSelector((state:RootState)=> state.user);
  const available_items = useSelector((state:RootState)=> state.availableItems);
  //const available_equips :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  //const available_hulls :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  //const available_crews :any[] = []//available_items?.find((el:any) => el.item_type === "hull")
  const available_equips = available_items?.filter((el:any) => el.item_type === "module")
  const available_hulls = available_items?.filter((el:any) => el.item_type === "hull")
  const available_crews = available_items?.filter((el:any) => el.item_type === "crew")
  const screenTab = useSelector((state:RootState)=> state.screenTab);
  

  const hangar_items = user?.hangar_items
  const hull_equiped = user?.equips?.find((el:any) => el.item_type === "hull")
  const modules_equiped = user?.equips?.filter((el:any) => el.item_type === "module")
  const crews_equiped = user?.equips?.filter((el:any) => el.item_type === "crew")
  const navigator_equiped = user?.equips?.filter((el:any) => el.item_type === "navigator")[0]
  const urlParams = new URLSearchParams(window.location.search);
  const hash_id = urlParams.get('hash_id');
  const team = useSelector((state:RootState)=> state.team);
  const available_navigators = hangar_items?.filter((el:any) => el.item_type === "navigator")

  const [generalInfoTab, setGeneralInfoTab] = useState(0)

  //console.log("available_crews", available_crews)

  //function getItemJson(card_id:number, item_type:string){
  //  return available_items[item_type]?.find((el:any) => el.card_id === card_id)
  //}
  useEffect(() => {

   fetchAvailableItems();
  }, [dispatch]);

  let repair_count = 0

  repair_count += user?.total_stats?.hp > user.hp ? user?.total_stats?.hp - user.hp : 0
  repair_count += user?.total_stats?.sp > user.sp ? user?.total_stats?.sp - user.sp : 0
  repair_count += user?.total_stats?.order > user.order ? user?.total_stats?.order - user.order : 0
  repair_count += user?.total_stats?.supply > user.supply ? user?.total_stats?.supply - user.supply : 0
  repair_count += user?.total_stats?.morale > user.morale ? user?.total_stats?.morale - user.morale : 0

  function fetchTeam(){
    // Call the fetch_team_func passed from the parent
    fetch_team_func();
  };
  
  function indicator_old(square_num:number){
    if (square_num && square_num > 0)
    {
        return "■ ".repeat(square_num)
    }
    return ""
  }

  function crossIndicator_old(square_num:number){
    if (square_num && square_num > 0)
    {
        return "x ".repeat(square_num)
    }
    return ""
  }

  function indicator(square_num:number){
    if (square_num && square_num > 0)
    {
        //return "■ ".repeat(square_num)
        //return <div className='square-indicator'></div>
        return(<>
        {[...Array(square_num)].map((e, i) => <div className='square-indicator'></div>)}
        </>)
    }
    return ""
  }

  function crossIndicator(square_num:number){
    if (square_num && square_num > 0)
    {
        //return "x ".repeat(square_num)
        //return <div className='square-indicator-empty'></div>
        return(<>
          {[...Array(square_num)].map((e, i) => <div className='square-indicator-empty'></div>)}
          </>)
    }
    return ""
  }

  async function changeEquip(current_equip_id:number,new_equip_id:number, item_type: string) {

    console.log("current_equip_id", current_equip_id)
    let data = null
    if (current_equip_id && current_equip_id != 0){

        data = await ky.put(`${API_BASE_URL}/gameplay/ships/change_equipped_items/?hash_id=${hash_id}`, { json: {old_item_id: current_equip_id, new_item_id:new_equip_id} , credentials:'include'}).json();
    }else{

        data = await ky.post(`${API_BASE_URL}/gameplay/ships/equip_item/?hash_id=${hash_id}`, { json: {old_item_id: current_equip_id, new_item_id:new_equip_id} , credentials:'include'}).json();
    }
    console.log("changeEquip response",item_type, new_equip_id, data)
    fetchUser()
  }

  async function repair() {

        const data = await ky.put(`${API_BASE_URL}/gameplay/ships/total_repair/?hash_id=${hash_id}`, { json: {} , credentials:'include'}).json();
    console.log("repair response", data)
    fetchUser()
  }

  async function fetchUser() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/players/get_user?hash_id=${hash_id}`;

      const result = await ky.get(requestUrl, { credentials: 'include' }).json();
      dispatch(setUser(result));
      fetchTeam()
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };

  async function fetchAvailableItems() {
    try {
      // Add hash_id to the fetch request URL
      const requestUrl = `${API_BASE_URL}/gameplay/items/get_available_items?hash_id=${hash_id}`;

      const result :any = await ky.get(requestUrl, { credentials: 'include' }).json();
      dispatch(setAvailableItems(result));
    } catch (error) {
      console.error('Could not fetch user:', error);
    }
  };

  return (
    <>      

    <div className={generalInfoTab || equipModal || hullModal || crewModal ? 'general-inventory opacity-transform' : 'general-inventory'}>


    <div className='items dotted-background appear-with-shift'> {/*''*/}
    {/*hullClickAnimation ? <div  key='234'  className='background-color-pulse'/> : <div key='123' className='background-color-pulse'/>*/}

        <div className='info-panel-left not-fixed '>
          <div className='voyage-stats-list  '>

            <p  
            className='stat short-wrapable cyan-color-link'
            onClick={ ()=>{setHullModal(1);setCrewModal(0);setEquipModal(0);setChangeItemTargetId(hull_equiped?.id)}}
            >
              {hull_equiped?.name}
            </p>
            <p className='inactive'>{hull_equiped?.stats?.ship_class}</p>
          </div>
          
        </div>

        

        <div className='info-panel-right not-fixed '>
            <div className='voyage-stats-list right '>

                {navigator_equiped ?
                  <div 
                  className='stat short-wrapable cyan-color-link'  
                  onClick={ ()=>{setNavigatorModal(1);setChangeItemTargetId(navigator_equiped?.id)}}
                  >{navigator_equiped?.name}</div>
                  :
                  <div className='stat cyan-color-link' onClick={ ()=>{setNavigatorModal(1);setChangeItemTargetId(0)}}>
                  + выбрать
                </div>
                }
                  
            </div>

        </div>

        
        <div className='info-left '>
          <div className='voyage-stats-list right cut-content '>
              <p className='stat'><span className='inactive'>модули {modules_equiped?.length}/{user?.total_stats?.modules_capacity} </span></p>

              { modules_equiped?.map((eq:any) => {
                return (
                  <p className='stat cyan-color-link' onClick={ ()=>{setEquipModal(1);setChangeItemTargetId(eq?.id)}}>
                    {eq?.name}
                  </p>
                ) 
                }
              )}
              {user?.total_stats?.modules_capacity > modules_equiped?.length?
                <div className='stat cyan-color-link' onClick={ ()=>{setEquipModal(1);setChangeItemTargetId(0)}}>
                  + модуль
                </div>
                :<></>
              }

          </div>
          
        </div>

        <div className='info-right  '>


            <div className='voyage-stats-list  cut-content'>
            <p className='stat'><span className='inactive'>экипажи {crews_equiped?.length}/{user?.total_stats?.crew_capacity} </span></p>
            {user?.total_stats?.crew_capacity == 0?
                <>
                <p className='inactive'>нет отсеков</p>
                </>
              :<>
              </>}
              { crews_equiped?.map((eq:any) => {
                      return (
                        <p className='stat cyan-color-link' onClick={ ()=>{setCrewModal(1);setChangeItemTargetId(eq?.id)}}>
                          {eq?.name}
                        </p>
                      ) 
                      }
                    )} 
              {user?.total_stats?.crew_capacity > crews_equiped?.length?
                <div className='stat cyan-color-link' onClick={ ()=>{setCrewModal(1);setChangeItemTargetId(0)}}>
                  + экипаж
                </div>
                :<></>
              }

              </div>
        </div>

        

        <div className='hull' >
        <div className='circle' />
          <img 
          src={hull_equiped?.img} 
          className={hullIsLoaded ? 'img-float-animation' : 'zero-opacity'}
          onLoad={() => setHullIsLoaded(true)}
          alt="Loaded content"/>
          <div className='info-left display-none'>
            <div className='indicators-row'>{/*user?.sp+'\u00A0'*/}СПЦ {indicator(user?.sp)}{user?.sp <= user?.total_stats?.sp?crossIndicator(user?.total_stats?.sp-user?.sp):""} </div>
            <div className={'white-indicators indicators-row'} >ИИ+ {/*user?.ai+'\u00A0'*/}{indicator(user?.ai)}{user?.ai <= user?.total_stats?.ai?crossIndicator(user?.total_stats?.ai-user?.ai):""}</div>
       
          </div>
          <div className='info-right display-none'>
            {team?.voyage?.card_type == 'station'?
            <>
              <p>валюта</p>
              <p>станции</p>
              <p><span className='white-font'>{user.hyperpoints} ВС</span></p>
            </>:
            <>
              <p>вне</p><p>станции</p>
            </>}
            </div>
          </div>
        
        { /*moduleTab == 'module'?
          <div className='items-row display-none'>
          <div className='equip-items' key={'equip-icons'}>
            <div className='section-title display-none'>Модули</div>

              { modules_equiped?.map((eq:any) => {
                return (
                  <div className='item ' onClick={ ()=>{setEquipModal(1);setChangeItemTargetId(eq?.id)}}>
                    <img src={eq?.img}/>
                    <small className='name'>{eq?.name}</small>
                  </div>
                ) 
                }
              )} 
              {user?.total_stats?.modules_capacity > modules_equiped?.length?
                <div className='item ' onClick={ ()=>{setEquipModal(1);setChangeItemTargetId(0)}}>
                  +
                  <small className='name'>выбрать</small>
                </div>
                :<></>
              }
              {user?.total_stats?.modules_capacity == 0?
                <p className='inactive'>Корпус не поддерживает модули</p>
                :<></>
              }
          </div>

          <div className='crew-items' key={'crew-icons'}>
            <div className='section-title display-none'>Экипаж</div>
              { crews_equiped?.map((cr:any) => {
                return (
                  <div className='item crew' onClick={ ()=>{setCrewModal(1);setChangeItemTargetId(cr?.id)}}>
                    <img src={cr?.img}/>
                    <small className='name'>{cr?.name}</small>
                  </div>
                ) 
                }
              )} 
              {user?.total_stats?.crew_capacity > crews_equiped?.length?
                <div className='item crew' onClick={ ()=>{setCrewModal(1);setChangeItemTargetId(0)}}>
                  +
                  <small className='name'>выбрать</small>
                </div>
                :<></>
              }
              {user?.total_stats?.crew_capacity == 0?
                <p className='inactive'>Корпус не поддерживает отсеки экипажей</p>
                :<></>
              }
            </div>

              { navigator_equiped ? 
              <>

                    <div className='equip-card navigator equipped'                    
                    style={{backgroundImage: 'url(' + navigator_equiped?.img + ')'}}
                    >
                      <small className='name'>{navigator_equiped?.name}</small>
                      <div className='navigator-accent-line'/>
                      <div className='title'>
                        <h2>{navigator_equiped?.name.toUpperCase()}</h2>
                        <p>{navigator_equiped?.stats?.buff_description}</p>
                      </div>
                      <div className='price-text'>
                        
                        </div>

                      <div className='description'>
                      <small>{navigator_equiped?.description}</small>
                      </div>
                    </div>
              </>: 
              <>
                  <div className='navigator-items' key={'navigator-icons'}>
                  <div className='item navigator' onClick={ ()=>{setNavigatorModal(1);setChangeItemTargetId(0)}}>
                    +
                    <small className='name'>выбрать</small>

                  </div>
                  </div>

              </>
              }
            </div>
        */}


      </div>
      
      
      
      </div>

    <div className='hud no-opacity'>


      <div className="commands-panel">
          <div className="command-order-btns-row">
            <div className="next-target-btn" onClick={() => dispatch(setShowShipCardId(0))}>НАЗАД</div>
            <div className="secondary-order-btn" onClick={() => setGeneralInfoTab(1)}>ДАННЫЕ ЭКИПАЖА</div>
          </div>
        </div>

        <div className={generalInfoTab ? 'bottom-drawer': 'bottom-drawer hidden'}>
          <div className='drawer-header'>Данные экипажа</div>
          { user.total_stats?
            <div className='total-stats-section'>
              <div className='title'><h2 >{hull_equiped?.name}</h2>
              <p className='highlight'>{hull_equiped?.stats?.ship_class}</p>
              </div>
              <div className='stats'>

                <div className='left-stats'>
                <p>КОР {indicator(user.hp)} 
                {user.hp <= user.total_stats?.hp?crossIndicator(user.total_stats?.hp-user.hp):""}</p>
                <p>ЭНР {indicator(user.ep)} 
                {user.ep <= user.total_stats?.ep?crossIndicator(user.total_stats?.ep-user.ep):""}</p>
                <p>СПЦ {indicator(user.sp)} 
                {user.sp <= user.total_stats?.sp?crossIndicator(user.total_stats?.sp-user.sp):""}</p>
                <p>ИИ+ {indicator(user.ai)} {user.ai <= user.total_stats?.ai?crossIndicator(user.total_stats?.ai-user.ai):""}</p>
                </div>
                <div className='right-stats inactive'>
                  <p>ресурс</p>
                </div>
              </div>
              <div className='stats'>
                <div className='left-stats'>
                <p>{user.morale} / {user.total_stats?.morale} мораль</p>
                <p>{user.supply} / {user.total_stats?.supply} снабжение</p>
                <p>{user.order} / {user.total_stats?.order} дисциплина</p>
                <p>{user.faith} / 10 резонанс</p>
                <p>{user.aggression} / 10 агрессия</p>
                <p>{user.diplomacy} / 10 дипломатия</p>
                <p>{user.hyperpoints} валюта станции</p>
                </div>
                <div className='right-stats inactive'>
                  <p>экипаж</p>
                </div>
              </div>
              <div className='stats'>
                <div className='left-stats'>
                <p>{modules_equiped?.length} / {user.total_stats?.modules_capacity} модули</p>
                <p>{crews_equiped?.length} / {user.total_stats?.crew_capacity} экипажи</p>
                <p>{user.total_stats?.freight } вместимость груза</p>
                </div>
                <div className='right-stats inactive'>
                  <p>модули</p>
                </div>
              </div>
              <p className='inactive'> Показатели каравана формируются из средних показателей всех кораблей в группе. Грузоподъемность суммируется.</p>
              
            </div> :<>не найдено</>
            }
          <div className="command-order-btns-row">
              <div className="secondary-order-btn"  onClick={() => setGeneralInfoTab(0)}>НАЗАД</div>
              {/*team?.voyage?.card_type == 'station'?
              <div className="order-btn"  onClick={()=>{repair(); setGeneralInfoTab(0)}}>РЕМОНТ/РЕСУРСЫ (-{5*repair_count} ВС)</div>
              :
              <div className="order-btn inactive">РЕМОНТ НЕДОСТУПЕН</div>
              */}

            </div>
        </div>


    {/*<div className='info-panel-right'>
        <div className='text-stats-right'>
        <p>■ ■ ■ ■ hp</p>
            <p>■ ■ ■ ■ ep</p>
            <p>■ ■ sp</p>
            <p>■ ■ ■ ■ ai</p>
        </div>
        
      </div>
      <div className='info-panel-left'>
        <div className='text-stats-left'>
        <p>morale ■ ■</p>
            <p>food ■ ■</p>
            <p>nav ■ ■</p>
            <p>cargo ■ ■</p>
        </div>
        
      </div>

      <div className='info-panel-center'>
        total        
      </div>*/}

    
    <div className={hullModal ? 'bottom-drawer': 'bottom-drawer hidden'}>  
    <div className='drawer-header'>Корпуса</div>

    {team?.voyage?.card_type != 'station'  ?
      <div className='category'>Можно менять только на станции</div>:
    <div className='category'>
      <div className='card-row'>
      { available_hulls.map((hull:any) => {
                  let hangar_insert = (hangar_items?.filter((el:any) => el.id === hull?.id))?.length
                  return <div className={newItemTargetId == hull?.id ?'equip-card selected' : changeItemTargetId == hull?.id ? 'equip-card equipped' : 'equip-card'}  key={'hull-item-'+ hull?.id} 
                  onClick={changeItemTargetId == hull?.id ? ()=> {} : () => {setNewItemTargetId(hull?.id); hangar_insert? setNewPrice(0) : hull?.stats?.price ? setNewPrice(hull?.stats?.price) : setNewPrice(200)}}>

                    <div className='accent-circle'/>

                  <div className={changeItemTargetId == hull?.id ? 'price-text' : 'price-text'}>{changeItemTargetId == hull?.id ? 'УСТАНОВЛЕНО' : hangar_insert ? 'В АНГАРЕ' : hull?.stats?.price ? hull?.stats?.price +' ВС' : '200 ВС'}</div>

                <img src={hull?.img}/>
                <div className='title'>
                <p>{hull?.stats?.ship_class}</p>
                    <h3>{hull?.name}</h3>
                </div>
                <div className='hull-description'>
                  <div>
                <p className='indicators-row red-indicators'>КОР: { (hull?.stats?.hp)}</p>
                <p className='indicators-row cyan-indicators'>ЭНР: { (hull?.stats?.ep)}</p>
                <p className='indicators-row '>СПЦ: { (hull?.stats?.sp)}</p>
                <p className='indicators-row white-indicators'>ИИ : { (hull?.stats?.ai)}</p>

                </div>
                <div className=''>
                <p>МОД : {hull?.stats?.modules_capacity}</p>
                <p>ЭКИП: {hull?.stats?.crew_capacity}</p>
                <p>ГРУЗ: { hull?.stats?.freight} </p>
                </div>
                </div>

                  </div>
                }
                  )
                }
        {/* available_hulls.map((hull:any) => {
            return <div onClick={()=> {setNewItemTargetId(hull?.id) }}>
              < EquipCard
            item_data={hull}
            key={'hull-item-'+ hull?.id}
            />
            </div>
          })
        */}
      </div>
        
      </div>
      }
      <div className="command-order-btns-row">

        <div className="next-target-btn" onClick={ ()=>{setHullModal(0);setNewItemTargetId(0); setNewPrice(0)}}>НАЗАД</div>
        {newItemTargetId != 0?
        <div className="order-btn"  onClick={()=>{changeEquip(hull_equiped?.id, newItemTargetId,"hull"); setHullModal(0);setNewItemTargetId(0); setNewPrice(0)}}>УСТАНОВКА (-{newPrice} ВС)</div>
        :
        <div className="order-btn inactive">ВЫБЕРИТЕ МОДУЛЬ</div>
        }
      </div>
    </div>

    
    <div className={equipModal ? 'bottom-drawer': 'bottom-drawer hidden'}>  
    <div className='drawer-header'>Модули</div>

    {team?.voyage?.card_type != 'station' ?
      <div className='category'>Можно менять только на станции</div>:
    <div className='category'>
        <div className='card-row'>

            { available_equips?.map((equip:any) => {
              let hangar_insert = (hangar_items?.filter((el:any) => el.id === equip?.id))?.length

              return  <div className={newItemTargetId == equip?.id ?'equip-card selected' : changeItemTargetId == equip?.id ? 'equip-card equipped' : 'equip-card'}  key={'module-item-'+ equip?.id} 
                onClick={changeItemTargetId == equip?.id ? ()=> {} : () => {setNewItemTargetId(equip?.id); hangar_insert ? setNewPrice(0)  : equip?.stats?.price ? setNewPrice(equip?.stats?.price) : setNewPrice(100)}}>

                  <div className='accent-line'/>
                  <div className='left-accent'/>

                  <img src={equip?.img}/>
                  <div className='title'>
                    <p>{equip?.stats?.buff_description}</p>
                    <b>{equip?.name}</b>
                  </div>
                  <div className={changeItemTargetId == equip?.id ? 'price-text' : 'price-text'}>{changeItemTargetId == equip?.id ? 'УСТАНОВЛЕНО' : hangar_insert ? 'В АНГАРЕ x' + hangar_insert : equip?.stats?.price ? equip?.stats?.price + ' ВС' : '100 ВС'}</div>

                  <div className='description'>
                  <small>{equip?.description}</small>
                  </div>
                </div>
              }
            )
          }
          
        </div>
      </div>
    }
    <div className="command-order-btns-row">
              
      <div className="next-target-btn" onClick={ ()=>{setEquipModal(0);setNewItemTargetId(0); setNewPrice(0)}}>НАЗАД</div>
        {newItemTargetId != 0?
        <div className="order-btn"  onClick={()=>{
          changeEquip(changeItemTargetId,newItemTargetId,"module"); 
          setEquipModal(0);
          setNewItemTargetId(0);
          setNewPrice(0)
          }}>
          {changeItemTargetId ? 'ЗАМЕНА ': 'УСТАНОВКА '} {'(-' + newPrice +' ВС)'}
        </div>
        :
        <div className="order-btn inactive">ВЫБЕРИТЕ МОДУЛЬ</div>
        }
    </div>
    </div>

    <div className={crewModal ? 'bottom-drawer': 'bottom-drawer hidden'}>  
    <div className='drawer-header'>Спецгруппы</div>

      {team?.voyage?.card_type != 'station'  ?
        <div className='category'>Можно менять только на станции</div>
        :
        <div className='category'>
          <div className='card-row'>

                { available_crews.map((crew:any) => 
                  { 
                    let hangar_insert = (hangar_items?.filter((el:any) => el.id === crew?.id))?.length

                    return <div className={newItemTargetId == crew?.id ?'equip-card selected' : changeItemTargetId == crew?.id ? 'equip-card equipped' : 'equip-card'}  key={'module-item-'+ crew?.id} 
                    onClick={changeItemTargetId == crew?.id ? ()=> {} : () => {setNewItemTargetId(crew?.id); hangar_insert ? setNewPrice(0) : crew?.stats?.price ? setNewPrice(crew?.stats?.price) : setNewPrice(100)}}>

                      <div className='accent-line'/>
                      <div className='left-accent'/>
                      <img className='crew-img'  src={crew?.img}/>
                      <div className='title'>
                        <p>{crew?.stats?.buff_description}</p>
                        <b>{crew?.name}</b>
                      </div>
                      <div className={changeItemTargetId == crew?.id ? 'price-text' : 'price-text'}>{changeItemTargetId == crew?.id ? 'УСТАНОВЛЕНО' : hangar_insert ? "В АНГАРЕ x" + hangar_insert : crew?.stats?.price ? crew?.stats?.price +' ВС': '100 ВС'}</div>

                      <div className='description'>
                      <small>{crew?.description}</small>
                      </div>
                    </div>
                  }
                  )
                }
                

          </div>
        </div>
      }
      <div className="command-order-btns-row">

        
        <div className="next-target-btn" onClick={ ()=>{setCrewModal(0);setNewItemTargetId(0); setNewPrice(0)}}>НАЗАД</div>
        {newItemTargetId != 0?
        <div className="order-btn"  onClick={()=>{changeEquip(changeItemTargetId,newItemTargetId,"crew"); setCrewModal(0);setNewItemTargetId(0); setNewPrice(0)}}>НАЙМ/ЗАМЕНА (-{newPrice} ВС)</div>
        :
        <div className="order-btn inactive">ВЫБЕРИТЕ ЭКИПАЖ</div>
        }
      </div>
    </div>

    <div className={navigatorModal ? 'bottom-drawer': 'bottom-drawer hidden'}>  
    <div className='drawer-header'>Предыстории капитана</div>

      {team?.voyage?.card_type != 'station'  ?
        <div className='category'>Можно менять только на станции</div>
        :
        <div className='category'>
          <div className='card-row'>

                { 
                 !navigator_equiped ?
                <>
                  {available_navigators.map((crew:any) => 
                    { 
                      let hangar_insert = (hangar_items?.filter((el:any) => el.id === crew?.id))?.length

                      return <div 
                      className={newItemTargetId == crew?.id ?'equip-card navigator selected' : changeItemTargetId == crew?.id ? 'equip-card navigator equipped' : 'equip-card navigator'}  
                      key={'module-item-'+ crew?.id} 
                      onClick={changeItemTargetId == crew?.id ? ()=> {} : () => {setNewItemTargetId(crew?.id); hangar_insert ? setNewPrice(0) : crew?.stats?.price ? setNewPrice(crew?.stats?.price) : setNewPrice(100)}}
                      style={{backgroundImage: 'url(' + crew?.img + ')'}}
                      >
                        <div className='navigator-accent-line'/>
                        <div className='title'>
                          <h2>{crew?.name.toUpperCase()}</h2>
                          <p>{crew?.stats?.buff_description}</p>
                        </div>
                        <div className='description'>
                        <small>{crew?.description}</small>
                        </div>
                      </div>
                    }) 
                  }
                </>
                  :
                   <div 
                      className={'equip-card navigator chosen'}  
                      key={'module-item-'+ navigator_equiped?.id} 
                      style={{backgroundImage: 'url(' + navigator_equiped?.img + ')'}}
                      >
                        <div className='navigator-accent-line'/>
                        <div className='title'>
                          <h2>{navigator_equiped?.name.toUpperCase()}</h2>
                          <p>{navigator_equiped?.stats?.buff_description}</p>
                        </div>
                        <div className='description'>
                        <small>{navigator_equiped?.description}</small>
                        </div>
                      </div>
                }
              
          </div>
        </div>
      }
      <div className="command-order-btns-row">

        
        <div className="next-target-btn" onClick={ ()=>{setNavigatorModal(0);setNewItemTargetId(0); setNewPrice(0)}}>НАЗАД</div>
        {
        navigator_equiped ?
        <div className="order-btn inactive">УЖЕ ВЫБРАНА</div>
        :
        newItemTargetId != 0?
        <div className="order-btn"  onClick={()=>{changeEquip(changeItemTargetId,newItemTargetId,"crew"); setNavigatorModal(0);setNewItemTargetId(0); setNewPrice(0)}}>ВЫБРАТЬ (НЕЛЬЗЯ ЗАМЕНИТЬ)</div>
        :
        <div className="order-btn inactive">ВЫБЕРИТЕ ПРЕДЫСТОРИЮ</div>
        }
      </div>
    </div>

    {/*<div className='order-btn'>
      <a href={"/waiting_room?hash_id="+hash_id}><h2>READY</h2></a>
    </div>*/}
    </div>

    </>
    //      <axesHelper />

  )
}
